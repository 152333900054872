import React from 'react';
import { SignUp } from 'aws-amplify-react';
import { Form, Input, Checkbox } from 'antd';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

class SignUpForm extends SignUp {

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('values: ', values);

                Auth.signUp({
                    username: values.email,
                    password: values.password,
                    attributes: {
                        email: values.email,
                        phone_number: '',
                        family_name: values.lastName,
                        name: values.firstName,
                        'custom:company': values.company
                    },
                    validationData: [{
                        Name: 'dataProcessing',
                        Value: `${values.dataProcessing}`
                    },{
                        Name: 'termsConditions',
                        Value: `${values.termsConditions}`
                    }]
                })
                .then(() => {
                    Hub.dispatch('auth', {
                        event: 'signUpSubmit',
                        data: {
                            username: values.email,
                            password: values.password,
                        }
                    });
                    this.changeState('confirmSignUp', values.email);
                })
                .catch(err => this.error(err));
            } else{
                console.log('errors: ', err);
            }
        });
    }

    errorMessage = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors){
            if(errors.length > 0) {
                return (
                    <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
                )
            } else {
                return null;
            }
        }
    }

    showComponent() {
        const { hide } = this.props;
        if (hide && hide.includes(SignUpForm)) { return null; }

        const {
            getFieldDecorator
        } = this.props.form;

        return (
            <div>
                <div className="mw6 center pv3 pv4-ns">
                    <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                        <img className="" src="/assets/img/hala-logo.svg" alt="" />
                    </div>
                </div>
                <div className="mw6 center ph2 mb2 mb5-l">
                    <div className="pa3 pa4-ns pa5-l bg-white br1">
                        <h1 className="f3 f2-ns mv0">Sign up</h1>
                        <p className="mt2 mb0 lh-copy">Create new Hala account.</p>
                        <Form onSubmit={this.handleSubmit} className="mt3">
                            <div className="">
                                <label htmlFor="email" className="b f6">Email:</label>
                                {getFieldDecorator('email', {
                                    rules: [{
                                            required: true,
                                            message: 'Please input your Email!'
                                    },{
                                            type: "email",
                                            message: 'Email address in invalid format!'
                                    }],
                                        validateTrigger: "onChange",
                                        onChange: this.handleInputChange
                                })(
                                    <Input  prefixCls="h" className="w-100 mt1 pv2 ph2 f5" name="email" autoComplete="email" />
                                )}
                                {
                                    this.errorMessage('email')
                                }
                            </div>
                            <div className="mt3">
                                <label htmlFor="firstName" className="b f6">First Name:</label>
                                {getFieldDecorator('firstName', {
                                    rules: [{
                                            required: true,
                                            message: 'Please input your First Name!'
                                    }],
                                        validateTrigger: "onChange",
                                        onChange: this.handleInputChange
                                })(
                                    <Input prefixCls="h" className="w-100 mt1 pv2 ph2 f5" name="firstName" />
                                )}
                                {
                                    this.errorMessage('firstName')
                                }
                            </div>
                            <div className="mt3">
                                <label htmlFor="lastName" className="b f6">Last Name:</label>
                                {getFieldDecorator('lastName', {
                                    rules: [{
                                            required: true,
                                            message: 'Please input your Last Name!'
                                    }],
                                        validateTrigger: "onChange",
                                        onChange: this.handleInputChange
                                })(
                                    <Input prefixCls="h" className="w-100 mt1 pv2 ph2 f5" name="lastName" />
                                )}
                                {
                                    this.errorMessage('lastName')
                                }
                            </div>
                            <div className="mt3">
                                <label htmlFor="company" className="b f6">Company:</label>
                                <span className="fr f6 o-60">Optional</span>
                                {getFieldDecorator('company', {
                                    rules: [{
                                            required: true,
                                            message: 'Please input your Company Name!'
                                    }],
                                        validateTrigger: "onChange",
                                        onChange: this.handleInputChange
                                })(
                                    <Input prefixCls="h" className="w-100 mt1 pv2 ph2 f5" name="company"/>
                                )}
                                {
                                    this.errorMessage('company')
                                }
                            </div>
                            <div className="mt3">
                                <label htmlFor="password" className="b f6">Password:</label>
                                {getFieldDecorator('password', {
                                    rules: [{
                                            required: true,
                                            message: 'Please input your Password!'
                                    }],
                                        validateTrigger: "onChange",
                                        onChange: this.handleInputChange
                                })(
                                    <Input prefixCls="h" className="w-100 mt1 pv2 ph2 f5" autoComplete="new-password" name="password" type="password" />
                                )}
                                {
                                    this.errorMessage('password')
                                }
                            </div>
                            <div className="flex items-center mt3">
                                {getFieldDecorator('termsConditions', {
                                    rules: [{
                                            required: true,
                                            message: 'Please agree with Terms & Conditions',
                                        validator: (e, val, next) => {
                                            if (val) next();
                                            else next(null);
                                        }
                                    }],
                                        validateTrigger: "onSubmit"
                                })(
                                    <Checkbox className="mr2"></Checkbox>
                                )}
                                <label>I agree with <a target="_blank" href="/" className="link">Terms &amp; Conditions</a>.</label>
                            </div>
                            <div className="flex items-center mt3">
                            {getFieldDecorator('dataProcessing', {
                                rules: [{
                                        required: true,
                                        message: 'Please agree with Data Processing',
                                    validator: (e, val, next) => {
                                        if (val) next();
                                        else next(null);
                                    }
                                }],
                                    validateTrigger: "onSubmit"
                            })(
                                <Checkbox className="mr2"></Checkbox>
                            )}
                            <label>I agree with <a target="_blank" href="/" className="link">Data Processing</a>.</label>
                            </div>
                            <div className="mt3">
                                <input type="submit" value="Create new account" className="w-100 btn btn-blue pv2 ph3 f5" />
                            </div>
                            <p className="mt3 mb0 lh-copy">Already have an account? <a href="/" className="link"
                            onClick={() => this.changeState('confirmSignUp')}>Log in now</a></p>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

const WrappedSignUpForm = Form.create()(SignUpForm);
export default WrappedSignUpForm;
