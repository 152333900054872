import {
    REQ_COMPANY_INFO_SUCCESS,
    SET_TOTAL_SUPPORT_LIMIT_SUCCESS,
    SET_SUPPORT_ITEM_LIMIT_SUCCESS
} from '../../constants';


const support = ( state = {
    total: 0,
    limit: 0,
    data: []
}, action) => {
    switch(action.type){
        case REQ_COMPANY_INFO_SUCCESS:
            return {...state, ...action.data.support};
        case SET_TOTAL_SUPPORT_LIMIT_SUCCESS:
            return {...state, limit: action.data};
        case SET_SUPPORT_ITEM_LIMIT_SUCCESS:
            return  {...state,
                data: state.data.map( item => {
                    console.log(action);
                if(item.type === action.data.type){
                    return {...item, limit: action.data.limit};
                }
                return item;
            })
        };
        default:
            return state;
    }
}

export default support;