import React from 'react';
import PropTypes from 'prop-types';

const FileInput = ({item, handleselectedFile, fileName}) => {
    return (
        <div className="mt3">
            <label className="b f6">{item.name}:</label>
            <div className="mt1 pv2 ph2 f5">
                <input type="file" id="file" onChange={handleselectedFile} className="file-input" accept={"image/*"} />
                <label htmlFor="file" className="dib btn btn-ghost pv2 ph3 f5">Choose a file</label>
                {fileName ? <p className="dib ml2 o-60">{fileName}</p> : null}
            </div>
            <div className="mt1 f6 o-60">We recommend 248x64 px dimensions and PNG/JPG format.</div>
        </div>
    );
};

export default FileInput;

FileInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleselectedFile: PropTypes.func.isRequired,
    fileName: PropTypes.string,
}