import React from 'react';
import PropTypes from 'prop-types';

// const Image = ({isAscending}) => (
//     <img className="v-mid ml1 child" 
//     onClick={e => e.stopPropagation()}
//     src={`/assets/img/16icn-sort-${isAscending ? 'desc' : 'asc'}.svg`} alt="" />
// )

const SkillCol = ({id, name, handleClick, params}) => {
    // const active = params.sortFilter && params.sortFilter === id;
    // const isAscending = params.sortOption && params.sortOption === 'ascending';
    return(
        <th className={`${name === 'Skill name' ? 'tl' : 'tr'}`} id={id}
        // onClick={handleClick}
        >
            {name}
            {/* {active ? <Image isAscending={isAscending} /> : null} */}
        </th>
    );
}

export default SkillCol;

SkillCol.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    params: PropTypes.shape({
        filter: PropTypes.string,
        sortOption: PropTypes.string,
    }).isRequired,
}