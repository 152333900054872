// IntegrationsPageHead 

export const titles = {
    'SAP': {
        'ERP': {
            description: 'Hala allows you to work with the SAP backend by using text and voice. Hala is supporting integration starting from SAP ECC 4/6.'
        }
    },
    'IBM': {
        'Watson Assistant': {
            description: 'Add a natural language interface to your application to automate interactions with your end users.',
            link: 'https://console.bluemix.net/docs/services/conversation/getting-started.html'
        },
        'WKS': {
            description: 'Teach Watson the language of your domain with custom machine learning models that identify entities and relationships unique to your industry in unstructured text.',
            link: 'https://console.bluemix.net/docs/services/watson-knowledge-studio/index.html#wks_overview_full'
        },
        'NLU': {
            description: 'Use advanced NLP to analyze text and extract meta-data from content such as concepts, entities, keywords, categories, sentiment, emotion, relations, and semantic roles.',
            link: 'https://console.bluemix.net/docs/services/natural-language-understanding/getting-started.html#getting-started-tutorial'
        },
        'NLC': {
            description: 'The Natural Language Classifier service uses advanced natural language processing and machine learning techniques to assign custom categories to inputted text.',
            link: 'https://console.bluemix.net/docs/services/natural-language-classifier/getting-started.html'
        },
        'Discovery': { 
            description:'Add a cognitive search and content analytics engine to applications to identify patterns, trends and actionable insights that drive better decision-making.',
            link: 'https://console.bluemix.net/docs/services/discovery/getting-started-tool.html#getting-started'
        }
    }
};

export const logoImg = {
    'SAP': '/assets/img/integration-logo-sap.svg',
    'IBM': '/assets/img/integration-logo-ibm-watson.png'
};

//FilterSection

export const  filterOptions = {
    provider: ['SAP', 'IBM', 'Hala', 'Google', 'Microsoft', 'Slack', 'Oracle'],
    category: [{
        key: 'ERP',
        name:'Enterprise Resource Planning (ERP)'
    }, {
        key: 'CRM',
        name: 'Customer relationship management (CRM)'
    },{
        key: 'TP',
        name: 'Technology Provider (TP)'
    },{
        key: 'CT',
        name: 'Communication tools'
    }]
};

// Catalogue

export const active = [{
    name: 'SAP ERP',
    img: '/assets/img/integration-logo-sap.png',
    link: '/connections/SAP/ERP/addNew'
}, {
    name: 'IBM Watson',
    img: '/assets/img/integration-logo-ibm-watson.png',
    link: '/connections/catalogue/ibm'
}];

export const comingSoon = [{
    name: 'Oracle',
    img: '/assets/img/integration-logo-oracle.png'
}, {
    name: 'Salesforce',
    img: '/assets/img/integration-logo-salesforce.png'
},{
    name: 'Skype',
    img: '/assets/img/integration-logo-skype.png'
},{
    name: 'Slack',
    img: '/assets/img/integration-logo-slack.png'
},{
    name: 'Microsoft Dynamics',
    img: '/assets/img/integration-logo-microsoft-dynamics.png'
},{
    name: 'Google Cloud Platform',
    img: '/assets/img/integration-logo-Google-cloud-platform.png'
}];
