import * as c from '../constants';
import * as api from '../services/api';
import history from '../history';
import { error } from './errors'


const reqInvoices = () => ({
    type: c.GET_INVOICES
})

const reqInvoicesSuccess = (data) => ({
    type: c.GET_INVOICES_SUCCESS,
    data
})

const reqInvoicesError = (data) => ({
    type: c.GET_INVOICES_ERROR,
    data
})


export const getInvoices = () => async dispatch =>  {
    dispatch(reqInvoices());
    try{
        const invoices = await api.getInvoices();
        dispatch(reqInvoicesSuccess(invoices));
    }catch(err){
        dispatch(reqInvoicesError(err.message));
        error(err.message);
    }
}

export const saveInvoice = invoice => async dispatch => {
    if(invoice && invoice._id){
        dispatch(updateInvoice(invoice))
    } else {
        dispatch(createInvoice(invoice));
    }        
}


const reqSaveInvoice = () => ({
    type: c.REQ_SAVE_INVOICE
})

const reqSaveInvoiceSuccess = data => ({
    type: c.REQ_SAVE_INVOICE_SUCCESS,
    data
})

const reqSaveInvoiceError = data => ({
    type: c.REQ_SAVE_INVOICE_ERROR,
    data
})

export const createInvoice = invoice => async dispatch => {
    dispatch(reqSaveInvoice());
    try{
        const newInvoice = await api.createInvoice(invoice);
        dispatch(reqSaveInvoiceSuccess(newInvoice))
        history.push('/settings/billing');
    }catch(err){
        dispatch(error(err.response.data));
        dispatch(reqSaveInvoiceError(err.message))
    }
}


const reqUpdateInvoice = () => ({
    type: c.REQ_UPDATE_INVOICE
})

const reqUpdateInvoiceSuccess = data => ({
    type: c.REQ_UPDATE_INVOICE_SUCCESS,
    data
})

const reqUpdateInvoiceError = data => ({
    type: c.REQ_UPDATE_INVOICE_ERROR,
    data
})

const updateInvoice = invoice => async dispatch => {
    dispatch(reqUpdateInvoice());
    try{
        await api.updateInvoice(invoice);
        dispatch(reqUpdateInvoiceSuccess(invoice))
        history.push('/settings/billing');
    }catch(err){
        dispatch(error(err.response.data));
        dispatch(reqUpdateInvoiceError(err.message))
    }
}

const reqDeleteInvoice = () => ({
    type: c.REQ_DELETE_INVOICE
})

const reqDeleteInvoiceSuccess = data => ({
    type: c.REQ_DELETE_INVOICE_SUCCESS,
    data
})

const reqDeleteInvoiceError = data => ({
    type: c.REQ_DELETE_INVOICE_ERROR,
    data
})

const deleteInvoice = invoiceId => async dispatch => {
    dispatch(reqDeleteInvoice());
    try{
        await api.deleteInvoice(invoiceId);
        dispatch(reqDeleteInvoiceSuccess(invoiceId))
    }catch(err){
        dispatch(error(err.response.data));
        dispatch(reqDeleteInvoiceError(err.message))
    }
}



export const confirmDeleteInvoice = invoice => (
    async dispatch => {
        dispatch({
            type: c.RENDER_CONFIRMATION_POPUP,
            payload: {
                data: [ invoice ],
                type: 'invoice',
                ok: async () => {
                    return await dispatch(deleteInvoice(invoice._id))
                }
            }
        });
    }
)