import React from 'react';
import PropTypes from 'prop-types';

const Title = ({type, length}) => {

    return (
        <h1  className="f3 f2-ns mv0">{`Delete ${length > 1 ? length + ' ' + type + 's' : type}`}</h1>
    );
};

export default Title;

Title.propTypes = {
    type: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
}