import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

const TopTable = ({ 
    title, 
    data, 
    fetching, 
    description,
    totalDescription,
    renderName,
    details
}) => {
    return (
    <div className="w-100 w-50-l mb3 mb0-l ph2 flex">
        <div className="bg-navy-near-white pa3 br1 flex flex-column justify-start flex-auto">
            <Skeleton loading={fetching} active>
                <h3 className="mt0 mb3">{title}</h3>
                <div className="flex justify-between pb2">
                    <p className="f6 fw5 o-60 mv0 lh-title">{description}</p>
                    <p className="f6 fw5 o-60 mv0 lh-title">{totalDescription}</p>
                </div>
                {
                    data ? data.map( (s,i) => {
                        return(
                            <div key={i} className="flex justify-between bb b--black-05 pv2">
                                <p className="mv0">{`${i + 1}. ${renderName(s)}`}</p>
                                <p className="mv0">{s.total}</p>
                            </div>
                        )
                    }) : null
                }
                <div className="pt2" style={{marginTop: 'auto'}}>
                    <p className="mv0"><Link to={details} className="link">See more</Link></p>
                </div>
            </Skeleton>
        </div>
    </div>
)}

TopTable.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    totalDescription: PropTypes.string.isRequired,
    data: PropTypes.array,
    fetching: PropTypes.bool.isRequired,
    renderName: PropTypes.func.isRequired
}

export default memo(TopTable);