import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import PageHead from '../../PageHead';
import SkillsTable from './SkillsTable';
// import SupportTable from './SupportTable';
import RefreshContent from '../../RefreshContent';
import UsageLimit from './UsageLimit';
import { fetchreqCompanySkillsObject } from '../../../actions/settings';

class SkillsUsage extends Component {

    componentDidMount(){
        this.props.fetchreqCompanySkillsObject();
    }

    render(){
        const { fetchreqCompanySkillsObject, lastUpdated, fetching, period } = this.props;
        return (
            <div style={{minHeight: '99vh', backgroundColor: 'white'}}>
                <PageHead 
                    title= "Usage Dashboard">
                    <p className="mb0 f6 fw5 o-60 o-60">{`Current period: ${moment(period.start).format('LL')} - ${moment(period.end).format('LL')}`}</p>
                </PageHead> 
                <div className="pa4 pb5-l">
                    <div className="ph3">
                        <div className="measure-wide">
                            <h2 className="mt0 mb2">Skills</h2>
                        </div>
                        <RefreshContent action={fetchreqCompanySkillsObject} 
                        lastUpdated={lastUpdated} 
                        fetching={fetching} />
                        <SkillsTable />
                        {/* <div className="measure-wide">
                            <hr className="ba b--black-05 mv4" />
                            <h2 className="mt0 mb2">Support</h2>
                            <p className="mt2">Breakdown of your Support usage.</p>
                        </div>
                        <SupportTable /> */}
                        {/* <div className="measure-wide">
                            <hr className="ba b--black-05 mv4" />
                            <h2 className="mt0 mb2">Contact us</h2>
                            <p className="mt2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim veniam.</p>
                            <p><Link to="/contacts" className="dib btn btn-ghost pv2 ph3 f5 link">Contact us</Link></p>
                        </div> */}
                    </div>
                </div>
                <UsageLimit />
            </div>
            
        )
    }
}

const mapStateToProps = state => ({
    lastUpdated: state.settings.skills.lastFetched,
    period: state.settings.skills.period,
    fetching: state.settings.skills.fetching,
})

export default connect(mapStateToProps, { fetchreqCompanySkillsObject})(SkillsUsage);
