import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';

const PageHead = ({ 
    title, 
    description, 
    children,
    goBack
}) => (
    <div className="page-header bg-navy-near-white">
        <h2>
            { goBack ? <span onClick={goBack} className="go-back i-arrow left"></span> : null}
            <span>{title}</span>
        </h2>
        {
            description
            ? (<p className="mt2 mb0 o-60">{description}</p>)
            : null
        }
        { children ? children : null }
    </div>
);

PageHead.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    title: PropTypes.string.isRequired,
    description: PropTypes.string
}

export default PageHead;