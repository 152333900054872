import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import './selectInput.sass';

const { Option } = Select;

class SelectInput extends Component {

    state = {
        value: ''
    }

    highLightMatchedText = (text) => {
        const { value } = this.state;
        let parts = text.split(new RegExp(`(${value})`, 'gi'));
        return (
            <span>
                { 
                    parts.map((part, i) => 
                        <span key={i} style={part === value ? { fontWeight: 'bold' } : {} }>
                        { part }
                        </span>
                    )
                }
            </span>
        );
    }

    handleSearch = value => {
        clearTimeout(this.timeout);
        this.setState({value: value})
        this.timeout = setTimeout( () => {
            this.props.getCompanyContactsOptions(value);
        }, 300)
    }


    render(){
        // get from props from the above component, selected person, and set new selected person  function 
        const { options, item, getFieldDecorator, object } = this.props;
        return (
            <div className="mt3">
                <label htmlFor="" className="b f6">{item.name}:</label>
                {
                    getFieldDecorator(item.key, {
                        initialValue: object[item.key],
                        validateTrigger: "onChange",
                        onChange: this.handleInputChange
                    })(
                        <Select
                            showSearch
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={this.handleSearch}
                            notFoundContent={null}
                        >
                        {
                            options.map( option => {
                                let string = option.firstName + ' ' + option.lastName + ', ' + option.email;
                                return(
                                <Option key={option.email} value={option.email}>{this.highLightMatchedText(string)}</Option>
                            )})
                        }
                        </Select>)
                }
            </div>
        );
    }
    
};

export default SelectInput;

SelectInput.propTypes = {
    item: PropTypes.object.isRequired,
    options: PropTypes.array,
    contactPerson: PropTypes.string,
    getFieldDecorator: PropTypes.func.isRequired,
    getCompanyContactsOptions: PropTypes.func.isRequired,
}