import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { analLogsSetPage } from '../../actions/analytics';

const PageIndex = ({i, currentPage, setPage}) => (<span className={`ml2 ${currentPage === i  ? 'link-active b' : 'link'}`} onClick={() => setPage(i)}>{i}</span>);

PageIndex.propTypes = {
    i: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
}

export class Pagination extends Component {

    setPage = page => {        
        const { pagination: { currentPage }, lastPage, analLogsSetPage } = this.props;
        if(page >= 1 && page <= lastPage && page !== currentPage){
            analLogsSetPage(page);
        }
    }

    pages = () => {
        const { pagination: { currentPage }, lastPage } = this.props;
        let pages = [];
        const gap = <span key="gap3" className="ml2 o-60">..</span>;
        const gap2 = <span key="gap4" className="ml2 o-60">..</span>;
        if(lastPage <= 4){
            for(let i = 1; i <= lastPage; i ++){
                pages.push(<PageIndex key={i} setPage={this.setPage} currentPage={currentPage} i={i} />);
            }
            return pages;
        } else{
            if(currentPage < 4){
                pages = [];
                for(let i = 1; i <= 4; i++){
                    pages.push(<PageIndex key={i} setPage={this.setPage} currentPage={currentPage} i={i} />)
                }
                pages.push(gap, <PageIndex key="last" currentPage={currentPage} setPage={this.setPage} i={lastPage} />)
                return pages;
            } else if(currentPage >= 4 && currentPage <= lastPage - 3){
                pages = [];
                pages.push(<PageIndex key="gap1" currentPage={currentPage} setPage={this.setPage} i={1} />, gap);
                for(let i = currentPage -1; i <= currentPage + 1;i ++){
                    pages.push(<PageIndex key={i} currentPage={currentPage} setPage={this.setPage} i={i} />);                
                }
                pages.push(gap2, <PageIndex key="last" currentPage={currentPage} setPage={this.setPage} i={lastPage} />)
                return pages;
            } 
            else {
                pages = [];
                pages.push(<PageIndex key="gap2" currentPage={currentPage} setPage={this.setPage} i={1} />, gap)
                for(let i = lastPage - 3; i <= lastPage;i ++){
                    pages.push(<PageIndex currentPage={currentPage} key={i} setPage={this.setPage} i={i} />);                
                }
                return pages;
            }
        }
    }

    render() {
        const { pagination: { size, currentPage, recordsPerPage }, lastPage } = this.props;
        const isEmpty = size === 0;
        const startRecord = (currentPage - 1) * recordsPerPage + 1,
            endRecord =  currentPage === lastPage ? size : currentPage * recordsPerPage;
        return (
            <div style={{marginTop: '1rem'}}>
                <div className="flex justify-between">
                    <p className="dib f6">
                    <span className="o-60">Page:</span>
                    {
                        isEmpty ? <PageIndex key={0} currentPage={0} setPage={this.setPage} i={0} />
                        : this.pages()
                    }
                    </p>
                    <p className="dib o-60 f6">{`Showing ${isEmpty ? size : startRecord + ' to ' + endRecord} of ${size} entries .`}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    pagination: state.analytics.logs.pagination,
    lastPage: Math.ceil(state.analytics.logs.pagination.size/state.analytics.logs.pagination.recordsPerPage),
})

const mapDispatchToProps = {
    analLogsSetPage
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);

Pagination.propTypes = {
    pagination: PropTypes.shape({
      currentPage: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
      recordsPerPage: PropTypes.number.isRequired,
    }).isRequired,
    analLogsSetPage: PropTypes.func.isRequired
}
