import React, { Component } from 'react'
import PropTypes from 'prop-types'

const labels = {
    'username': 'SAP ID',
    'firstname': 'First Name',
    'lastname': 'Last Name',
    'company': 'Company',
    'e_mail': 'Email',
    'tel1_numbr': 'Phone Number',
    'reason': 'Reason'
}

class FormResult extends Component {

    showResult = () => {
        const { message: { formResult } } = this.props;
        let results = [];
        for(let prop in formResult){
            results.push(
            <div key={prop} className="mt3">
                <label className="b f6">{labels[prop]}:</label>
                <input type="text" value={formResult[prop]} readOnly style={{backgroundColor: 'white'}} className="w-100 mt1 pv2 ph2 f5"/>
            </div>)
        }
        return results;
    }

    render() {
        return (
            <div className="pl2 bl b--black-05 bw3 measure smartForm">
                <h4 className="mb2">Form Result</h4>
                <div className="mt3">
                    {
                        this.showResult()
                    }
                </div>
            </div>
        )
    }
}

FormResult.propTypes = {
    message: PropTypes.shape({
      formResult: PropTypes.object.isRequired,
    }).isRequired,
}

export default FormResult;