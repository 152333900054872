import {
    SIGN_IN,
    SIGN_UP,
    SIGNED_IN,
    SIGNED_OUT,
    SIGNED_UP,
    USER_INFO_SUCCESS,
    AUTH_LOADING,
    AUTH_ERROR,
    VERIFY_CONTACT,
    REQUIRE_NEW_PASSWORD
} from '../constants'
  
const initialState = {
    authState: SIGN_IN,
    user: {
        roles: [],
        company: '',
        firstName: '',
        lastName: '',
        email: ''
    },
    isSuperAdmin: false,
    loading: false
}
  
export default function(state = initialState, { type, payload }) {
    switch (type) {
        case VERIFY_CONTACT:
        case REQUIRE_NEW_PASSWORD:
            return {
                ...state,
                authState: type,
                loading: false
            }
        case SIGN_IN:
            return {
                ...state,
                authState: SIGN_IN,
                loading: false
            }
        case AUTH_LOADING:
            return {
                ...state,
                loading: payload
            }
        case AUTH_ERROR:
            return {
                ...state,
                loading: false
            }
        case SIGNED_OUT:
            return {
                ...state,
                authState: SIGNED_OUT,
                user: {
                    ...initialState.user
                },
                isSuperAdmin: false,
                loading: false
            }
        case SIGN_UP:
            return {
                ...state,
                authState: SIGN_UP,
                loading: false
            }  
        case SIGNED_IN:
            return {
                ...state,
                authState: SIGNED_IN,
                loading: false
            }
        case SIGNED_UP:
            return {
                ...state,
                authState: SIGNED_UP,
                loading: false
            }
        case USER_INFO_SUCCESS:
            return {
                ...state,
                user: payload,
                loading: false,
                isSuperAdmin: payload.roles.indexOf('SuperAdmin') !== -1
            }
        default:
            return state
    }
} 