import React from 'react';
import PropTypes from 'prop-types';
import ConversationScript from '../ConversationScript';
import { connect } from 'react-redux';
import moment from 'moment';
import { closeDetailsView } from '../../actions/analytics';
import { detailView } from './objectContainer';
import './detailedView.sass';

const DetailedView = ({ record, history, closeDetailsView }) => {
	let color;
	switch (record.status) {
		case 'Failed':
			color = 'red';
			break;
		case 'Processing':
			color = 'grey';
			break;
		default:
			color = 'green';
			break;
	}
	return (
		<div onClick={closeDetailsView} className="z-max fixed w-100 vh-100 bg-black-90 pa3 overflow-y-scroll top-0 left-0">
			<div className="mw7 center pv3 pv4 ph2 mb2 mb5-l">
				<div className="pa3 pa4-ns pa5-l bg-white br1" onClick={e => e.stopPropagation()}>
					<h1 className="f3 f2-ns mv0">Conversation details
						<img src="/assets/img/24icn-close-black.svg" 
						className="pointer o-60" style={{ float: 'right', padding: '5px' }} 
						onClick={closeDetailsView} alt="Close" />
					</h1>
					<div className="mt2 deatails-view-container">
						{
							detailView.map((i, k) => (
								<div key={i.key} className={"mb0 f6 item-" + k}>
									<p className="mb1 fw5 o-60">{i.name}:</p>
									<p className={"mt1 mb0 b lh-title " + (i.key === 'status' ? "o-60 " + color : "")}>
										{['approvedAt', 'date'].indexOf(i.key) > -1 ?
											moment(record.date).format("D MMM YYYY, H:mm:ss")
											: i.key === 'userName' ? `${record.user.firstName} ${record.user.lastName}` : record[i.key]}
									</p>
								</div>
							)
							)
						}
					</div>
					<h3 className="mt4">Conversation script</h3>
					<div className="ba b--black-05 pt2 bg-white br1">
						{history.length ? <ConversationScript history={history} record={record} /> : null}
					</div>
					<div className="mt3">
						<input type="submit" value="Close"
							onClick={closeDetailsView}
							className="dib btn btn-blue pv2 ph3 f5" />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	record: state.analytics.logs.detailsView.record,
	history: state.analytics.logs.detailsView.history,
})

const mapDispatchToProps = {
	closeDetailsView
}


export default connect(mapStateToProps, mapDispatchToProps)(DetailedView);

DetailedView.propTypes = {
	record: PropTypes.object.isRequired,
	history: PropTypes.array,
	closeDetailsView: PropTypes.func.isRequired,
}