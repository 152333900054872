import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchreqCompanySkillsObject } from '../actions/settings';
import { getAnalStats, getAnalLogs } from '../actions/analytics';
import Analytics from '../components/Analytics';
import TopUsers from '../components/Analytics/TopUsers';
import Logs from '../components/Analytics/Logs';

class AnalyticsRoot extends Component {

    componentDidMount(){
        // this.props.getAnalStats();
        // this.props.getAnalLogs();
        // this.props.fetchreqCompanySkillsObject();
    }

    render() {
        const { match } = this.props;
        return (
            <div>
                <Switch>
                    <Route path={`${match.url}/logs`} component={ Logs } />
                    <Route exact path={`${match.url}/`} component={ Analytics } />
                    <Route exact path={`${match.url}/top-users`} component={ TopUsers } />
                </Switch>
            </div>
        )
    }
}

const mapDispatchToProps = {
    fetchreqCompanySkillsObject,
    getAnalStats,
    getAnalLogs
}

export default connect(null, mapDispatchToProps)(AnalyticsRoot)

AnalyticsRoot.propTypes = {
    fetchreqCompanySkillsObject: PropTypes.func.isRequired,
    getAnalStats: PropTypes.func.isRequired,
    getAnalLogs: PropTypes.func.isRequired,
}