import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import NotFound from '../components/NotFound';

class Authorization extends Component {
    render() {
        if (this.props.userRoles.some(role => this.props.roles.includes(role))) {
            return this.props.render();
        }
        if (this.props.notFound) {
            return (<Route component={ NotFound } />)
        }
        return null;
    }
}

Authorization.propTypes = {
    userRoles: PropTypes.array.isRequired,
    render: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired
}

Authorization = connect(
state => ({
    userRoles: state.auth.user.roles
}), {
})(Authorization)

export default function(roles, Comp, notFound = true) {
    const render = () => <Comp/>;
    if (roles && roles.length) {
        return () => <Authorization notFound={notFound} roles={roles} render={render}/>;
    }
    return render;
}

export function hasAccess(roles, userRoles, render) {
    if (!roles || roles.length === 0) return render();
    if (userRoles.some(role => roles.includes(role))) {
        return render();
    }
    return null;
}