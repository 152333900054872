import React from 'react';
import { VerifyContact } from 'aws-amplify-react';
import { Form, Input, Button } from 'antd';

class VerifyContactForm extends VerifyContact {

    verifyContact(e) {
        this.inputs = {
            contact: true,
            checkedValue: 'email'
        }
        this.verify(e);
    }

    componentDidUpdate(prevProps) {
        if (this.props.authState === 'verifyContact'
        && this.props.authState !== prevProps.authState) {
            this.verifyContact();
        }  
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) this.submit(e);
        });
    }

    renderError = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors && errors.length > 0) {
            return (
                <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
            )
        } else return null;
    }

    showComponent() {
        const { hide, authData } = this.props;
        if (hide && hide.includes(VerifyContactForm)) { return null; }

        const { 
            getFieldDecorator
        } = this.props.form;

        return (
            <div>
                <div className="mw6 center pv3 pv4-ns">
                    <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                        <img className="" src="/assets/img/hala-logo.svg" alt="" />
                    </div>
                </div>
                <div className="mw6 center ph2 mb2 mb5-l">
                    <div className="pa3 pa4-ns pa5-l bg-white br1">
                        <h1 className="f3 f2-ns mv0">Verify your account</h1>
                        <p className="mt2 mb0 lh-copy">Verification code was sent to the <strong>{ authData.unverified.email }</strong>. Please check your mailbox and enter verification code from the e-mail.</p>
                        <Form onSubmit={this.handleSubmit} className="mt3">
                           <div>
                               <label htmlFor="code">Verification Code:</label>
                               {getFieldDecorator('code', {
                                    rules: [{ 
                                        required: true, 
                                        message: 'Please input your Code!' 
                                    },{
                                        type: "string",
                                        pattern: /^\d+$/,
                                        message: 'Code in invalid format!'
                                    }],
                                    validateTrigger: "onSubmit",
                                    onChange: this.handleInputChange
                                })(
                                    <Input prefixCls="h"  className="w-100 mt1 pv2 ph2 f5" type="text" autoComplete="new-password" name="code"/>
                                )}
                                { this.renderError('code') }
                           </div>
                            <div className="mt3">
                                <Button type="submit" htmlType="submit" className="w-100 dib btn btn-blue pv2 ph3 f5 mr2 button" size="large">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }

}

const WrappedVerifyContactForm = Form.create()(VerifyContactForm);
export default WrappedVerifyContactForm;