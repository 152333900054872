import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import './antd-general.css';
import './index.css';
import Root from './containers/Root';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store'
import Amplify from '@aws-amplify/core';
import aws_exports from './aws-exports';
import './tachyons.min.css';
import './index.sass';
import ReactGA from 'react-ga';

Amplify.configure(aws_exports);
const logger = new Amplify.Logger('Bootstrap');

if (process.env.NODE_ENV === 'production') {
    if (aws_exports.googleAnalytics) {
        ReactGA.initialize(aws_exports.googleAnalytics);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
} else {
    Amplify.Logger.LOG_LEVEL = 'DEBUG';
    logger.debug('development environment');
}

const store = configureStore();

render(
    <Root store={store} />,
    document.getElementById('root')
);

registerServiceWorker();
