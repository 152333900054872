import { combineReducers } from 'redux'
import notifications from './notifications'
import auth from './auth'
import users from './users'
import forms from './forms';
import integrations from './integrations';
import skills from './skills';
import popups from './popupComponents/index';
import redirect from './redirect';
import settings from './settings/index';
import analytics from './analytics/index';
import filtersData from './filtersData';

const rootReducer = combineReducers({
    auth,
    notifications,
    users,
    forms,
    integrations,
    skills,
    popups,
    redirect,
    settings,
    analytics,
    filtersData
})

export default rootReducer;
