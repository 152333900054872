import { combineReducers } from 'redux';
import {
    ANALYTICS_STATS_REQ,
    ANALYTICS_STATS_SUCCESS,
    ANALYTICS_STATS_ERROR
} from '../../constants';

const initSt = {};
initSt.data = {
    apiCalls: 0,
    conversations: 0,
    users: 0,
    successRate: 0,
    topSkills: [],
    topUsers: [],
    messagesByDate: []
};

const data = (state = initSt.data, { type, data }) => {
    switch (type) {
        case ANALYTICS_STATS_SUCCESS:
            return {
                ...state,
                ...data.data
            }
        default:
            return state;
    }
}

const isFetching = (state = false, {type}) => {
    switch(type){
        case ANALYTICS_STATS_REQ:
            return true;
        case ANALYTICS_STATS_SUCCESS:
        case ANALYTICS_STATS_ERROR:
            return false;
        default:
            return state;
    }
}

const lastUpdated = (state = '', {type, data}) => {
    if(type === ANALYTICS_STATS_SUCCESS){
        return data.date;
    }
    return state;
}

export default combineReducers({data, isFetching, lastUpdated});