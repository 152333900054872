import { combineReducers } from 'redux';
import {
    REQ_SKILLS,
    REQ_SKILLS_SUCCESS,
    REQ_SKILLS_ERROR,
    SET_SKILL_SEARCH,
    SET_SKILL_PROVIDER,
    SET_SKILL_AREA,
    SET_SKILL_DOMAIN_KNOWLEDGE,
    RESET_SKILL_FILTERS,
    CREATE_SKILL,
    CREATE_SKILL_SUCCESS,
    CREATE_SKILL_ERROR,
    UPDATE_SKILL,
    UPDATE_SKILL_SUCCESS,
    UPDATE_SKILL_ERROR,
    DELETE_SKILL,
    DELETE_SKILL_ERROR,
    DELETE_SKILL_SUCCESS
} from '../constants';

const data = (state = [], {type, data}) => {
    switch (type) {
        case REQ_SKILLS_SUCCESS:
        return [...data];
        case CREATE_SKILL_SUCCESS:
            return [...state, data];
        case UPDATE_SKILL_SUCCESS:
            return state.map( skill => {
                return skill._id === data._id ? data : skill;
            })
        case DELETE_SKILL_SUCCESS:
            return state.filter(skill => {
                return skill._id !== data._id;
            });
        default:
            return state;
    }
}

const fetching = (state = false, {type}) => {
    switch (type) {
        case REQ_SKILLS:
        case CREATE_SKILL:
        case UPDATE_SKILL:
        case DELETE_SKILL:
            return true;
        case REQ_SKILLS_SUCCESS:
        case REQ_SKILLS_ERROR:
        case CREATE_SKILL_SUCCESS:
        case CREATE_SKILL_ERROR:
        case UPDATE_SKILL_SUCCESS:
        case UPDATE_SKILL_ERROR:
        case DELETE_SKILL_SUCCESS:
        case DELETE_SKILL_ERROR:
            return false;
        default:
            return state;
    }
}

const initialFiltersState = {
    search: '',
    provider: '',
    area: '',
    domainKnowledge: ''
};

const filters = (state = initialFiltersState, {type, value}) => {
    switch (type) {
        case SET_SKILL_SEARCH:
            return {...state,
            search: value
        };
        case SET_SKILL_PROVIDER:
            return {...state,
            provider: value
        };
        case SET_SKILL_AREA:
            return {...state,
            area: value
        };
        case SET_SKILL_DOMAIN_KNOWLEDGE:
            return {...state,
                domainKnowledge: value
            }
        case RESET_SKILL_FILTERS:
            return initialFiltersState;
        default:
            return state;
    }
}


export default combineReducers({data, fetching, filters});

// Selectors

export const filterSkills = (state, filter) => {
    return filterBySearchWord(filterByArea(filterByProvider(filterByDomainKnowledge(state, filter.domainKnowledge), filter.provider), filter.area), filter.search);
}

const filterByProvider = (state, filter) => {
    if(filter !== ''){
       return state.filter( skill => {
            return skill.serviceProvider === filter;
        })
    } else{
        return state;
    }
}

const filterByDomainKnowledge = (state, filter) => {
    if(filter !== ''){
       return state.filter( skill => {
            return skill.domainKnowledge === filter;
        })
    } else {
        return state;
    }
}

const filterByArea = (state, filter) => {
    if(filter !== ''){
        return state.filter( skill => {
            return skill.area === filter;
        })
    } else {
        return state;
    }
}

const filterBySearchWord = (state, filter) => {
    return state.filter( skill => {
    	return (skill.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) || (skill.description.toLowerCase().indexOf(filter.toLowerCase()) > -1);
    })
}

export const findSkill = (state, id) => {
    if(id && id !== 'newSkill'){
        return state.find( skill => skill._id === id);
    } else return {};
}
