import { combineReducers } from 'redux';
import {
    FILTERS_REQ_USERS_SUCCESS,
    FILTERS_REQ_SKILLS_SUCCESS
} from '../constants';

const users = (state = [], { type, payload }) => {
    switch (type) {
        case FILTERS_REQ_USERS_SUCCESS:
            if (payload.length) {
                return payload;
            }
            return state;
        default:
            return state;
    }
};

const skills = (state = [], { type, payload }) => {
    switch (type) {
        case FILTERS_REQ_SKILLS_SUCCESS:
            if (payload.length) {
                return payload;
            }
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    users,
    skills
});