import API from '@aws-amplify/api';
import awsconfig from '../aws-exports';

const apiName = 'api';
const devApi = 'devApi'; // testing 

API.configure(awsconfig);

export async function getUserRoles() {
  return await API.get(apiName, `/user-roles`);
}

export async function getUserSapId() {
  return await API.get(devApi, `/user-info/sap-id`);
}

export async function getUsers(queryStringParameters) {
  return await API.get(apiName, `/users`, { queryStringParameters });
}

export async function updateUser(body) {
  return await API.post(apiName, `/users/${body.id}`, { body });
}

export async function createUser(body) {
  return await API.post(apiName, `/users`, { body });
}

export async function deleteUsers(body) {
  return await API.del(apiName, `/users`, { body });
}

export async function getIntegrations() {
  return await API.get(devApi, `/integrations`);
}

export async function createIntegration(body) {
  return await API.post(apiName, `/integrations`, { body });
}

export async function updateIntegration(body) {
  return await API.put(apiName, `/integrations/${body.id}`, { body });
}

export async function deleteIntegration(id) {
  return await API.del(apiName, `/integrations/${id}`);
}


//  SKILLS

export async function getSkills() {
  return await API.get(devApi, '/skills/store');
}

export async function createSkill(body) {
  return await API.post(devApi, `/skills/store/create`, { body });
}

export async function updateSkill(body) {
  return await API.put(devApi, `/skills/store/update/${body._id}`, { body });
}

export async function deleteSkill(body) {
  return await API.del(devApi, `/skills/store/delete/${body._id}`, { body });
}

// BILLING

export const getInvoices = async () => {
  return await API.get(devApi, '/company/billing');
}

export const createInvoice = async body => {
  return await API.post(devApi, '/company/billing', { body })
}

export const updateInvoice = async body => {
  return await API.put(devApi, `/company/billing/${body._id}`, { body })
}

export const deleteInvoice = async id => {
  return await API.del(devApi, `/company/billing/${id}`)
}

// COMPANY ACCOUNT SETTINGS

export async function getCompanySettings() {
  return await API.get(devApi, `/company/info`);
}

export async function updateCompany(body) {
  return await API.put(devApi, '/company/info', { body });
  //return Promise.reject(new Error('Error setting total skills limit'));
}

export async function saveNotifications(body) {
  return await API.put(apiName, '/company/notifications', { body })
  //return Promise.reject(new Error('Error saving Notifications settings'));
}

export async function getCompanySkills() {
  return await API.get(devApi, '/company/skills/usage');
}



export async function setSkillLimit(limit, skill) {
  skill.limit = limit;
  return Promise.resolve(skill);
  //return Promise.reject(new Error('Error setting limit for skill' + skill.name));
}

export async function setTotalSkillsLimit(limit) {
  return Promise.resolve(limit);
  //return Promise.reject(new Error('Error setting total skills limit'));
}

export async function setSupportItemLimit(limit, item) {
  item.limit = limit;
  return Promise.resolve(item);
  //return Promise.reject(new Error('Error setting limit for skill' + skill.name));
}

export async function setTotalSupportLimit(limit) {
  return Promise.resolve(limit);
  //return Promise.reject(new Error('Error setting total skills limit'));
}


export async function getCompanyContactsOptions(value) {
  return Promise.resolve([{
    name: 'Andrii Rudchuk',
    email: 'rudchuk@hala.ai',
    disabled: false
}, {
    name: 'Mykyta Bezhenov',
    email: 'mykyta.bezhenov@hala.ai',
    disabled: false
}, {
    name: 'Petar Petrov',
    email: 'petar@hala.ai',
    disabled: false
}]);
  //return await API.get(apiName, `/company/:${value}`)
}

export async function getAnalyticsStatistics(query) {
  return await API.get(devApi, '/analytics/stats', {
    queryStringParameters: query
  });
}

export async function getTopUsers(query) {
  return await API.get(devApi, '/analytics/top-users', {
    queryStringParameters: query
  });
}

export async function getAnalyticsLogs(body) {
  return await API.post(devApi, '/analytics/logs', { body });
}

export async function getConversationHistory(payloadId) {
  return await API.get(devApi, `/analytics/logs/conversation/${payloadId}`);
}

export async function getAllUsers() {
  return await API.get(devApi, `/users`);
}

export async function getAllSkills() {
  return await API.get(devApi, `/skills`);
}