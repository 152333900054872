import React from 'react';
import PropTypes from 'prop-types';

const checkItem = (type, i) => {
    switch(type){
        case 'user':
            return (<p>{`${i.firstName} ${i.lastName}`}</p>);
        case 'connection':
            return (<p>{`${i.name}`}</p>)
        case 'skill':
            return (<p>{`${i.name}`}</p>)
        case 'invoice':
            return (<p>{`${i.invoiceId}`}</p>)
        default:
            return null;
    }
}

const DeletionList = ({list, type}) => {
    
    return (
        <div className="mt2 ">
            <ul className="list pa3 pb2 bg-washed-red br1 f6" style={{maxHeight: '160px', overflowY: 'auto'}}>
            {
               list.map( (item,i) => {
                return (
                    <li  key={`${item.id}-${i}`} className="b mb2">
                        {
                            checkItem(type, item)
                        } 
                    </li>
                )
               })
            }
            </ul>
        </div>
    )
}

export default DeletionList;

DeletionList.propTypes = {
    list: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
}