export const credentials = [{
    type: 'text',
    key: 'username',
    name: 'Username',
    confidential: true,
    validationRules: [{
        whitespace: true, message: 'The Username field can not be empty!'
    }, {
        required: true, message: 'The Username field is required!'
    }]
}, {
    type: 'password',
    key: 'password',
    name: 'Password',
    confidential: true,
    validationRules: [{
        type: 'string',
        message: 'The input is not a valid Password!'
    }, {
        whitespace: true, message: 'The Password can not be empty!'
    }, {
        required: true, message: 'The Password field is required!'
    }]
}];

export const details = [{
    type: 'text',
    key: 'name',
    name: 'Name of the service',
    confidential: false,
    validationRules: [{
        whitespace: true, message: 'The Name of the service field can not be empty!'
    }, {
        required: true, message: 'The Name of the service field is required!'
    }]
}];

export const connection = [{
    type: 'text',
    key: 'hostname',
    name: 'Host Name',
    confidential: true,
    validationRules: [{
        whitespace: true, message: 'The Host Name field can not be empty!'
    }, {
        required: true, message: 'The Host Name field is required!'
    }]
}, {
    type: 'text',
    key: 'port',
    name: 'Port',
    confidential:  true,
    validationRules: [{
        whitespace: true, message: 'The Port field can not be empty!'
    }, {
        required: true, message: 'The Port field is required!'
    }]
}];


export const notAdminValidationRules = [{
    whitespace: true, message: 'The field can not be empty!'
}, {
    required: true, message: 'The field is required!'
}];
