import {
    RENDER_UPDATE_LIMITS_POPUP,
    RESET_UPDATE_LIMITS_POPUP,
} from '../../constants';

const initialState = {
    show: false,
    data: {},
    type: '',
    ok: null,
};

export default function(state = initialState, {type, payload}){
    switch(type){
        case RENDER_UPDATE_LIMITS_POPUP:
            return {...payload, show: true};
        case RESET_UPDATE_LIMITS_POPUP:
            return initialState;
        default:
            return state;
    }
}
