import React from 'react';

const Configurations = () => {
    return (
        <div className="pa4 pb5-l">
            <div className="ph3">
                <div className="measure-wide">
                    <h2 className="mt0 mb2">Skill configurations</h2>
                    <p className="mt2">Skill configurations are required for skill instalation and functioning.</p>
                    <h3 className="mt4 mb2">Integration with backend</h3>
                    <div className="mt3">
                        <label htmlFor="select" className="b f6">Select SAP system:</label>
                        <select id="select" name="" className="w-100 input-reset mt1 pv2 pl2 pr4 f5">
                            <option value="" hidden="" selected=""></option>
                            <option value="One">ComoBox</option>
                            <option value="Two">Two</option>
                            <option value="Three">Three</option>
                            <option value="Four">Four</option>
                        </select>
                    </div>
                    <h3 className="mt4 mb2">Install transport request</h3>
                    <p className="mt2">You need to install transport request inside your SAP system and create Web Service to enable the integration.</p>
                    <span className="dib btn btn-ghost pv2 ph3 f5">Download transport request (1.4mb)</span>
                    <div className="flex items-center mt3">
                        <input id="checkbox-3" type="checkbox" className="mr2" />
                        <label htmlFor="checkbox-3" className="lh-copy fw5">Yes, I've installed transport request</label>
                    </div>
                    <h3 className="mt4 mb2">Activate skill</h3>
                    <p className="mt2">Temporary enable/disable skill.</p>
                    <div className="flex items-center mt3">
                        <span className="toggle toggle-on mr2">
                            <span className="toggle-switch"></span>
                        </span>
                        <label htmlFor="" className="lh-copy fw5">Skill is activated</label>
                    </div>
                    <hr className="ba b--black-05 mv4" />
                    <h2 className="mt0 mb2">Approval workflow</h2>
                    <p className="mt2">For additional control you can setup a verification step. We will send an email for action approval.</p>
                    <div className="flex items-center mt3">
                        <input id="checkbox-1" type="checkbox" className="mr2" checked="" />
                        <label htmlFor="checkbox-1" className="lh-copy fw5">Turn on approval request</label>
                    </div>
                    <div className="mt3">
                        <label htmlFor="" className="b f6">Reviewer email:</label>
                        <input id="" value="" type="text" name="" className="w-100 mt1 pv2 ph2 f5" />
                    </div>
                    <hr className="ba b--black-05 mv4" />
                    <h2 className="mt0 mb2">Notifications</h2>
                    <p className="mt2">Turn on notifications for request completion.</p>
                    <div className="flex items-center mt3">
                        <input id="checkbox-3" type="checkbox" className="mr2" />
                        <label htmlFor="checkbox-3" className="lh-copy fw5">Notify user</label>
                    </div>
                    <div className="flex items-center mt3">
                        <input id="checkbox-3" type="checkbox" className="mr2" />
                        <label htmlFor="checkbox-3" className="lh-copy fw5">Notify approver</label>
                    </div>
                    <hr className="ba b--black-05 mv4" />
                    <span className="dib btn btn-blue pv2 ph3 f5">Save configuraions</span>
                </div>
            </div>

        </div>
    );
};

export default Configurations;