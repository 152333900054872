import Auth from '@aws-amplify/auth';



const awsmobile = {
    'aws_app_analytics': 'disabled',
    'aws_cognito_region': 'eu-central-1',
    'aws_mandatory_sign_in': 'enable',
    // 'aws_mobile_analytics_app_id': '4622f3c1bd724ba5826757194b83655a',
    // 'aws_mobile_analytics_app_region': 'us-east-1',
    // 'aws_project_id': 'f004457f-c426-487d-a96d-6de2336802a0',
    'aws_project_name': 'hala-portal',
    'aws_project_region': 'eu-central-1',
    // 'aws_resource_name_prefix': 'halaportal-mobilehub-2027102562',
    'aws_sign_in_enabled': 'enable',
    'aws_user_pools': 'enable',
    'aws_cognito_identity_pool_id': 'eu-central-1:b6005257-14ad-4e13-a68c-32a457764df8',
    'aws_user_pools_id': 'eu-central-1_t59cuEfDz',
    'aws_user_pools_web_client_id': '1ou024tnhqk4gij83rq4b2scof',
    'aws_user_files_s3_bucket': 'hala-user-files',
    'aws_user_files_s3_bucket_region': 'eu-central-1',
    'aws_cloud_logic_custom': [
        {
            "name": "api",
            "endpoint": "https://w0xgwd1jg1.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "devApi",
            "endpoint": (() => {
                if (process.env.NODE_ENV === 'production') {
                    return "https://portal-api.hala.ai";
                }
                return "http://localhost:9010";
            })(),
            "custom_header": async () => { 
                return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
            }
        }
    ],
    devTools: true,
    googleAnalytics: "UA-110087397-4"
}

export default awsmobile;
