/* Auth */
export const SIGNED_IN = 'signedIn';
export const SIGNED_OUT = 'signedOut';
export const SIGN_IN = 'signIn';
export const SIGN_UP = 'signUp';
export const SIGNED_UP = 'signedUp';
export const CONFIRM_SIGN_IN = 'confirmSignIn';
export const CONFIRM_SIGN_UP = 'confirmSignUp';
export const FORGOT_PASSWORD = 'forgotPassword';
export const VERIFY_CONTACT = 'verifyContact';
export const AUTH_LOADING = 'AUTH_LOADING';
export const REQUIRE_NEW_PASSWORD = 'requireNewPassword';


/* ERRORS */

export const ERROR = 'ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

/**********************************/

/* Users */
export const REQ_USERS = 'REQ_USERS';
export const REQ_USERS_ERROR = 'REQ_USERS_ERROR';
export const REQ_USERS_SUCCESS = 'REQ_USERS_SUCCESS';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const SELECT_USER = 'SELECT_USER';
export const DESELECT_USER = 'DESELECT_USER';
export const SELECT_ALL_USERS = 'SELECT_ALL_USERS';
export const DESELECT_ALL_USERS = 'DESELECT_ALL_USERS';

export const SET_USERS_FILTER = 'SET_USERS_FILTER';

/* User */
export const SET_USER_FORM = 'SET_USER_FORM';
export const NEW_USER = 'NEW_USER';
export const CANCEL_USER_FORM = 'CANCEL_USER_FORM';
export const EDIT_USER = 'EDIT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_ERROR = 'DELETE_USERS_ERROR';

/*  Users form */
export const RESET_FORM_STATE = 'RESET_FORM_STATE';

/**********************************/

/*   Integrations    */
// FIlter integrations
export const SET_INTEGRATION_SEARCH = 'SET_INTEGRATION_SEARCH';
export const SET_INTEGRATION_PROVIDER = 'SET_INTEGRATION_PROVIDER';
export const SET_INTEGRATION_CATEGORY = 'SET_INTEGRATION_CATEGORY';
export const SET_INTEGRATION_STATUS = 'SET_INTEGRATION_STATUS';

export const RESET_INTEGRATION_FILTER = 'RESET_INTEGRATION_FILTER';

export const SELECT_INTEGRATIONS = 'SELECT_INTEGRATIONS';

// Integration modificaitons
export const REQ_INTEGRATIONS = 'REQ_INTEGRATIONS';
export const REQ_INTEGRATIONS_SUCCESS = 'REQ_INTEGRATIONS_SUCCESS';
export const REQ_INTEGRATIONS_ERROR = 'REQ_INTEGRATIONS_ERROR';

export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const CREATE_INTEGRATION_SUCCESS = 'CREATE_INTEGRATION_SUCCESS';
export const CREATE_INTEGRATION_ERROR = 'CREATE_INTEGRATION_ERROR';

export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const UPDATE_INTEGRATION_SUCCESS = 'UPDATE_INTEGRATION_SUCCESS';
export const UPDATE_INTEGRATION_ERROR = 'UPDATE_INTEGRATION_ERROR';

export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';
export const DELETE_INTEGRATION_ERROR = 'DELETE_INTEGRATION_ERROR';

export const DELETE_INTEGRATIONS_SUCCESS = 'DELETE_INTEGRATIONS_SUCCESS';

/*****************************************/

/*         SKills           */

// Skills filters

export const SET_SKILL_SEARCH = 'SET_SKILL_SEARCH';
export const SET_SKILL_PROVIDER = 'SET_SKILL_PROVIDER';
export const SET_SKILL_AREA = 'SET_SKILL_AREA';
export const SET_SKILL_DOMAIN_KNOWLEDGE = 'SET_SKILL_DOMAIN_KNOWLEDGE';
export const RESET_SKILL_FILTERS = 'RESET_SKILL_FILTERS';
export const SELECT_SKILLS = 'SELECT_SKILLS';


// Skill modifications
export const REQ_SKILLS = 'REQ_SKILLS';
export const REQ_SKILLS_SUCCESS = 'REQ_SKILLS_SUCCESS';
export const REQ_SKILLS_ERROR = 'REQ_SKILLS_ERROR';

export const CREATE_SKILL = 'CREATE_SKILL';
export const CREATE_SKILL_SUCCESS = 'CREATE_SKILL_SUCCESS';
export const CREATE_SKILL_ERROR = 'CREATE_SKILL_ERROR';
export const UPDATE_SKILL = 'UPDATE_SKILL';
export const UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS';
export const UPDATE_SKILL_ERROR = 'UPDATE_SKILL_ERROR';
export const DELETE_SKILL = 'DELETE_SKILL';
export const DELETE_SKILL_ERROR = 'DELETE_SKILL_ERROR';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';

/******************************************/

// POPUPS

// CONFIRM DELETE
export const RENDER_CONFIRMATION_POPUP = 'RENDER_CONFIRMATION_POPUP';
export const RESET_CONFIRMATION_POPUP = 'RESET_CONFIRMATION_POPUP';

// CONFIRM DELETE
export const RENDER_UPDATE_LIMITS_POPUP = 'RENDER_UPDATE_LIMITS_POPUP';
export const RESET_UPDATE_LIMITS_POPUP = 'RESET_UPDATE_LIMITS_POPUP';

// REDIRECT
export const REDIRECT = 'REDIRECT';
export const RESET_REDIRECT = 'RESET_REDIRECT';

/******************************************/

// SETTINGS

// notifications

export const SAVE_NOTIFICATION_SETTINGS = 'SAVE_NOTIFICATION_SETTINGS';
export const SAVE_NOTIFICATION_SETTINGS_SUCCESS = 'SAVE_NOTIFICATION_SETTINGS_SUCCESS';
export const SAVE_NOTIFICATION_SETTINGS_ERROR = 'SAVE_NOTIFICATION_SETTINGS_ERROR';

//skill limits
export const SET_SKILL_LIMIT = 'SET_SKILL_LIMIT';
export const SET_SKILL_LIMIT_SUCCESS = 'SET_SKILL_LIMIT_SUCCESS';
export const SET_SKILL_LIMIT_ERROR = 'SET_SKILL_LIMIT_ERROR';

// total skills limit

export const SET_TOTAL_SKILLS_LIMIT = 'SET_TOTAL_SKILLS_LIMIT';
export const SET_TOTAL_SKILLS_LIMIT_SUCCESS = 'SET_TOTAL_SKILLS_LIMIT_SUCCESS';
export const SET_TOTAL_SKILLS_LIMIT_ERROR = 'SET_TOTAL_SKILLS_LIMIT_ERROR';

// support item limit
export const SET_SUPPORT_ITEM_LIMIT = 'SET_SUPPORT_ITEM_LIMIT';
export const SET_SUPPORT_ITEM_LIMIT_SUCCESS = 'SET_SUPPORT_ITEM_LIMIT_SUCCESS';
export const SET_SUPPORT_ITEM_LIMIT_ERROR = 'SET_SUPPORT_ITEM_LIMIT_ERROR';
    
// total support limit

export const SET_TOTAL_SUPPORT_LIMIT = 'SET_TOTAL_SUPPORT_LIMIT';
export const SET_TOTAL_SUPPORT_LIMIT_SUCCESS = 'SET_TOTAL_SUPPORT_LIMIT_SUCCESS';
export const SET_TOTAL_SUPPORT_LIMIT_ERROR = 'SET_TOTAL_SUPPORT_LIMIT_ERROR';

//company data object
export const REQ_COMPANY_INFO = 'REQ_COMAPNY_SETTINGS';
export const REQ_COMPANY_INFO_SUCCESS = 'REQ_COMAPNY_SETTINGS_SUCCESS';
export const REQ_COMPANY_INFO_ERROR = 'REQ_COMAPNY_SETTINGS_ERROR';

// company skills array 
export const REQ_COMAPNY_SKILLS = 'REQ_COMAPNY_SKILLS';
export const REQ_COMAPNY_SKILLS_SUCCESS = 'REQ_COMAPNY_SKILLS_SUCCESS';
export const REQ_COMAPNY_SKILLS_ERROR = 'REQ_COMAPNY_SKILLS_ERROR';

// set dynamcally filtered company contact options
export const COMPANY_CONTACTS_OPTIONS = 'COMPANY_CONTACTS_OPTIONS';


// Update profile

export const UPDATE_COMPANY_INFO = 'UPDATE_PROFILE_SETTINGS';
export const UPDATE_COMPANY_INFO_SUCCESS = 'UPDATE_PROFILE_SETTINGS_SUCCESS';
export const UPDATE_COMPANY_INFO_ERROR = 'UPDATE_PROFILE_SETTINGS_ERROR';

/******************************************/

// ANALYTICS

export const ANALYTICS_STATS_REQ = 'ANALYTICS_STATS_REQ';
export const ANALYTICS_STATS_SUCCESS = 'ANALYTICS_STATS_SUCCESS';
export const ANALYTICS_STATS_ERROR = 'ANALYTICS_STATS_ERROR';
export const ANALYTICS_LOGS_REQ = 'ANALYTICS_LOGS_REQ';
export const ANALYTICS_LOGS_SUCCESS = 'ANALYTICS_LOGS_SUCCESS';
export const ANALYTICS_LOGS_ERROR = 'ANALYTICS_LOGS_ERROR';
export const LOGS_SET_PAGE = 'LOGS_SET_PAGE';
export const FETCH_PAYLOAD_HISTORY_REQ = 'FETCH_PAYLOAD_HISTORY_REQ';
export const FETCH_PAYLOAD_HISTORY_SUCCESS = 'FETCH_PAYLOAD_HISTORY_SUCCESS';
export const FETCH_PAYLOAD_HISTORY_ERROR = 'FETCH_PAYLOAD_HISTORY_ERROR';
export const CLOSE_DETAILS_VIEW = 'CLOSE_DETAILS_VIEW';
export const ANALYTICS_FILTER_BY_DATE = 'ANALYTICS_FILTER_BY_DATE';
export const ANALYTICS_FILTER_BY_USER = 'ANALYTICS_FILTER_BY_USER';
export const ANALYTICS_FILTER_BY_SKILL = 'ANALYTICS_FILTER_BY_SKILL';
export const ANALYTICS_FILTER_BY_STATUS = 'ANALYTICS_FILTER_BY_STATUS';
export const ANALYTICS_FILTER_BY_TECHNICAL_USERS = 'ANALYTICS_FILTER_BY_TECHNICAL_USERS';

// BILLING

// get
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_ERROR = 'GET_INVOICES';
// create
export const REQ_SAVE_INVOICE = 'REQ_SAVE_INVOICE';
export const REQ_SAVE_INVOICE_SUCCESS = 'REQ_SAVE_INVOICE_SUCCESS';
export const REQ_SAVE_INVOICE_ERROR = 'REQ_SAVE_INVOICE_ERROR';
// update
export const REQ_UPDATE_INVOICE = 'REQ_UPDATE_INVOICE';
export const REQ_UPDATE_INVOICE_SUCCESS = 'REQ_UPDATE_INVOICE_SUCCESS';
export const REQ_UPDATE_INVOICE_ERROR = 'REQ_UPDATE_INVOICE_ERROR';
// delete
export const REQ_DELETE_INVOICE = 'REQ_DELETE_INVOICE';
export const REQ_DELETE_INVOICE_SUCCESS = 'REQ_DELETE_INVOICE_SUCCESS';
export const REQ_DELETE_INVOICE_ERROR = 'REQ_DELETE_INVOICE_ERROR';
// download invoice
export const REQ_DOWNLOAD_INVOICE = 'REQ_DOWNLOAD_INVOICE';
export const REQ_DOWNLOAD_INVOICE_SUCCESS = 'REQ_DOWNLOAD_INVOICE_SUCCESS';
export const REQ_DOWNLOAD_INVOICE_ERROR = 'REQ_DOWNLOAD_INVOICE_ERROR';

// filtersData
export const FILTERS_REQ_USERS = 'FILTERS_REQ_USERS';
export const FILTERS_REQ_USERS_ERROR = 'FILTERS_REQ_USERS_ERROR';
export const FILTERS_REQ_USERS_SUCCESS = 'FILTERS_REQ_USERS_SUCCESS';
export const FILTERS_REQ_SKILLS = 'FILTERS_REQ_SKILLS';
export const FILTERS_REQ_SKILLS_ERROR = 'FILTERS_REQ_SKILLS_ERROR';
export const FILTERS_REQ_SKILLS_SUCCESS = 'FILTERS_REQ_SKILLS_SUCCESS';