import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserTable from './UserTable';
import UserFilter from './UserFilter';
import PulseLoader from '../PulseLoader/index';
import PageHead from '../PageHead';

const UsersList = ({isFetching}) =>(
    <div style={{minHeight: '99vh'}}>
        <PageHead title={'User management'} />
        <div className="pa4 pb5-l">
            <UserFilter />
            {
                isFetching ?
                <div className="ph3">
                    <PulseLoader className="table-loader bg-navy-near-white"/>
                </div>
                : <UserTable />  
            }
        </div>
    </div>
);

UsersList.propTypes = {
    isFetching: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isFetching: state.users.fetching
});

export default withRouter(connect(
    mapStateToProps,
    null
)(UsersList));
