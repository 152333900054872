import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { findSkill } from '../../reducers/skills';
import ScreenShotModal from './ScreenShotModal';
import PulseLoader from '../PulseLoader/index';
import Overview from './Overview';
import Configurations from './Configurations';
import { confirmDeleteSkill } from '../../actions/skills';
import { hasAccess } from '../../containers/HasRoles';


const areas = {
    'FI': 'Finance',
    'MM': 'Material Managment',
    'SD': 'Sales and Distribution',
    'CO': 'Controlling',
    'BS': 'Basic',
    'General': 'General'
}

const SmallDiv = ({text, name}) => (    
    <div className="mb0 w-third f6">
        <p className="mb1 fw5 o-60">{name}:</p>
        <p className="mt1 mb0 b lh-title">{text}</p>
    </div>
)

class SkillPage extends Component {

    constructor(){
        super();
        this.state = {
            screenShotModal: false,
            screenShotId: 0,
            activeTab: 'Overview'
        }
        this.openScreenShotModal = this.openScreenShotModal.bind(this);
        this.closeScreenShotModal = this.closeScreenShotModal.bind(this);
    }

    goBack = () => {
        this.props.history.goBack();
    }

    openScreenShotModal = (e) => {
        let val = e.target.id;
        this.setState({screenShotModal: !this.state.screenShotModal, screenShotId: val});
    }

    closeScreenShotModal = () => {
        this.setState({screenShotModal: false});
    }

    nextScreenShot = () => {
        let newVal = +this.state.screenShotId === this.props.skill.screenShots.length - 1 ? 0 : +this.state.screenShotId + 1;
            this.setState({screenShotId: newVal});
    }

    prevScreenShot = () => {
        let newVal = +this.state.screenShotId === 0 ? this.props.skill.screenShots.length - 1: +this.state.screenShotId - 1;
            this.setState({screenShotId: newVal});
    }

    setActiveTab = (i) => {
        this.setState({activeTab: i});
    }

    render() {
        const { isFetching, confirmDeleteSkill, userRoles }= this.props;
        const skill = this.props.skill || {};
        if(isFetching){
            return (
                
                <div className="pa4 bg-navy-near-white br1 br--top" style={{minHeight: '100vh'}}>
                    <div className="ph3">
                        <div className="measure-wide" style={{width: '100%', textAlign: 'center', margin: '20vh auto'}}>
                            <PulseLoader />
                        </div>
                    </div>
                </div>
            )
        }

        const url = skill.screenShots ? skill.screenShots[+this.state.screenShotId] : '';
        const { activeTab } = this.state;
        return (
            <div className="bg-white br1 br--top" style={{minHeight: '99vh'}}>
                <header className="pa4 bg-navy-near-white br1 br--top">
                    <div className="ph3">
                        <div className="measure-wide">
                            <p className="f6"><Link to="/skills" className="link f6">← Skills store</Link></p>
                            <h2 className="mb2">
                                <img className="dib v-mid mb1 mr2" src="/assets/img/16icn-user.svg" style={{width:'24px'}} alt="" />
                                {skill.name}
                            </h2>
                            <p className="mt2 mb0">{skill.description}</p>
                            <div className="mt2 flex justify-between">
                                <SmallDiv name="Area" text={areas[skill.area]} />
                                <SmallDiv name="Service provider" text={skill.serviceProvider} />
                                <SmallDiv name="Domain knowledge" text={skill.domainKnowledge} />
                            </div>
                            {/* <hr className="ba b--black-05 mt4 mb3"/> */}
                            {/* <div className="flex items-center justify-between"> */}
                            {/* <div>
                                {
                                    ['Overview', 'Configurations'].map( i => <span key={i} className={activeTab === i ? "link mr3 pointer" : "mr3 pointer"}
                                    onClick={() => this.setActiveTab(i)}>{i}</span>)
                                }
                            </div> */}
                            {/* <span className="dib btn btn-blue pv1 ph2 f5">Install skill</span> */}
                        {/* </div> */}
                        </div>
                    </div>
                </header>
                <main className="pa4 pb5-l">
                    {
                        activeTab === 'Overview' ? 
                                <Overview skill={skill} openScreenShotModal={this.openScreenShotModal} />
                                :   activeTab === 'Configurations' ? 
                                    <Configurations /> 
                                    : null
                    }
                    {
                        hasAccess(['SuperAdmin'], userRoles, () => 
                            <div className="measure-wide">
                                <div className="flex justify-between items-center mt3">
                                    <div className="ph3 mt4">
                                        <Link className="btn btn-blue pv1 ph2 f5" to={`/skills/management/${skill._id}`} >Edit skill</Link>
                                    </div>
                                    <div className="ph3 mt4">
                                        <span style={{cursor: 'pointer'}} onClick={() => confirmDeleteSkill(skill)} className="link link-red">Delete skill</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.screenShotModal ? <ScreenShotModal closeScreenShotModal={this.closeScreenShotModal}
                            url={url}
                            i={+this.state.screenShotId + 1}
                            length={this.props.skill.screenShots.length}
                            nextScreenShot={this.nextScreenShot}
                            prevScreenShot={this.prevScreenShot}/>
                        : null
                    }
                </main>
            </div>
        );
    }

}

const mapStateToProps = (state, {match: { params }}) => ({
    skill: findSkill(state.skills.data, params.id),
    isFetching: state.skills.fetching,
    userRoles: state.auth.user.roles
})

export default connect(mapStateToProps, { confirmDeleteSkill })(SkillPage);