import React from 'react'

const EmptyTable = ({span}) => {
    return (
        <tr>
            <td colSpan={span}>
                <div className="pv5 tc">
                    <img src="/assets/img/visuals-empty-integrations.png" style={{maxHeight:'64px'}} alt="" />
                    <h3 className="mt0 mv2">You don't have any invoices yet</h3>
                </div>
            </td>
        </tr>
    )
}

export default EmptyTable
