const defValRules = [{
    whitespace: true, message: 'The field can not be empty!'
}, {
    required: true, message: 'The field is required!'
}]

export const profileObject = [{
    key: 'firstName',
    name: 'First Name',
    required: true,
    validationRules: defValRules
}, {
    key: 'lastName',
    name: 'Last Name',
    required: true,
    validationRules: defValRules
}, {
    key: 'email',
    name: 'E-mail address',
    required: true,
    validationRules: [...defValRules, {
        type: 'email', message: 'The input is not a valid email address!'
    }]
}, {
    key: 'company',
    name: 'Company Name',
    required: true,
    validationRules: defValRules
}, {
    key: 'sapId',
    name: 'SAP Login ID',
    required: true,
    validationRules: []
}];

export const companyObject = [{
    key: 'companyName',
    name: 'Comany Name',
    type: 'text',
    required: true,
    validationRules: defValRules
},
//  {
//     key: 'logo',
//     name: 'Company Logo',
//     type: 'file',
//     required: true,
// }, 
{
    key: 'vatNumber',
    name: 'Company VAT Nr.',
    type: 'text',
    required: true,
    validationRules: defValRules
}, {
    key: 'country',
    name: 'Country',
    type: 'text',
    required: true,
    validationRules: defValRules
}, {
    key: 'address_1',
    name: 'Address line 1',
    type: 'text',
    required: true,
    validationRules: defValRules
}, {
    key: 'address_2',
    name: 'Address line 2',
    type: 'text',
    required: false,
    validationRules: []
}, {
    key: 'region',
    name: 'State/Province/Region',
    type: 'text',
    required: false,
    validationRules: []
}, {
    key: 'city',
    name: 'City',
    type: 'text',
    required: true,
    validationRules: defValRules
}, {
    key: 'zip',
    name: 'ZIP/Postal Code',
    type: 'text',
    required: true,
    validationRules: defValRules
}];

export const contactPersons = [{
    key: 'primaryContact',
    name: 'Primary Contact Person',
    type: 'select',
    // options: [{
    //     name: 'Andrii Rudchuk',
    //     email: 'rudchuk@hala.ai',
    //     disabled: false
    // }, {
    //     name: 'Mykyta Bezhenov',
    //     email: 'mykyta.bezhenov@hala.ai',
    //     disabled: false
    // }, {
    //     name: 'Petar Petrov',
    //     email: 'petar@hala.ai',
    //     disabled: false
    // }],
    required: true
}, {
    key: 'secondaryContact',
    name: 'Secondary Contact Person',
    // options: [{
    //     name: 'Andrii Rudchuk',
    //     email: 'rudchuk@hala.ai',
    //     disabled: false
    // }, {
    //     name: 'Mykyta Bezhenov',
    //     email: 'mykyta.bezhenov@hala.ai',
    //     disabled: false
    // }, {
    //     name: 'Petar Petrov',
    //     email: 'petar@hala.ai',
    //     disabled: false
    // }],
    type: 'select',
    required: true
}];