import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RefreshContent from '../RefreshContent';
import Table from './Table';
import DetailedView from './DetailedView';
import { getAnalLogs } from '../../actions/analytics';
import Pagination from './Pagination';
import PageHead from '../PageHead';
import AnalyticsDateRange from './AnalyticsDateRange';
import SkillsFilter from './SkillsFilter';
import UsersFilter from './UsersFilter';
import StatusFilter from './StatusFilter';
import './Logs.sass';

function Logs({
    isFetching,
    lastUpdated,
    showDetailsView,
    from,
    to,
    users,
    status,
    skills,
    page,
    recordsPerPage,
    getAnalLogs,
    history,
    technicalUsers
}) {
    const [refresh, setRefresh] = useState(false);
    const goBack = useCallback(() => history.goBack(), [history]);

    useEffect(() => {
        getAnalLogs({
            from,
            to,
            users,
            skills,
            status,
            page,
            recordsPerPage,
            technicalUsers
        });
    }, [
        refresh, from, to, page, skills,
        recordsPerPage, users, status, technicalUsers
    ]);

    return (
        <div className="analytics-logs">
            <PageHead 
                title="Analytics"
                goBack={goBack}
            >
                <RefreshContent 
                    action={() => setRefresh(!refresh)}
                    lastUpdated={lastUpdated}
                    fetching={isFetching} 
                />
                <AnalyticsDateRange/>
                <SkillsFilter/>
                <UsersFilter/>
                <StatusFilter/>
            </PageHead>
            <div className="pa4 pb5-l flex flex-column flex-auto ">
                <div className="ph3 flex flex-column flex-auto">
                    {/* <Filter /> */}
                    <Table />
                    <Pagination />
                </div>
            </div>
            { showDetailsView ? <DetailedView /> : null}
        </div>
    );
}

Logs.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    lastUpdated: PropTypes.string.isRequired,
    showDetailsView: PropTypes.bool.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    status: PropTypes.array.isRequired,
    skills: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    recordsPerPage: PropTypes.number.isRequired,
    getAnalLogs: PropTypes.func.isRequired,
    technicalUsers: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        users: state.analytics.filters.users,
        status: state.analytics.filters.status,
        skills: state.analytics.filters.skills,
        technicalUsers: state.analytics.filters.technicalUsers,
        from: state.analytics.filters.from,
        to: state.analytics.filters.to,
        isFetching: state.analytics.logs.isFetching,
        lastUpdated: state.analytics.logs.lastUpdated,
        showDetailsView: state.analytics.logs.detailsView.show,
        page: state.analytics.logs.pagination.currentPage,
        recordsPerPage: state.analytics.logs.pagination.recordsPerPage
    }),{
        getAnalLogs
    }
)(Logs);