export const formFields = [{
    key: 'firstName',
    name: 'First Name',
    validationRules: [{
        whitespace: true, message: 'The First Name can not be empty!'
    }, {
        required: true, message: 'The First Name field is required!'
    }]
}, {
    key: 'lastName',
    name: 'Last Name',
    validationRules: [{
        whitespace: true, message: 'The Last Name can not be empty!'
    }, {
        required: true, message: 'The Last Name field is required!'
    }]
}, {
    key: 'email',
    name: 'E-mail address',
    validationRules: [{
        type: 'email', message: 'The input is not a valid email address!'
    }, {
        whitespace: true, message: 'The Email can not be empty!'
    }, {
        required: true, message: 'The Email field is required!'
    }]
}, {
    key: 'company',
    name: 'Company Name',
    validationRules: [{
        whitespace: true, message: 'The Company can not be empty!'
    }, {
        required: true, message: 'The Company field is required!'
    }]
}, {
    key: 'sapId',
    name: 'SAP Login ID',
    validationRules: [{
        whitespace: true, message: 'The SAP Login ID can not be empty!'
    }, {
        required: true, message: 'SAP Login ID field is required!'
    }]
}];