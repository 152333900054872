import { combineReducers } from 'redux';
import support from './support';
import * as c from '../../constants';
import skills from './skills';
import billing from './billing';

const info = (state = {}, {type, data})  => {
    switch (type) {
        case c.REQ_COMPANY_INFO_SUCCESS:
            return {...state, ...data.info};
        case c.UPDATE_COMPANY_INFO_SUCCESS:
            return {...state, ...data};
        default:
            return state;
    }
}

const updates = (state = {
    announcements: false,
    maintenance: true,
    newSkills: false
}, { type, data}) => {
    switch (type) {
        case c.REQ_COMPANY_INFO_SUCCESS:
            return {...state, ...data.updates};
        case c.SAVE_NOTIFICATION_SETTINGS_SUCCESS:
            return {...state, ...data.updates};
        default:
            return state;
    }
}

const limits = (state = {
    accountLimit: false,
    budgetWarning: false,
    spendingDigest: false
}, { type, data}) => {
    switch (type) {
        case c.REQ_COMPANY_INFO_SUCCESS:
            return {...state, ...data.limits};
        case c.SAVE_NOTIFICATION_SETTINGS_SUCCESS:
            return {...state, ...data.limits};
        default:
            return state;
    }
}

const companyContactOptions = (state =[], {type, data}) => {
    switch(type){
        case c.COMPANY_CONTACTS_OPTIONS:
            return [...data];
        default:
            return state;
    }
}

const details = (state = {
    currency: 'EUR',
    monthly: 0,
    lastUpdated: ''
}, {type, data, date}) => {
    switch(type){
        case c.REQ_COMPANY_INFO_SUCCESS:
            return {...state, ...data.details, lastUpdated: date};
        default:
            return state;
    }
}

const fetching = (state = {
    fetching: false,
    updating: false,
}, {type}) => {
    switch(type){
        case c.REQ_COMPANY_INFO:
            return {...state, fetching: true};
        case c.REQ_COMPANY_INFO_SUCCESS:
        case c.REQ_COMPANY_INFO_ERROR:
        return {...state, fetching: false};
        case c.SAVE_NOTIFICATION_SETTINGS:
        case c.UPDATE_COMPANY_INFO:
            return {...state, updating: true};
        case c.SAVE_NOTIFICATION_SETTINGS_SUCCESS:
        case c.UPDATE_COMPANY_INFO_SUCCESS:
        case c.UPDATE_COMPANY_INFO_ERROR:
        case c.SAVE_NOTIFICATION_SETTINGS_ERROR:
        return {...state, updating: false};
        default:
            return state;
    }
}

export default combineReducers({ info, companyContactOptions, updates, limits, support, skills, billing, details, fetching });