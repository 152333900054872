import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SignedOut } from '../../actions/auth';
import { SideNavLink, ProfileNavLink } from './SideNavLinks';
import { profileNav, sideNav_1 } from './routes';
import './navigation.sass';
import { hasAccess } from '../../containers/HasRoles';

class SideNavigation extends Component {
    
    state= {
        profileMenu: false,
        integrationsMenu: false,
        skillsMenu: false
    }

    toggleProfileMenu = () => {
        this.setState({profileMenu: !this.state.profileMenu});
    }

    componentWillReceiveProps(nextProps){
        let pathnameArr = nextProps.location.pathname.split('/');
        if(pathnameArr[1] !== 'connections'){
            this.setState({integrationsMenu: false});
        } else{
            this.setState({integrationsMenu: true});
        }
        if(pathnameArr[1] !== 'skills'){
            this.setState({skillsMenu: false});
        } else{
            this.setState({skillsMenu: true});
        }

    }

    render(){            
        const { profileMenu, integrationsMenu, skillsMenu } = this.state;
        const { SignedOut, user } = this.props;
        const userName = user.firstName + ' ' + user.lastName;
        const expand = '/assets/img/16icn-expand-white.svg';
        const colapse = '/assets/img/16icn-collapse-white.svg';
        return(
                <div className="h-100 pr1 pb2 flex-none fixed overflow-y-auto" style={{width:'240px'}}>
                    <div className="h-100">
                        <div className="h-100 flex flex-column relative">
                            <div className="h-100">
                                <div className="bg-blue-purple br1 aa profile-settings">
                                    <div className="flex justify-between items-center db white pa2">
                                        <p className="dib ph1 ma0">{userName}</p>
                                        <img className="o-60 hover-o-100 pointer"
                                            src={profileMenu ? "/assets/img/24icn-close-white.svg" : "/assets/img/24icn-hamburger-white.svg"}
                                            onClick={this.toggleProfileMenu}
                                            alt="" />
                                    </div>
                                    <div className={profileMenu ? "settings" : "hide-settings"}>
                                        
                                        <ul className="list pa1 ma0 bt b--black-10">
                                        {
                                            profileNav.map(l => hasAccess(l.roles, user.roles, () => <ProfileNavLink key={l.link} l={l} toggleProfileMenu={this.toggleProfileMenu} />))
                                        }
                                        </ul>
                                        <ul className="list pa1 ma0 bt b--black-10">
                                            <li><span className="db link-menu white o-60 hover-o-100 pa2 br2"  onClick={SignedOut}>Log out</span></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Navigation menu */}
                                <ul className="list pa0 mt2 aa" id="side-nav-list">
                                    {
                                        sideNav_1.map((l,n) => {
                                            return hasAccess(l.roles, user.roles, () => {
                                                if (l.items && l.items.length) {
                                                    let _show = (() => {
                                                        if (l.to === '/connections' && integrationsMenu) {
                                                            return  true;
                                                        } else if (l.to === '/skills' && skillsMenu) {
                                                            return  true;
                                                        }
                                                        return  false;
                                                    })();
                                                    return (
                                                        <SideNavLink key={`nMen-${n}`} l={{
                                                            ...l,
                                                            img_2: _show ? colapse : expand
                                                        }}>
                                                            <ul className="list pa0 ml2 mv2" style={{display: _show ? 'block' : 'none'}}>
                                                                {
                                                                    l.items.map((l,k) => {
                                                                        return hasAccess(l.roles, user.roles, () => <SideNavLink key={`subMen-${k}`} l={l}/>);
                                                                    })
                                                                }
                                                            </ul>
                                                        </SideNavLink>
                                                    )
                                                }
                                                return (<SideNavLink key={`nMen-${n}`} l={l}/>);
                                            })
                                        })
                                    }
                                </ul>
                            </div>
                            {/* <!-- Sidebar footnotes --> */}
                            <div className="flex-none white aa">
                                <div className="bt b--white-10 pa2">
                                    <p className="o-40 f6 fw5 mv2 lh-solid">Built and supported by Hala.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default withRouter(connect(mapStateToProps, {
  SignedOut,
})(SideNavigation));

SideNavigation.propTypes = {
    SignedOut: PropTypes.func.isRequired,
    user: PropTypes.shape({
      roles: PropTypes.array.isRequired,
      company: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.string,
    }).isRequired,
}