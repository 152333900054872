import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FilterSection from './FilterSection';
import ConnectionsTable from './ConnectionsTable';
import PulseLoader from '../PulseLoader';
import Amplify from '@aws-amplify/core';
import PageHead from '../PageHead';

const log = new Amplify.Logger('Connections::');

const Connections = ({isFetching}) => {
    log.debug('render');
    return (
        <div style={{minHeight: '99vh'}}>
            <PageHead title="Integrations" />
            <div className="pa4 pb5-l">
                <div className="ph3 flex justify-between flex-wrap mb2">
                    <FilterSection />
                    {/* <div className="mb2">
                        <Link 
                            style={{display: 'inline-block'}}
                            className="btn btn-blue pv1 ph2 f5" 
                            to="/connections/catalogue"    
                        >Add integration</Link>
                    </div> */}
                </div>
                {
                    isFetching
                    ? <div className="ph3"><PulseLoader className="table-loader bg-navy-near-white"/></div>
                    : <ConnectionsTable/>
                }
            </div>
        </div>
    );
}

Connections.propTypes = {
    isFetching: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isFetching: state.integrations.fetching,
})

export default connect(
    mapStateToProps, 
    null
)(Connections);