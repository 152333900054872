import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { sortBy } from '../../../reducers/settings/skills';
import SkillRow from './SkillRow';
import SkillCol from './SkillCol';
import ToggleTableRow from './ToggleTableRow';
import { skillColumns } from './objectContainer';
import { roundPrice } from '../../../utils';

class SkillsTable extends Component {

    state = {
        totalRequests: 0,
        totalCost: 0,
        showMenu: false,
        activeFilter: '',
        remainingSkills: 0,
        remainingTotalRequests: 0,
        remainingTotalCost: 0,
        table: [],
        visibleRows: 10,
        optimalRows: 10
    };

    componentDidMount(){
        this.calculateTotalRequests();
    }
    
    componentDidUpdate(prevProps, prevState){
        if(this.props.sortedSkills.length !== prevProps.sortedSkills.length ||
             this.state.visibleRows !== prevState.visibleRows
             || this.props.match.params !== prevProps.match.params){
            this.calculateTotalRequests();
        }
    }

    calculateTotalRequests = () => {
        let totalRequests = 0,
            totalCost = 0,
            remainingSkills = 0,
            remainingTotalRequests = 0,
            remainingTotalCost = 0,
            table = [];
        const { sortedSkills } = this.props;
        const { visibleRows } = this.state;
        if(sortedSkills.length){
            sortedSkills.map( (skill, i) => {
                totalRequests += skill.requests;
                totalCost += skill.total;
                if(i < visibleRows){
                    table.push(<SkillRow key={skill._id} skill={skill}/>);
                } else{
                    remainingSkills ++;
                    remainingTotalRequests += skill.requests;
                    remainingTotalCost += skill.total;
                }
                return false;
            });
        }
        this.setState({totalRequests, totalCost, table, remainingSkills, remainingTotalRequests, remainingTotalCost});
    }

    filterValue = (e) => {
        const { match: { params } } = this.props;
        const value = e.target.id;
        if(params.sortFilter === value) {
            if(params.sortOption === 'ascending'){
                this.props.history.push("/settings/usage/" + value + '/descending');
            } else{
                this.props.history.push("/settings/usage/" + value + '/ascending');
            }
        } else {
            this.props.history.push("/settings/usage/" + value + '/ascending');
        }
    }

    toggleTableRows = () => {
        const { optimalRows, visibleRows } = this.state;
        const { sortedSkills } = this.props;
        const newValue = visibleRows < sortedSkills.length ? sortedSkills.length : optimalRows;
        this.setState({visibleRows: newValue});
    }

    render() {
        const { limit, match: { params }, sortedSkills } = this.props;
        const { totalRequests, totalCost, table, remainingSkills, remainingTotalRequests, remainingTotalCost } = this.state;
        return (
            <table className="table w-100 tl">
                <thead>
                    <tr className="bg-navy-near-white">
                    {
                        skillColumns.map( col => <SkillCol key={col.id} {...col} params={params} handleClick={this.filterValue} />)
                    }
                    {/* <th style={{width:'40px'}}></th> */}
                    </tr>
                </thead>
            <tbody>
                {table}
                {
                    sortedSkills.length > 10 ?
                    <ToggleTableRow 
                toggleTableRows={this.toggleTableRows}
                totalSkills={sortedSkills.length}
                remainingSkills={remainingSkills} 
                remainingTotalRequests={remainingTotalRequests} 
                remainingTotalCost={remainingTotalCost} />
                : null
                }
                <SkillRow 
                total={roundPrice(totalCost)}
                totalRequests={totalRequests}
                totalLimit={limit}
                  />
            </tbody>
    </table>
        )
    }
}

const mapStateToProps = (state, { match: { params } }) => ({
    sortedSkills: state.settings.skills.data,//sortBy(state.settings.skills.data, params),
    //total: state.settings.skills.total,
    limit: state.settings.skills.limit,
})

const mapDispatchToProps = {
  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SkillsTable))

SkillsTable.propTypes = {
    sortedSkills: PropTypes.array.isRequired,
    //total: PropTypes.number.isRequired,
    // limit: PropTypes.number.isRequired,
    params: PropTypes.object,
}
