import {
    SIGNED_OUT,
    USER_INFO_SUCCESS,
    AUTH_ERROR,
    AUTH_LOADING
} from '../constants';
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { Hub } from '@aws-amplify/core';
import { getUserRoles, getUserSapId } from '../services/api';

const logger = new Amplify.Logger('Auth');

export const getUserInfo = () => (
    async dispatch => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const payload = {
                id: user.username,
                company: user.attributes['custom:company'],
                firstName: user.attributes['name'],
                lastName: user.attributes['family_name'],
                email: user.attributes['email'],
                roles: await getUserRoles(),
                sapId: await getUserSapId()
            }
            dispatch({ type: USER_INFO_SUCCESS, payload });
        } catch (err) {
            logger.error(err);
        }
    }
)

export const authError = (data) => ({
    type: AUTH_ERROR,
    data
})

export const authLoading = (payload) => (
    dispatch => {
        dispatch({
            type: AUTH_LOADING,
            payload
        })
    }
)

export const authStatusChanged = (type, payload) => ({
    type,
    payload
});
    
    // dispatch => {
    //     if (type === SIGNED_OUT) {
    //         sessionStorage.clear();
    //         localStorage.clear();
    //         Auth.signOut();
    //         type = SIGN_IN;
    //     }
    //     if (type === SIGNED_UP) {
    //         Hub.dispatch('auth', { 
    //             event: 'signedUp'
    //         });
    //     }
    //     sessionStorage.setItem('authState', type);
    //     dispatch({ type, payload });
    // }


export const signOut = () => {
    Auth.signOut()
    .then(() => Hub.dispatch(SIGNED_OUT));
    return {
        type: SIGNED_OUT
    }
};
export const SignedOut = signOut;