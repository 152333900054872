import React, { Component } from 'react';
import Message from './Message/Message';

class ConversationScript extends Component {

    renderMessages = () => {
        const { history, record } = this.props;
        console.log('record be like',record);
        if(history && history.length > 0){
            return history.map( (message, i) => {
                return (<Message 
                    key={message.id + '-' + i}
                    userName={`${record.user.firstName} ${record.user.firstName}`}
                    message={message} />)
            })
        }
        return null;
    }

    render() {
        return (
            <div>
                { this.renderMessages() }
            </div>
        )
    }
}

export default  ConversationScript;