import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { 
  Switch, 
  Route,
  Router
} from 'react-router-dom';
import SideNavigation from '../components/Navigation';
import ConfirmDelete from '../components/ConfirmDelete/ConfirmDelete';
import RedirectWarning from '../components/RedirectWarning';
import UserManagment from './UserManagment';
import Settings from './Settings';
import ConnectionsRoot from './ConnectionsRoot';
import Skills from './Skills';
import AnalyticsRoot from './AnalyticsRoot';
import DashboardOld from '../components/Dashboard';
import Documentation from '../components/Documentation';
// import Dashboard from '../components/HalaApp';
import Contacts from '../components/Contacts';
import Legalinfo from '../components/Legalinfo';
import NotFound from '../components/NotFound';
import Amplify from '@aws-amplify/core';
import { getUserInfo } from '../actions/auth';
import history from '../history';
import PulseLoader from '../components/PulseLoader';
import { hasAccess } from './HasRoles';
const log = new Amplify.Logger('App Container');

class App extends Component {

  componentDidMount() {
    this.props.getUserInfo();
  }

  render() {
    log.debug('rerendered!!!');

    if (!this.props.userEmail) {
      return <PulseLoader className="page-loader"/>
    }

    const { userRoles } = this.props;

    return (
      <Router history={history}>
        <div className="page-wrap sans-serif bg-navy navy" style={{minWidth:'768px', minHeight: '100vh'}}>
            <div className="h-100 flex mw9 center pt2 ph2">
                <ConfirmDelete />
                <RedirectWarning />
                <SideNavigation />
                <div className="w-100 pl1" style={{maxHeight:'100%', marginLeft:'240px'}}>
                  <div className="bg-white br1 br--top" style={{minHeight:'100%'}}>
                  <Switch>
                    <Route path="/users" component={ hasAccess(['SuperAdmin'], userRoles, () => UserManagment) } />
                    <Route path="/settings" component={ Settings } />
                    <Route path="/documentation" component={ Documentation } />
                    <Route path="/connections" component={ hasAccess(['SuperAdmin'], userRoles, () => ConnectionsRoot) } />
                    <Route path="/integrations" component={ hasAccess(['SuperAdmin'], userRoles, () => ConnectionsRoot) } />
                    <Route path="/skills" component={ hasAccess(['SuperAdmin'], userRoles, () => Skills) } />
                    <Route path="/analytics" component={ AnalyticsRoot } />
                    <Route path="/contacts" component={ Contacts } />
                    <Route path="/legalInformation" component={ Legalinfo } />
                    <Route path="/dashboard-old" component={ hasAccess(['SuperAdmin'], userRoles, () => DashboardOld) } />
                    <Route exact path="/" component={ AnalyticsRoot } />
                    <Route component={ NotFound } />
                  </Switch>
                  </div>
                </div>
                </div>
            </div>
      </Router>  
    );
  }
}

App.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired
};


export default connect(
  state => ({
    userEmail: state.auth.user.email,
    userRoles: state.auth.user.roles
  }), {
  getUserInfo
})(App);
