import React from "react";
import PropTypes from "prop-types";

const ScreenShotModal = ({
  closeScreenShotModal,
  nextScreenShot,
  prevScreenShot,
  url,
  i,
  length
}) => {

  return (
    <div>
      <div
        className="z-max fixed w-100 vh-100 bg-navy-near-white"
        style={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <div className="vh-100 flex flex-column">
          <div className="flex justify-between items-center pa3">
            <p className="mv0">
              {/* {`screenshot-${i}.png`} */}
            </p>
            <img
              className="o-60 hover-o-100 pointer"
              onClick={() => closeScreenShotModal()}
              src="/assets/img/24icn-close.svg"
              alt=""
            />
          </div>
          <div className="flex-auto overflow-scroll">
            <div className="ph3 tc">
              <img
                className="br1"
                style={{ maxWidth: "100%", maxHeight: "calc(100vh - 116px)" }}
                src={url}
                alt=""
              />
            </div>
          </div>
          <div className="pa3 tc">
            <p className="mv0">
              <img
                className="v-btm o-60 hover-o-100 pointer"
                src="/assets/img/24icn-nav-prev.svg"
                onClick={() => prevScreenShot()}
                alt=""
              />
              {`${i} of ${length}`}
              <img
                className="v-btm o-60 hover-o-100 pointer"
                src="/assets/img/24icn-nav-next.svg"
                onClick={() => nextScreenShot()}
                alt=""
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenShotModal;

ScreenShotModal.propTypes = {
  closeScreenShotModal: PropTypes.func.isRequired,
  nextScreenShot: PropTypes.func.isRequired,
  prevScreenShot: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired
};
