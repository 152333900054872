import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterUsers } from '../../reducers/users';
import EmptyTable from './EmptyTable';
import {
    selectUser,
    deselectUser,
    selectAllUsers,
    deselectAllUsers
} from '../../actions/users';

class UserTable extends Component {
  
    //select a user row
    selectUser = e => {
        !e.target.checked ? 
        this.props.deselectUser(e.target.id) 
        : this.props.selectUser(e.target.id)
    }

    //select all the users
    selectAllUsers = e => {
        const { filteredUsers, selectAllUsers, deselectAllUsers } = this.props;
        if(!e.target.checked){
            deselectAllUsers(filteredUsers.map( user =>  user.id));
        } else{
            selectAllUsers(filteredUsers.map( user =>  user.id));
        }
    }

    highLightMatchedText = (text) => {
        const value = this.props.filter;
        let parts = text.split(new RegExp(`(${value})`, 'gi'));
        return (
            <span>
                { 
                    parts.map((part, i) => 
                        <span key={i} style={part === value ? { fontWeight: 'bold' } : {} }>
                        { part }
                        </span>
                    )
                } 
            </span>
        );
    }

    render() {
        const { selectedUsers, filteredUsers, filter } = this.props;
        const areAllSelected = filteredUsers.filter( user => selectedUsers.indexOf(user.id) > -1).length === filteredUsers.length && filteredUsers.length > 0;
        const emptyTablemMssg =  filter !== '' ? 'No users matching the current search.' : 'No users to display.';
        if(!filteredUsers.length){
            return <EmptyTable mssg={emptyTablemMssg} />
        }

        return (
            <table className="ph3 table w-100 tl">
                <tbody>
                    <tr className="bg-navy-near-white">
                        <th className="" style={{width:'32px'}}><input type="checkbox" checked={areAllSelected}  onChange={this.selectAllUsers} /></th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>E-mail</th>
                        <th>Company</th>
                        <th>Role</th>
                    </tr>
                    {
                        (
                            filteredUsers.map( (user, i) => {
                                return (
                                    <tr key={i} >
                                        <td><input id={user.id} type="checkbox" checked={selectedUsers.indexOf(user.id) > -1} onChange={this.selectUser} /></td>
                                        <td><Link to={`/users/${user.id}`} className="link">{this.highLightMatchedText(user.firstName)}</Link></td>
                                        <td>{this.highLightMatchedText(user.lastName)}</td>
                                        <td>{this.highLightMatchedText(user.email)}</td>
                                        <td>{this.highLightMatchedText(user.company)}</td>
                                        <td>{user.roles? user.roles.map( role => <span key={i} style={{paddingRight: '5px'}}>{role}</span>) : ''}</td>
                                    </tr>
                                )
                            })
                        )
                    }
                </tbody>
            </table>
        )
    }
}

const mapStateToProps = (state) => ({
    filteredUsers: filterUsers(state.users.data, state.users.filter),
    selectedUsers: state.users.selectedUsers,
    isFetching: state.users.fetching,
    filter: state.users.filter
})

const mapDispatchToProps = {
        selectUser,
        deselectUser,
        selectAllUsers,
        deselectAllUsers
    }

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);

UserTable.propTypes = {
    filteredUsers: PropTypes.array.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    filter: PropTypes.string.isRequired,
    selectUser: PropTypes.func.isRequired,
    deselectUser: PropTypes.func.isRequired,
    selectAllUsers: PropTypes.func.isRequired,
    deselectAllUsers: PropTypes.func.isRequired,
}