import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchUsers } from '../../../actions/filtersData';
import { filterByUser } from '../../../actions/analytics';

const Option = Select.Option;

function filterByInput(input, option) {
    return option.props.searchkey.indexOf(input.toLowerCase()) !== -1;
}

function convertData(data) {
    const d = {};
    data.forEach(el => {
        const val = `${el.firstName} ${el.lastName}`;
        if (!d[val]) {
            d[val] = {
                key: el.id,
                searchKey: val.toLowerCase(),
                val
            };
        } else {
            d[val].key = d[val].key + '::' + el.id;
        }
    });
    return Object.values(d);
}

function UsersFilter({ isFetching, data, fetchUsers, values, filterByUser }) {
    const ref = useRef();

    useEffect(() => {
        if (!data.length) fetchUsers();
    }, [data]);

    const onChange = useCallback(val => {
        if (isFetching) return false;
        filterByUser(val);
    }, [isFetching]);
    const _data = useMemo(() => convertData(data), [data]);
    
    return (
        <Select
            ref={ref}
            mode="multiple"
            placeholder="Filter by user name"
            value={values}
            onChange={onChange}
            filterOption={filterByInput}
        >
            {
                _data.map(el=> (
                    <Option 
                        key={el.key} 
                        searchkey={el.searchKey}
                    >{el.val}</Option>
                ))
            }
        </Select>
    )
}

UsersFilter.propTypes = {
    data: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    filterByUser: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        data: state.filtersData.users,
        values: state.analytics.filters.users,
        isFetching: state.analytics.logs.isFetching
    }), {
        fetchUsers,
        filterByUser
    }
)(UsersFilter);