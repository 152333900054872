import * as c from '../constants';
import * as api from '../services/api';
import history from '../history';

// Integration filters

export const setIntegrationSearchText = value => ({
    type: c.SET_INTEGRATION_SEARCH,
    value
})

export const setIntegrationSearchProvider = value => ({
    type: c.SET_INTEGRATION_PROVIDER,
    value
})

export const setIntegrationSearchCategory = value => ({
    type: c.SET_INTEGRATION_CATEGORY,
    value
})

export const setIntegrationSearchStatus = value => ({
    type: c.SET_INTEGRATION_STATUS,
    value
})

export const resetIntegrationFilters = () => ({
    type: c.RESET_INTEGRATION_FILTER
})

export const selectIntegrations = (data) => ({
    type: c.SELECT_INTEGRATIONS,
    data
})

// Integrations fetch

const requestIntegrations = () => ({
    type: c.REQ_INTEGRATIONS
})

const requestIntegrationsSuccess = data => ({
    type: c.REQ_INTEGRATIONS_SUCCESS,
    data
})

const requestIntegrationsError = data => ({
    type: c.REQ_INTEGRATIONS_ERROR,
    data
})

// Integration manipulatiosn

const requestUpdateIntegration = data => ({
    type: c.UPDATE_INTEGRATION,
    data
})

const updateIntegrationSuccess = data => ({
    type: c.UPDATE_INTEGRATION_SUCCESS,
    data
})

const updateIntegrationError = data => ({
    type: c.UPDATE_INTEGRATION_ERROR,
    data
})

const requestCreateIntegration = data => ({
    type: c.CREATE_INTEGRATION,
    data
})

const createIntegrationSuccess = data => ({
    type: c.CREATE_INTEGRATION_SUCCESS,
    data
})

const createIntegrationError = data => ({
    type: c.CREATE_INTEGRATION_ERROR,
    data
})

export const requestDeleteIntegration = data =>({
    type: c.DELETE_INTEGRATION,
    data
});
export const deleteIntegrationSuccess = data =>({
    type: c.DELETE_INTEGRATION_SUCCESS,
    data
});
export const deleteIntegrationError = data =>({
    type: c.DELETE_INTEGRATION_ERROR,
    data
});



// Thunk bindAction

export const saveIntegration = integration => (
    async dispatch => {
        if (integration.id) dispatch(updateIntegration(integration));
        else dispatch(createIntegration(integration));
    }
)

export const createIntegration = (integration) => (
    async dispatch => {
        dispatch(requestCreateIntegration(integration));
        try {
            integration.id = await api.createIntegration(integration);
            dispatch(createIntegrationSuccess(integration));
            history.push('/connections');
            return integration;
        } catch (err) {
            dispatch(createIntegrationError(err.message));
            return err;
        }
    }
)

export const updateIntegration = integration => (
    async dispatch => {
        dispatch(requestUpdateIntegration(integration));
        try {
            await api.updateIntegration(integration);
            dispatch(updateIntegrationSuccess(integration));
            history.push('/connections');
            return integration;
        } catch (err) {
            dispatch(updateIntegrationError(err.message));
            return err;
        }
    }
);

export const deleteIntegration = integration => (
    async dispatch => {
        dispatch(requestDeleteIntegration(integration));
        try {
            await api.deleteIntegration(integration.id);
            dispatch(deleteIntegrationSuccess(integration));
            history.push('/connections');
            return integration;
        } catch (err) {
            dispatch(deleteIntegrationError(err.message));
            return err;
        }
    }
);

export const fetchIntegrations = () => (
    async dispatch => {
        dispatch(requestIntegrations());
        try {
            let ints = await api.getIntegrations();
            dispatch(requestIntegrationsSuccess(ints));
            return ints;
        } catch (err) {
            dispatch(requestIntegrationsError(err.message))
            return err;
        }
    }
)

export const deleteConfirmation = connection => (
    async dispatch => {
        dispatch({
            type: c.RENDER_CONFIRMATION_POPUP,
            payload: {
                data: [ connection ],
                type: 'connection',
                ok: async () => {
                    return dispatch(deleteIntegration(connection));                
                }
            }
        });
    }
)