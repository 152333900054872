import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { filterSkills } from '../../reducers/skills';
import FilterSkills from './FilterSkills';
import SkillStoreBlock from './SkillStoreBlock';
import ModalComponent from '../ModalComponent/index';
import { resetFilters } from '../../actions/skills';
import Amplify from '@aws-amplify/core';
import PageHead from '../PageHead';
const log = new Amplify.Logger('Skills Store::');

class SkillsStore extends Component {

    state = {
        modal: false
    }

    toggleModalMessage = () => {
        this.setState({modal: !this.state.modal})
    }

    componentWillMount(){
        this.props.resetFilters();
    }

    render() {
        log.debug('render');
        const { filteredSkills, isSuperAdmin } = this.props;

        return (
            <div className="bg-white br1 br--top" style={{minHeight:'99vh', minWidth: '700px'}}>
                <PageHead title="Skills store"/>

                {/* <!-- Content part --> */}
                <div className="pa4 pb5-l">
                    <FilterSkills>
                        {isSuperAdmin ?
                        <div className="" >
                            <Link to="/skills/management/addNew"><button type="button" onClick={this.toggleModalMessage} className="btn btn-blue pv1 ph2 f5">Add new skill</button></Link>
                        </div>
                        : null }
                    </FilterSkills>
                    <div className="ph2 flex flex-wrap">
                        {
                            filterSkills.length > 0 ?
                                filteredSkills.map( (skill,i) => {
                                    return <SkillStoreBlock key={`${skill.id}-${i}`} skill={skill} />
                                })
                            : null
                        }
                    </div>

                </div>

                {
                    this.state.modal ? 
                    <ModalComponent>
                        <div className="mw6 center ph2 mb2 mb5-l">
                            <div className="pa3 pa4-ns pa5-l bg-white br1">
                                <h1 className="f3 f2-ns mv0">Download All Skills</h1>
                                <p className="mv3 lh-copy">This functionality will be available soon.</p>
                                <span onClick={this.toggleModalMessage} className="db tc btn btn-blue pv2 ph3 f5">Close</span>
                            </div>
                        </div>
                    </ModalComponent>
                    : null
                }             

            </div>
        );
    }
}

const mapStateToProps = state => ({
        filteredSkills: filterSkills(state.skills.data, state.skills.filters),
        isFetching: state.skills.fetching,
        isSuperAdmin: state.auth.isSuperAdmin
})

export default connect(mapStateToProps, { resetFilters })(SkillsStore);

SkillsStore.propTypes = {
    filteredSkills: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired
}
