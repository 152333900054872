import React from 'react';
import PropTypes from 'prop-types';
import KeyValView from '../KeyValView';
import './index.sass';

const CardTable = ({sourceData, legend, requiredFields = []}) => {
    const maxItems = 6;
    const dataKeys = Object.keys(sourceData);
    const fields = requiredFields.length ? requiredFields : dataKeys.length > maxItems ? dataKeys.slice(0,maxItems) : dataKeys;
    const id = Date.now();
    const hasMore = dataKeys.length >  maxItems;
    
    return (
        <div className="card-table">
            <div className={`card-table-items${hasMore?' has-more':''}`}>
                {
                    fields.map((f,k) => (
                        <KeyValView 
                            key={`${id}-${k}`}
                            label={legend[f] ? legend[f] : f}
                            value={sourceData[f] || '-'}
                        />
                    ))
                }
            </div>
        </div>
    );
};

CardTable.propTypes = {
    legend: PropTypes.object.isRequired, 
    requiredFields: PropTypes.array.isRequired,
    sourceData: PropTypes.object.isRequired,
    openDetails: PropTypes.func.isRequired
}

export default CardTable;