import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTopUsers } from '../../actions/analytics';
import PageHead from '../PageHead';
import { Skeleton } from 'antd';
import AnalyticsDateRange from './AnalyticsDateRange';

function TopUsers({ history, getTopUsers, from, to, technicalUsers}) {
    const [state, setState] = useState({
        users: [],
        isFetching: true
    });

    const goBack = useCallback(() => history.goBack(), [history]);

    useEffect(() => {
        getTopUsers({from, to, technicalUsers})
        .then(res => {
            if (res && res.length) {
                setState({
                    users: res,
                    isFetching: false
                });
            }
        });
    }, [from, to, technicalUsers]);

    return (
        <div className="bg-white br1 br--top flex flex-column" style={{minHeight:'99vh'}}>
            <PageHead 
                title="Top Users"
                goBack={goBack}
            >
                <AnalyticsDateRange/>
            </PageHead>
            <div className="pa4 pb5-l flex flex-column flex-auto ">
                <div className="ph3 flex flex-column flex-auto">
                    <Skeleton loading={state.isFetching} active title={false}>
                        <table className="table w-100 tl">
                            <thead>
                                <tr className="bg-navy-near-white">
                                    <th>User name</th>
                                    <th>Total messages</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.users.map((record,i) => (
                                        <tr key={i}>
                                            <td>{record.firstName} {record.lastName}</td>
                                            <td>{record.total}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Skeleton>
                </div>
            </div>
        </div>
    );
}

TopUsers.propTypes = {
    getTopUsers: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    technicalUsers: PropTypes.bool.isRequired
}

export default connect(
    state => ({
        from: state.analytics.filters.from,
        to: state.analytics.filters.to,
        technicalUsers: state.analytics.filters.technicalUsers
    }),
    { getTopUsers }
)(TopUsers);