import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'antd';
import PageHead from '../../PageHead';
import TextInput from './TextInput';
import FileInput from './FileInput';
import SelectInput from './SelectInput';
import { 
    updateCompanyInfo,
    getCompanyContactsOptions,
    fetchCompanyInfo
 } from '../../../actions/settings';
import { companyObject, contactPersons } from './objectContainer';
import SubmitButton from '../../SubmitButton';
import './company.sass';

class Company extends Component {

    state = {
        selectedFile: null
    }

    resetForm = () => {
        this.props.fetchCompanyInfo();
    }

    handleselectedFile = (e) => {
        this.setState({
            selectedFile: e.target.files[0]
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll }, updateCompanyInfo } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                //values.logo = this.state.selectedFile;
                console.log('profile updated', values)
                for(let prop in values){
                   values[prop] = values[prop] === '' ? null : values[prop];
                }
                updateCompanyInfo(values);
            } else{
                values.logo = this.state.selectedFile;
            }
        });
    }

    errorMessage = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors){
            if(errors.length > 0) {
                return (
                    <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
                )
            } else {
                return null;
            }
        }
    }

    render() {
        const { form: { getFieldDecorator }, getCompanyContactsOptions, companyContactOptions, updating } = this.props;
        const fileName = this.state.selectedFile ? this.state.selectedFile.name : null;
        const company = this.props.company || {};
        return (
            <div className="bg-white br1 br--top" style={{minHeight:'99vh'}}>
                <PageHead title={'Company'} />
                <div className="pa4 pb5-l">
                    <div className="ph3">
                        <div className="measure-wide">
                            <h2 className="mt0 mb2">Company information</h2>
                            <p className="mt2">Following information will be used for billing and representing your organisation within the Hala system.</p>
                            <Form onSubmit={this.handleSubmit}
                                    className="mt3 company-form">
                                { 
                                    companyObject.map( item => 
                                        item.type === 'text' ?
                                        <TextInput key={item.key}
                                        item={item} object={company} getFieldDecorator={getFieldDecorator} errorMessage={this.errorMessage} />
                                        : 
                                        <FileInput key={item.key}
                                        item={item}
                                        fileName={fileName}
                                        handleselectedFile={this.handleselectedFile} />
                                    )
                                }
                                <hr className="ba b--black-05 mv4" />
                                <h2 className="mt0 mb2">Contact persons</h2>
                                <p className="mt2">People from organisation that should be contacted in case of any questions.</p>
                                {
                                    contactPersons.map( item => <SelectInput key={item.key} 
                                        item={item}
                                        getFieldDecorator={getFieldDecorator}
                                        getCompanyContactsOptions={getCompanyContactsOptions}
                                        options={companyContactOptions}
                                        object={company} />)
                                }
                                <div className="mt3">
                                    <SubmitButton  value={'Save changes'} loader={updating} />
                                    <input type="button" value="Cancel" onClick={this.resetForm} className="dib btn btn-ghost pv2 ph3 f5" />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    company: state.settings.info,
    updating: state.settings.fetching.updating,
    companyContactOptions: state.settings.companyContactOptions
})

const mapDispatchToProps = {
    updateCompanyInfo,
    fetchCompanyInfo,
    getCompanyContactsOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Company));

Company.propTypes = {
    company: PropTypes.object.isRequired,
    companyContactOptions: PropTypes.array.isRequired,
}