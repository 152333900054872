import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RefreshContent from '../RefreshContent';
import SmallBlock from './SmallBlock';
import TopTable from './TopTable';
import { getAnalStats } from '../../actions/analytics';
import { stats } from './objectContainer';
import PageHead from '../PageHead';
import AnalyticsDateRange from './AnalyticsDateRange';

function Analytics({
    getAnalStats,
    stats: { data, isFetching, lastUpdated },
    from, to, technicalUsers
}) {

    useEffect(() => {
        getAnalStats({from, to, technicalUsers});
    }, [from, to, technicalUsers]);

    return (
        <div className="bg-white br1 br--top" style={{minHeight:'99vh'}}>
            <PageHead title="Dashboard">
                <RefreshContent action={() => getAnalStats({from, to, technicalUsers})} fetching={isFetching} lastUpdated={lastUpdated} />
                <AnalyticsDateRange/>
            </PageHead>
            <div className="pa4">
                    <div className="flex flex-wrap ph2">
                    {
                        stats.map( s => <SmallBlock key={s.key} name={s.name} value={data[s.key]} fetching={isFetching} />)
                    }
                    </div>
                    <div className="mt3 flex flex-wrap ph2">
                        <TopTable 
                        title={'Top 5 Skills'}
                        description={'Skill name'}
                        totalDescription={'Total requests'}
                        renderName={d => d._id}
                        data={data.topSkills}
                        fetching={isFetching} 
                        details="/analytics/logs"
                        />
                        <TopTable 
                        title={'Top 5 Unique Users'}
                        description={'User name'}
                        renderName={d => d.firstName + ' ' + d.lastName}
                        totalDescription={'Total messages'}
                        data={data.topUsers}
                        fetching={isFetching} 
                        details="/analytics/top-users"
                        />
                    </div>
                    <div className="ph3 pt4">
                        <div className="measure-wide">
                            <h2 className="mt0 mb2">Logs data</h2>
                            <p className="mt2">See complete list of all logs with request details.</p>
                            <p><Link to="/analytics/logs" className="link">Open log list</Link></p>
                        </div>
                    </div>
            </div>
        </div>
    );
}

Analytics.propTypes = {
    stats: PropTypes.shape({
        data: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        lastUpdated: PropTypes.string.isRequired,
    }).isRequired,
    getAnalStats: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    technicalUsers: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        stats: state.analytics.stats,
        from: state.analytics.filters.from,
        to: state.analytics.filters.to,
        technicalUsers: state.analytics.filters.technicalUsers
    }), 
    {
        getAnalStats
    }
)(Analytics);