import React from 'react';
import PropTypes from 'prop-types';

const defaultDiscription = (title) =>  (<span>{title} deletion is a permanent action and there is no way back, please enter <strong>DELETE</strong> to confirm deletion</span>);
const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

const Description = ({type}) => {

    const title = capitalizeFirstLetter(type);

    return (
        <p  className="mt2 mb0 lh-copy">{defaultDiscription(title)}:</p>
    );
};

Description.propTypes = {
    type: PropTypes.string.isRequired,
};

export default Description;