import React from 'react';
import PropTypes from 'prop-types';
import { roundPrice } from '../../../utils';

const ToggleTableRow = ({totalSkills, remainingTotalRequests, remainingSkills, remainingTotalCost, toggleTableRows}) => {
    const isExpanded = remainingSkills === 0;
    return(
        <tr style={{height:'43px'}}>
            <td colSpan="2" onClick={toggleTableRows}>
                    <span className="o-60 hover-o-100 pointer flex items-center">
                        <img className="mr1" src={`/assets/img/16icn-${isExpanded ? 'collapse' : 'expand' }.svg`} 
                        alt={isExpanded ? "collapse": "expand"} />
                        {isExpanded ? 'Collapse Table' : 'Show all ( ' + totalSkills + ') Skills'}
                    </span>
            </td>
            <td className="o-60 tr">{remainingTotalRequests ? remainingTotalRequests : null}</td>
            <td className="o-60 tr">{remainingTotalCost ? '€' + roundPrice(remainingTotalCost) : null}</td>
            {/* <td colSpan="2"></td> */}
        </tr>
    );
}

export default ToggleTableRow;

ToggleTableRow.propTypes = {
    totalSkills: PropTypes.number.isRequired,
    remainingSkills: PropTypes.number.isRequired,
    remainingTotalRequests: PropTypes.number.isRequired,
    remainingTotalCost: PropTypes.number.isRequired,
    toggleTableRows: PropTypes.func.isRequired,
}