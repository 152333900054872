import React from 'react';
import App from './App';
import { SIGNED_OUT } from '../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Hub } from '@aws-amplify/core';
import { authStatusChanged } from '../actions/auth';
import SignIn from '../components/Auth/SignIn';
import ForgotPassword from '../components/Auth/ForgotPassword';
import RequireNewPassword from '../components/Auth/RequireNewPassword';
import VerifyContact from '../components/Auth/VerifyContact';
import SignUp from '../components/Auth/SignUp';
import ConfirmSignUp from '../components/Auth/ConfirmSignUp';
import Notifications from '../components/Notifications';
import { authError } from '../actions/auth';
import {
    ConfirmSignIn,
    TOTPSetup,
    withAuthenticator
} from 'aws-amplify-react';

const Authenticator = withAuthenticator(App, false, [
    <SignUp/>,
    <SignIn/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <RequireNewPassword/>,
    <ConfirmSignUp/>,
    <ForgotPassword/>,
    <TOTPSetup/>
]);

class AppContainer extends Authenticator {

    componentDidMount() {
        Hub.listen(SIGNED_OUT, {
            onHubCapsule: () => {
                this.handleAuthStateChange(SIGNED_OUT);
            }
        });
    }

    handleAuthStateChange(state, data) {
        super.handleAuthStateChange(state, data);
        this.props.actions.authStatusChanged(state, data);
    }

    render() {
        const App = super.render();
        return (
            <React.Fragment>
                <Notifications/>
                { App }
            </React.Fragment>
        )
    }
}

AppContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  errorMessage: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
      authStatusChanged
  }, dispatch),
  errorMessage: (err => {
    dispatch(authError(err))
    return null;
  })
})

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppContainer);