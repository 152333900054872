import React from 'react';
import './index.sass';

const ModalComponent = ({children}) => {
    return (
        <div  className="z-max fixed w-100 vh-100 bg-black-90 modal-container" >
            <div  className="mw6 center pv3 pv4 ph2 mb2 mb5-l middle" >
                <div  className="pa3 pa4-ns pa5-l br1 ba b--black-10">
                    <div className="mw6 center pv3 pv4-ns">
                        <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                            <img className="" src="/assets/img/hala-logo.svg" alt="" />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalComponent;