import {
    REDIRECT,
    RESET_REDIRECT
} from '../constants';

const initialState = {
    open: false,
    link: ''
};

const redirect = (state = initialState, {type, data}) => {
    switch(type){
        case REDIRECT:
            return {...state,
            open: true,
            link: data
        };
        case RESET_REDIRECT:
            return initialState;
        default:
        return state;
    }
}

export default redirect;