import React from 'react';
import PropTypes from 'prop-types';

const TextMessage = ({ message, dataId }) => {
    let d = message.data[dataId] //&& typeof dataId != 'undefined' ? message.data[dataId] : message;
    if (typeof d.value === 'number') d.value = '' + d.value;
    return (
        <div>
            {(typeof d.value === 'string' ? [d.value] : d.value).map((mssg, i) => (
                <p key={i} className="mv2 lh-copy"
                    style={{wordWrap: 'break-word'}}
                    dangerouslySetInnerHTML={{__html: mssg}}
                    >
                    {/* {mssg} */}
                </p>
            ))}
        </div>
    )
};

TextMessage.propTypes = {
    message: PropTypes.object.isRequired,
    dataId: PropTypes.number.isRequired,
};

export default TextMessage;
