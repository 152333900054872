import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Form, Input } from 'antd';
import SubmitButton from '../SubmitButton';
import { connect } from 'react-redux';
import { authLoading } from '../../actions/auth';
// import { Hub } from '@aws-amplify/core';

class SignInForm extends SignIn {

    constructor(props) {
        super(props);
        const index = this._validAuthStates.indexOf('signedUp');
        if (index > -1) this._validAuthStates.splice(index, 1);
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.props.loading) return false;
        this.props.form.validateFields((err) => {
            if (!err) {
                this.props.authLoading(true);
                this.signIn(e);
            }
        }); 
    }

    // componentDidMount() {
    //     super.componentDidMount();
    //     Hub.listen('auth', {
    //         onHubCapsule: e => {
    //             if (e.payload.event === 'signUpSubmit') {
    //                 this.inputs.username = e.payload.data.username;
    //                 this.inputs.password = e.payload.data.password;
    //             }
    //             if (e.payload.event === 'signedUp') this.signIn();
    //         }
    //     });
    // }

    renderError = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors && errors.length > 0) {
            return (
                <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
            )
        } else return null;
    }

    showComponent() {
        const { hide } = this.props;
        if (hide && hide.includes(SignInForm)) { return null; }
        
        const {
            getFieldDecorator
        } = this.props.form;

        return (
            <div>
                <div className="mw6 center pv3 pv4-ns">
                    <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                        <img className="" src="/assets/img/hala-logo.svg" alt="" />
                    </div>
                </div>
                <div className="mw6 center ph2 mb2 mb5-l">
                    <div className="pa3 pa4-ns pa5-l bg-white br1">
                        <h1 className="f3 f2-ns mv0">Log in to Hala Portal</h1>
                        <p className="mt2 mb0 lh-copy">Hello, we are glad to see you!</p>
                        <Form onSubmit={this.handleSubmit} className="mt3">
                            <div>
                                <label htmlFor="username" className="b f6">Email:</label>
                                {getFieldDecorator('username', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your Email!'
                                    },{
                                        type: "email",
                                        message: 'Email address in invalid format!'
                                    }],
                                    validateTrigger: "onSubmit",
                                    onChange: this.handleInputChange
                                })(
                                    <Input autoComplete="username" prefixCls="h" className="w-100 mt1 pv2 ph2 f5" name="username"  />
                                )}
                                { this.renderError('username') }
                            </div>
                            <div className="mt3">
                                <label htmlFor="password" className="b f6">Password:</label><a href="/" className="link fr f6"
                                    onClick={(e) => {e.preventDefault(); this.changeState('forgotPassword')}}>Reset password</a>
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your Password!'
                                    }, {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,40}$/,
                                        message: 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!'
                                    }],
                                    validateTrigger: "onSubmit",
                                    onChange: this.handleInputChange
                                })(
                                    <Input autoComplete="current-password" name="password" className="w-100 mt1 pv2 ph2 f5" type="password"  />
                                )}
                                { this.renderError('password') }
                            </div>
                            <div className="mt3">
                                <SubmitButton className="w-100" value="Log in" loader={this.props.loading}/>
                            </div>
                        </Form>
                        <p className="mt3 mb0 lh-copy">
                            {/* Don't have an account? 
                            <a href="" 
                                className="link"
                                onClick={(e) => {e.preventDefault(); this.changeState('signUp')}}>
                                Sign up now
                            </a> */}
                            <span>Need help? </span>
                            <a href="mailto:support@hala.ai?subject=Hala%20Portal%20Contact%20" className="link">Contact the Hala Team</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.auth.loading
});

SignInForm = connect(
  mapStateToProps,
  {
    authLoading
  }
)(SignInForm);

const WrappedSignInForm = Form.create()(SignInForm);
export default WrappedSignInForm;
