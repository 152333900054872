import * as api from '../services/api';
import * as c from '../constants';
import history from '../history';
import { error } from './errors';

// Skill filters

export const setSkillSearchText = value => ({
    type: c.SET_SKILL_SEARCH,
    value
})

export const setSkillSearchProvider = value => ({
    type: c.SET_SKILL_PROVIDER,
    value
})

export const setSkillSearchArea = value => ({
    type: c.SET_SKILL_AREA,
    value
})

export const setSkillSearchDomainKnowledge = value => ({
    type: c.SET_SKILL_DOMAIN_KNOWLEDGE,
    value
})

export const resetFilters = () => ({
    type: c.RESET_SKILL_FILTERS
})

export const selectSkills = (data) => ({
    type: c.SELECT_SKILLS,
    data
})

// Skills fetch

const requestSkills = () => ({
    type: c.REQ_SKILLS
})

const requestSkillsSuccess = data => ({
    type: c.REQ_SKILLS_SUCCESS,
    data
})

const requestSkillsError = data => ({
    type: c.REQ_SKILLS_ERROR,
    data
})

// Skill manipulatiosn

const requestUpdateSkill = data => ({
    type: c.UPDATE_SKILL,
    data
})

const updateSkillSuccess = data => ({
    type: c.UPDATE_SKILL_SUCCESS,
    data
})

const updateSkillError = data => ({
    type: c.UPDATE_SKILL_ERROR,
    data
})

const requestCreateSkill = data => ({
    type: c.CREATE_SKILL,
    data
})

const createSkillSuccess = data => ({
    type: c.CREATE_SKILL_SUCCESS,
    data
})

const createSkillError = data => ({
    type: c.CREATE_SKILL_ERROR,
    data
})

export const requestDeleteSkill = data =>({
    type: c.DELETE_SKILL,
    data
});
export const deleteSkillSuccess = data =>({
    type: c.DELETE_SKILL_SUCCESS,
    data
});
export const deleteSkillError = data =>({
    type: c.DELETE_SKILL_ERROR,
    data
});



// Thunk bindAction

export const saveSkill = skill => (
    async dispatch => {
        if (skill._id) dispatch(updateSkill(skill));
        else dispatch(createSkill(skill));
    }
)

export const createSkill = (skill) => (
    async dispatch => {
        dispatch(requestCreateSkill(skill));
        try {
            const new_skill = await api.createSkill(skill);
            dispatch(createSkillSuccess(new_skill));
            history.push(`/skills/${new_skill._id}`);
        } catch (err) {
            dispatch(error(err.message));
            dispatch(createSkillError(err.message));
        }
    }
)

export const updateSkill = skill => async dispatch => {
    dispatch(requestUpdateSkill(skill));
    try {
        await api.updateSkill(skill);
        dispatch(updateSkillSuccess(skill));
        history.push(`/skills/${skill._id}`);
    } catch (err) {
        dispatch(error(err.message));
        dispatch(updateSkillError(err.message));
    }
}

export const deleteSkill = skill => (
    async dispatch => {
        dispatch(requestDeleteSkill(skill));
        try {
            await api.deleteSkill(skill)
            dispatch(deleteSkillSuccess(skill));
            history.push('/skills');
        } catch (err) {
            dispatch(error(err.message));
            dispatch(deleteSkillError(err.message));
        }
    }
);

export const fetchSkills = () => async dispatch => {
    dispatch(requestSkills());
    try {
        const skills = await api.getSkills();
        skills.map((el,k) => {
            el.key = k;
            return el;
        });
        dispatch(requestSkillsSuccess(skills));
    } catch (err) {
        dispatch(error(err.message));
        dispatch(requestSkillsError(err.message));
    }
}

export const confirmDeleteSkill = data => (
    async dispatch => {
        dispatch({
            type: c.RENDER_CONFIRMATION_POPUP,
            payload: {
                data: [ data ],
                type: 'skill',
                ok: async () => {
                    return dispatch(deleteSkill(data))
                }
            }
        });
    }
)
