import { combineReducers } from 'redux';
import {
    REQ_COMAPNY_SKILLS,
    REQ_COMAPNY_SKILLS_SUCCESS,
    REQ_COMAPNY_SKILLS_ERROR,
    SET_SKILL_LIMIT_SUCCESS,
    SET_TOTAL_SKILLS_LIMIT_SUCCESS
} from '../../constants';

const data = (state = [], {data, type}) => {
    switch (type) {
        case REQ_COMAPNY_SKILLS_SUCCESS:
            return data.data;
        case SET_TOTAL_SKILLS_LIMIT_SUCCESS:
            return {...state, limit: data};
        case SET_SKILL_LIMIT_SUCCESS:
            return  {...state,
                data: state.data.map( sk => {
                if(sk.id === data.id){
                    return {...sk, limit: data.limit};
                }
                return sk;
            })
        };
        default:
            return state;
    }
}

const totals = (state = {
    limit: 0,
    total: 0
}, {type, data}) => {
    switch(type){
        case REQ_COMAPNY_SKILLS_SUCCESS:
            return {...state, limit: data.limit, total: data.total};
        default:
            return state;
    }
}

const lastFetched = (state = null, {type, data}) => {
    if(type === REQ_COMAPNY_SKILLS_SUCCESS) {
        return data.date;
    }
    return state;
}

const period = (state = {}, { type, data }) => {
    switch(type){
        case REQ_COMAPNY_SKILLS_SUCCESS:
            return data.period;
        default:
            return state;
    }
}

const fetching = (state = false, {type}) => {
    switch (type) {
        case REQ_COMAPNY_SKILLS:
            return true;
        case REQ_COMAPNY_SKILLS_SUCCESS:
        case REQ_COMAPNY_SKILLS_ERROR:
        return false;
        default:
            return state;
    }
}


export default combineReducers({data, fetching, lastFetched, period, totals});

// Selectors



export const sortBy = (state, filters) => {
    console.log('!!!!!',filters);
    

        const sortOption = (typeof filters.sortOption === 'string') ? filters.sortOption === 'ascending' : filters.sortOption;
        console.log('!!!!!', sortOption);
        const count = filters.count ? filters.count : state.length;

        if(filters.stringFilter && filters.stringFilter !== ''){         
            state = filterByString(state, filters.stringFilter);
        }

        if(filters.statusFilter && filters.statusFilter !== ''){
            state = filterByStatus(state, filters.statusFilter);
        }
        
        switch(filters.sortFilter){
            case 'byName':
            case 'byUser':
                return sortByName(state, filters.sortFilter, sortOption).slice(0, count);
            case 'byCost':
            case 'byRequest':
            case 'byTotalCost':
            case 'byLimit':
            case 'byApiCalls':
            case 'byDate':
                return sortByValue(state, filters.sortFilter, sortOption).slice(0, count);
            default:
                return state;
        }
} 

const filterByString = (state, filter) => {
    filter = filter.toLowerCase();
    console.log('!!!!! filterByString',  filter);
    return state.filter( s => {
    	return ((s.name.toLowerCase().indexOf(filter) > -1) ||
        (s.userName.toLowerCase().indexOf(filter) > -1) ||
        (s.status.toLowerCase().indexOf(filter) > -1));
    })
}

const filterByStatus = (state, filter) => {
    if(filter && ['Failed', 'Processing', 'Completed'].indexOf(filter) > -1){
        return state.filter( s => {
            return s.status === filter;
        })
    }
    return state;
}

const sortByName = (items, type, sortOption) => {
    switch (type) {
        case 'byName':
            return items.sort( (a, b) => {
                const aString = a.name.toUpperCase(); // ignore upper and lowercase
                const bString = b.name.toUpperCase(); // ignore upper and lowercase
                if (aString < bString) {
                return sortOption ? -1 : 1;
                }
                if (aString > bString) {
                return sortOption ? 1 : -1;
                }
                // names must be equal
                return 0;
            });
        case 'byUser':
            return items.sort( (a, b) => {
                const aString = a.userName.toUpperCase(); // ignore upper and lowercase
                const bString = b.userName.toUpperCase(); // ignore upper and lowercase
                if (aString < bString) {
                return sortOption ? -1 : 1;
                }
                if (aString > bString) {
                return sortOption ? 1 : -1;
                }
                // names must be equal
                return 0;
            });
        default:
            return items;
    }
}

const sortByValue = (items, type, sortOption) => {
    console.log('stat in sortByvalue', items)
    switch(type){
        case 'byCost':
            return items.sort(function (a, b) {
                return sortOption ? a.price - b.price : b.price - a.price;
            });
        case 'byRequest':
            return items.sort(function (a, b) {
                return sortOption ? a.requests - b.requests : b.requests - a.requests;
            });
        case 'byTotalCost':
            return items.sort(function (a, b) {
                let aTotal = a.requests * a.price;
                let bTotal = b.requests * b.price;
                return sortOption ? aTotal - bTotal : bTotal - aTotal;
            });
        case 'byLimit':
            return items.sort(function (a, b) {
                return sortOption ? a.limit - b.limit : b.limit - a.limit;
            });
        case 'byDate':
            return items.sort(function (a, b) {
                return sortOption ? Date.parse(a.date) - Date.parse(b.date) : Date.parse(b.date) - Date.parse(a.date);
            });
        case 'byApiCalls':
            return items.sort(function (a, b) {
                return sortOption ? a.apiCalls - b.apiCalls : b.apiCalls - a.apiCalls;
            });
        default:
            return items;
    }
}

