import React from 'react';
import PropTypes from 'prop-types';
import NotificationBlock from './NotificationBlock';
import { platformOptions } from './objectContainer';

const NotificationPlatform = ({handleChange, options}) => (
    <React.Fragment>
        <h2 className="mt0 mb2">Platform notifications</h2>
        <p className="mt2">Choose notifications you wish to receive.</p>
        {
            platformOptions.map( op => <NotificationBlock key={op.name} 
                checked={options[op.name]} 
                name={op.name} label={op.label} 
                description={op.description} 
                handleChange={handleChange} />)
        }
        <hr className="ba b--black-05 mv4" />
    </React.Fragment>
)

export default NotificationPlatform;

NotificationPlatform.propTyepes = {
    handleChange: PropTypes.func.isRequired,
    options: PropTypes.shape({
      announcements: PropTypes.bool.isRequired,
      maintenance: PropTypes.bool.isRequired,
      newSkills: PropTypes.bool.isRequired,
    }).isRequired,
}
