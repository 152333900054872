import * as c from '../constants';
import * as api from '../services/api';
import { error } from './errors';

export const analLogsSetPage = (data) => ({
    type: c.LOGS_SET_PAGE,
    data
})

const analyticsStatsReq = () => ({
    type: c.ANALYTICS_STATS_REQ
})

const analyticsStatsSuccess = (data) => ({
    type: c.ANALYTICS_STATS_SUCCESS,
    data
})

const analyticsStatsError = () => ({
    type: c.ANALYTICS_STATS_ERROR
})

export const getAnalStats = (query) => async dispatch => {
    dispatch(analyticsStatsReq());
    try {
        const result = await api.getAnalyticsStatistics(query);
        dispatch(analyticsStatsSuccess(result));
    } catch(err){
        dispatch(error(err.message));
        dispatch(analyticsStatsError(err.message));
    }
}

export const getTopUsers = (query) => async dispatch => {
    const result = await api.getTopUsers(query);
    return result;
};

const analyticsLogsReq = () => ({
    type: c.ANALYTICS_LOGS_REQ
})

const analyticsLogsSuccess = (data) => ({
    type: c.ANALYTICS_LOGS_SUCCESS,
    data
})

const analyticsLogsError = (payload) => ({
    type: c.ANALYTICS_LOGS_ERROR,
    payload
})

export const getAnalLogs = ({
    from,
    to,
    users,
    skills,
    status,
    page,
    recordsPerPage,
    technicalUsers
}) => async dispatch => {
    dispatch(analyticsLogsReq());
    try {
        
        let _users = {};
        users.forEach(ids => {
            ids.split('::').forEach(id => {
                _users[id] = true;
            });
        });

        const result = await api.getAnalyticsLogs({
            from,
            to,
            users: Object.keys(_users),
            skills,
            status,
            page,
            limit: recordsPerPage,
            technicalUsers
        });
        dispatch(analyticsLogsSuccess(result));
    } catch(err){
        dispatch(error(err.message));
        dispatch(analyticsLogsError(err.message));
    }
}

const fetchPayloadHistoryReq = (payload) => ({
    type: c.FETCH_PAYLOAD_HISTORY_REQ,
    payload
})

const fetchPayloadHistorySuccess = (payload) => ({
    type: c.FETCH_PAYLOAD_HISTORY_SUCCESS,
    payload
})

const fetchPayloadHistoryError = (payload) => ({
    type: c.FETCH_PAYLOAD_HISTORY_ERROR,
    payload
})

export const fetchPayloadHistory = payload => async dispatch => {
    dispatch(fetchPayloadHistoryReq(payload));
    try{
        const history = await api.getConversationHistory(payload._id);

        // hardcoded test for FilePicker component
        
        // console.log('\n\n\n history \n\n\n', history);
        // history.push({
        //     author: {firstName: "Petar", lastName: "Petrov"},
        //     company: "5c013baa4c0a51fa26e2c4af",
        //     createdAt: "2019-02-11T13:16:49.971Z",
        //     data: [{
        //         type: 'text',
        //         value: 'File Picker result'
        //     }, {
        //         type: 'filePicker',
        //         filePicker: '03da2250-f88b-11e8-9dc8-4ffbc7c9f4dc'
        //     }],
        //     date: "2019-02-11T13:16:49.952Z",
        //     isHala: false,
        //     payload: "5c6175c16ad9470f3a3a7a3c",
        //     responseTime: "1087",
        //     user: "ef640c80-4b5f-48b9-8add-df64ab77adab",
        //     _id: "5c6175c16ad9470f3a3a8a3b"
        // })
        dispatch(fetchPayloadHistorySuccess(history));
    } catch(err){
        dispatch(error(err.message));
        dispatch(fetchPayloadHistoryError(err.message))
    }
}

export const closeDetailsView = () => ({
  type: c.CLOSE_DETAILS_VIEW
})

export function onChangeFilter(from, to) {
    return {
        type: c.ANALYTICS_FILTER_BY_DATE,
        payload: { from, to }
    }
}

export function filterByUser(payload) {
    return {
        type: c.ANALYTICS_FILTER_BY_USER,
        payload
    }
}

export function filterByStatus(payload) {
    return {
        type: c.ANALYTICS_FILTER_BY_STATUS,
        payload
    }
}

export function filterBySkill(payload) {
    return {
        type: c.ANALYTICS_FILTER_BY_SKILL,
        payload
    }
}

export function filterByTechnicalUsers(payload) {
    return {
        type: c.ANALYTICS_FILTER_BY_TECHNICAL_USERS,
        payload
    }
}