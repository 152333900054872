// Platform notifications

export const platformOptions = [{
    name: 'announcements',
    label: 'Product announcements',
    description: 'Occasional product news, updates and new service launches.'
}, {
    name: 'maintenance',
    label: 'Planned maintenance and downtimes',
    description: 'Receive notifications about server status and planned maintenance.'
}, {
    name: 'newSkills',
    label: 'New Skills announcement',
    description: 'Occasional product updates, news.'
}];

export const spendingOptions = [{
    name: 'accountLimit',
    label: 'Limit for Account or Skill was reached',
    description: null
}, {
    name: 'budgetWarning',
    label: '80% of Account budget was spent',
    description: null
}, {
    name: 'spendingDigest',
    label: 'Monthly spendings digest',
    description: 'Receive detailed report about your spendings in the end of every month.'
}];

// index
export const notificationsHeader = {
    title: 'Platform and spending notifications',
    description: 'Page description dolor sit amet, consectetur adipiscing elited do eiusmod tempor labore et dolore magna aliqua.'
};