import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import moment from 'moment';
import { Skeleton } from 'antd';
import {  logsTable } from './objectContainer';
import { fetchPayloadHistory } from '../../actions/analytics';

class Table extends Component {

    highLightMatchedText = (text) => {
        if(text && text.length){
            const value = this.props.logs.filters.search;
            let parts = text.split(new RegExp(`(${value})`, 'gi'));
            return (
                <span>
                    { 
                        parts.map((part, i) => 
                            <span key={i} style={part === value ? { fontWeight: 'bold' } : {} }>
                            { part }
                            </span>
                        )
                    } 
                </span>
            );
        }
        return text;
    }

    setColor = (status) => {
        let color;
        switch(status){
            case 'Failed':
            color = 'red';
            break;
            case 'Processing':
            color = 'grey';
            break;
            default:
            color = 'green';
            break;
        }
        return color;
    }


    render(){
        const { fetchPayloadHistory, logs: { data, isFetching, pagination: { recordsPerPage } }, isSuperAdmin } = this.props;
        //const img = sortOption ? 'desc' : 'asc';
        const tableLables = isSuperAdmin ? logsTable : logsTable.filter( l => l.key !== 'apiCalls');
        return (
            <Skeleton loading={isFetching} active paragraph={{recordsPerPage: recordsPerPage}} title={false}>
                <table className="table w-100 tl">
                <thead>
                    <tr className="bg-navy-near-white">
                        {
                            tableLables.map( i => <th key={i.sortValue} className="pointer">{i.name}</th>)
                        }
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length ? data.map( (record,i) => (
                            <tr key={i}>
                                <td>{moment(record.date).format("D MMM YYYY, H:mm:ss")}</td>
                                <td>{this.highLightMatchedText(record.skill)}</td>
                                <td>{this.highLightMatchedText(`${record.user.firstName} ${record.user.lastName}`)}</td>
                                <td className="tc">{record.messages}</td>
                                {isSuperAdmin ? <td className="tc">{record.apiCalls}</td> : null}
                                <td><span className={"o-60 " + this.setColor(record.status)}>{record.status}</span></td>
                                <td><span className="link" onClick={() => fetchPayloadHistory(record)}>Details</span></td>
                            </tr>
                        )) : null
                    }
                </tbody>
            </table>
        </Skeleton>
        );
    }
};

const mapStateToProps = (state) => ({
    logs: state.analytics.logs,
    isSuperAdmin: state.auth.isSuperAdmin
})

const mapDispatchToProps = {
    fetchPayloadHistory
}


export default connect(mapStateToProps, mapDispatchToProps)(Table);

Table.propTypes = {
    logs: PropTypes.object.isRequired
}