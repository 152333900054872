import React from 'react';
import { connect } from 'react-redux';
import { resetRedirect } from '../../actions/redirect';

const RedirectWarning = ({link, show, resetRedirect}) => {
    return (
        <React.Fragment>
        {
            show ? 
            (<div  className="z-max fixed w-100 vh-100 bg-black-90 container" style={{top: 0, right: 0, bottom: 0, left: 0}}>
                <div  className="mw6 center pv3 pv4 ph2 mb2 mb5-l">
                    <div className="mw6 center pv3 pv4-ns">
                        <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                            <img className="" src="/assets/img/hala-logo.svg" alt="" />
                        </div>
                    </div>
                        <div className="mw6 center ph2 mb2 mb5-l">
                        <div className="pa3 pa4-ns pa5-l bg-white br1">
                            <h1 className="f3 f2-ns mv0">You are leaving Hala</h1>
                            <p className="mv3 lh-copy">We are not responsible for any external web site content. If you are sure to continue click the link bellow, if no simply close this tab.</p>
                            <p className="bg-navy-near-white pa3 code mv0 f6" style={{overflowWrap:'break-word', wordWrap:'break-word'}}>
                            <a href={link} target="_blank" rel='noreferrer noopener' className="link">{link}</a>
                            </p>
                            <p className="mt3 mb0 lh-copy"></p>
                            <span onClick={() => resetRedirect()} className="db tc btn btn-blue pv2 ph3 f5">Close</span>
                        </div>
                    </div>
                </div>
            </div>  ) : null
        }
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    show: state.redirect.open,
    link: state.redirect.link
})

export default connect(mapStateToProps, {resetRedirect})(RedirectWarning);