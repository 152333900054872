import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CatalogueBlock = ({int}) => (
    <div key={int.img} className="w-50 w-third-l ph2">
        <div className="flex items-center mt3 pa3 bg-navy-near-white br1">
            <div className="flex-none bg-white br1" style={{width:'64px', height:'64px'}}>
                <img className={int.link ? "br1" : "br1 o-40"} src={int.img} alt={`${int.name} logo`}/>
            </div>
            <div className="ml3">
                <h4 className="mt0 mb2">{int.name}</h4>
                {int.link ? <Link to={int.link} className="link mt2">+ Add</Link> : null}
            </div>
        </div>
    </div>
);

export default CatalogueBlock;

CatalogueBlock.propTypes = {
    int: PropTypes.shape({
        img: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        link: PropTypes.string,
    }).isRequired,
}