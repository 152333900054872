import React from 'react';
import './submitButton.sass';
import classNames from'classnames';

const SubmitButton = ({loader, value, className, ...props}) => {
    return (
        <button {...props} type="submit" className={classNames('dib btn btn-blue pv2 ph3 f5 mr2 button', className, {
            loading: loader
        })}>
            <span className="container"><span className="text">{value}</span><div className="mini-loader"></div></span>
        </button>
    );
};

export default SubmitButton;