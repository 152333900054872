import React, { useCallback } from 'react';
import { DatePicker } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { onChangeFilter } from '../../../actions/analytics';
import TechnicalUsersFilter from '../TechnicalUsersFilter';
import './index.sass';

const { RangePicker } = DatePicker;
const dateFormat = 'MMMM Do, YYYY';

function AnalyticsDateRange({
    from,
    to,
    onChangeFilter,
    isFetching
}) {

    const onChange = useCallback(d => {
        if (isFetching) return false;
        if (d[0] && d[1]) {
            onChangeFilter(d[0].toISOString(), d[1].endOf('day').toISOString());
        }
    }, [isFetching]);

    return (
        <React.Fragment>
            <RangePicker
                className="analytics-date-range"
                defaultValue={[moment(from), moment(to)]}
                onChange={onChange}
                format={dateFormat}
            />
            <TechnicalUsersFilter/>
        </React.Fragment>
    )
}

AnalyticsDateRange.propTypes = {
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        from: state.analytics.filters.from,
        to: state.analytics.filters.to,
        isFetching: state.analytics.logs.isFetching
    }), 
    {
        onChangeFilter
    }
)(AnalyticsDateRange);