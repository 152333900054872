import {
    FILTERS_REQ_USERS,
    FILTERS_REQ_USERS_SUCCESS,
    FILTERS_REQ_USERS_ERROR,
    FILTERS_REQ_SKILLS,
    FILTERS_REQ_SKILLS_SUCCESS,
    FILTERS_REQ_SKILLS_ERROR
} from '../constants';
import { getAllUsers, getAllSkills } from '../services/api';

export const fetchUsers = () => (
    async dispatch => {
        dispatch({
            type: FILTERS_REQ_USERS
        });
        try {
            dispatch({
                type: FILTERS_REQ_USERS_SUCCESS,
                payload: await getAllUsers()
            });
        } catch (err) {
            dispatch({
                type: FILTERS_REQ_USERS_ERROR,
                payload: err.message
            });
        }
    }
);

export const fetchSkills = () => (
    async dispatch => {
        dispatch({
            type: FILTERS_REQ_SKILLS
        });
        try {
            dispatch({
                type: FILTERS_REQ_SKILLS_SUCCESS,
                payload: await getAllSkills()
            });
        } catch (err) {
            dispatch({
                type: FILTERS_REQ_SKILLS_ERROR,
                payload: err.message
            });
        }
    }
);