import { combineReducers } from 'redux';
import _ from 'lodash';
import {
    REQ_USERS,
    REQ_USERS_SUCCESS,
    REQ_USERS_ERROR,
    CREATE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    DELETE_USERS_SUCCESS,
    SET_USERS_FILTER,
    SELECT_USER,
    DESELECT_USER,
    SELECT_ALL_USERS,
    DESELECT_ALL_USERS
} from '../constants';

const initialState = [];

const data = (state = initialState, { type, data }) => {
    switch (type) {
        case REQ_USERS_SUCCESS:
            return [
                ...data
            ]
        case CREATE_USER_SUCCESS:
            data.key = data.id;
            return [
                ...state,
                {
                    ...data
                }
            ]
        case UPDATE_USER_SUCCESS:
            return state.map(user =>
                user.id === data.id ?
                { ...user, ...data } :
                user
            )
        case DELETE_USERS_SUCCESS:
            return state.filter(el => data.indexOf(el.id) === -1)
        default:
            return state
    }
}

const fetching = (state = false, {type}) => {
    switch(type){
        case REQ_USERS:
            return true;
        case REQ_USERS_SUCCESS:
        case REQ_USERS_ERROR:
            return false;
        default:
            return state;
    }
}

const filter = (state = '', action) => {
    if(action.type === SET_USERS_FILTER) {
        return action.filter
    }
    return state;
}

const selectedUsers = (state = [], { type, data }) => {
    switch (type) {
        case SELECT_USER:
            return [...state,
            data];
        case DESELECT_USER:
                return state.filter( item => item !== data);
        case SELECT_ALL_USERS:
                return _.union(state, data);
        case DESELECT_ALL_USERS:
            return _.xor(state, data);
        case DELETE_USERS_SUCCESS:
            return state.filter(id => data.indexOf(id) === -1)
        default:
            return state
    }
}


export default combineReducers({data, fetching, filter, selectedUsers});

export const filterUsers = (state, filter) => {
    return state.filter( user =>{
        return ((user.firstName.toLowerCase().indexOf(filter.toLowerCase()) > -1) || (user.lastName.toLowerCase().indexOf(filter.toLowerCase()) > -1)
            ||(user.email.toLowerCase().indexOf(filter.toLowerCase()) > -1) || (user.company.toLowerCase().indexOf(filter.toLowerCase()) > -1))
    })
}

export const findUser = (state, id) => {
    if(id !== 'newUser'){
        return state.find( user => {
            return user.id === id;
        })
    } return {};
}

export const usersToDelete = (state, ids) => {
    return state.filter( user => ids.indexOf(user.id) > -1)
}

