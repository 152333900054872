import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'antd';
import PageHead from '../../PageHead';
import TextInput from './TextInput';
import { profileObject } from './objectContainer';
import SubmitButton from '../../SubmitButton';
import { updateUser } from '../../../actions/users';

export class Profile extends Component {

    resetForm = () => {
        console.log('restore the default values from redux state')
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll } } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                if(this.props.user){
                    values.id = this.props.user.id;
                }
                this.props.updateUser(values);
            } else{
                console.log("errors", err, 'values', values);
            }
        });
    }

    errorMessage = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors){
            if(errors.length > 0) {
                return (
                    <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
                )
            } else {
                return null;
            }
        }
    }

    render() {
        const { form: { getFieldDecorator }, loader } = this.props;
        const user = this.props.user || {};
        return (
            <div className="bg-white br1 br--top" style={{minHeight:'99vh'}}>
                <PageHead title={'Profile information'} />
                <div className="pa4 pb5-l">
                    <div className="ph3">
                        <div className="measure-wide">
                            <p className="mt2">Your profile information is available only for your Company admins and Hala for identification purposes.</p>
                            <Form onSubmit={this.handleSubmit}
                                    className="mt3">
                                { 
                                    profileObject.map( item => <TextInput key={item.key}
                                        item={item} object={user} getFieldDecorator={getFieldDecorator} errorMessage={this.errorMessage} />)
                                } 
                                <div className="mt3">
                                    <SubmitButton  value={'Save changes'} loader={loader} />
                                    <input type="button" value="Cancel" onClick={this.resetForm} className="dib btn btn-ghost pv2 ph3 f5" />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loader: state.forms.users.create
})

const mapDispatchToProps = {
    updateUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Profile));

Profile.propTypes = {
    // getFieldDecorator: PropTypes.func.isRequired,
    loader: PropTypes.bool.isRequired,
}