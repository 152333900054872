import React, { Component } from 'react';
import { connect } from 'react-redux';
import { findSkill } from '../../reducers/skills';
import { saveSkill } from '../../actions/skills';
import { resetFormState } from '../../actions/users';
import { error } from '../../actions/errors';
import SubmitButton from '../SubmitButton/index';
import './index.sass';

const filterOptions = {
    serviceProviders: ['SAP', 'Hala'],
    areas: [{
        key: 'FI',
        name:'Finance'
    }, {
        key: 'MM',
        name: 'Material Managment'
    },{
        key: 'SD',
        name: 'Sales and Distribution'
    },{
        key: 'CO',
        name: 'Controlling'
    },{
        key: 'BS',
        name: 'Basis'
    },{
        key: 'General',
        name: 'General'
    }],
    domainKnowledges: [{
        key: 'ERP',
        name:'Enterprise Resource Planning (ERP)'
    },{
        key: 'Communication',
        name: 'Communication tools'
    }]
};

class SkillForm extends Component {

    state = {
        conversationInfo: [],
        screenShots: [],
        screenShotUrl: '',
        skillCommandInput: '',
        name: '',
        description: '',
        area: '',
        serviceProvider: '',
        domainKnowledge: '',
        skillResult: '',
        workflowApproval: false
    }

    constructor(props){
        super(props);
        if(props.skill){
            const skill = props.skill;
            for(let prop in skill){
                this.state[prop] = skill[prop];
            }
        }
    }

    componentDidUpdate(prevProp){
        const { skill } = this.props;
        if(prevProp.skill !== skill){
            for(let prop in skill){
                this.setState({[prop]: skill[prop]});
            }
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { error } = this.props;
        let skill = {...this.state};
        if(!skill.name.length || !skill.area.length || !skill.serviceProvider.length ||
         !skill.description.length || !skill.skillResult.length || !skill.domainKnowledge.length ||
         !skill.conversationInfo.length){
            error('All fields are required!');
        } else{
            delete skill.skillCommandInput;
            delete skill.screenShotUrl;
            console.log(skill);
            this.props.saveSkill(skill);
        }
    }

    errorMessage = (field) => {
     const errors = this.state.errors;
        if(errors){
            if(errors.length > 0) {
                return (
                    <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
                )
            } else {
                return null;
            }
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
        [name]: value
        });
    }

    keyPress = (e) => {
        if(e.key === 'Enter'){
            if(e.target.name === 'skillCommandInput'){
                this.addSkillCommand()
            } else{
                this.addScreenShot()
            }
        }
    }

    addSkillCommand = () => {
        let { conversationInfo, skillCommandInput } = this.state;
        // conversationInfo = conversationInfo.concat({id: uuid(), value: skillCommandInput});
        conversationInfo = conversationInfo.concat(skillCommandInput);  
        console.log('\n\n\n', conversationInfo, '\n\n\n');  
        this.setState({conversationInfo, skillCommandInput: ''});
    }

    addScreenShot = () => {
        let { screenShots, screenShotUrl } = this.state;
        screenShots = screenShots.concat(screenShotUrl);
        this.setState({screenShots, screenShotUrl: ''});
    }

    removeSkillCommand = (e) => {
        const id = Number(e.target.id);
        let { conversationInfo } = this.state;
        // conversationInfo = conversationInfo.filter( el => el.id !== id);
        console.log('id is ', id)
        console.log([...conversationInfo.slice(0, id), ...conversationInfo.slice(id+1)]);
        const _conversationInfo = [...conversationInfo.slice(0, id), ...conversationInfo.slice(id+1,)];
        console.log( ' \n\n\n conversation info 2', _conversationInfo, id);
        this.setState({conversationInfo: _conversationInfo});
    }

    render() {
        const skill = this.props.skill ? this.props.skill : {};
        const { skillCommandInput, name, serviceProvider, area, domainKnowledge, workflowApproval, screenShotUrl, screenShots, conversationInfo, skillResult, description } = this.state;
        const { formLoaders } = this.props;
        return (
            <div className="bg-white br1 br--top" style={{minHeight: '99vh'}}>
                <header className="pa4 bg-navy-near-white br1 br--top">
                    <div className="ph3">
                        <div className="measure-wide">
                            <p className="f6"><span onClick={this.goBack} className="link f6">← go back</span></p>
                            <h2 className="mb2">{skill.name ? `Edit ${skill.name}` : `Create new Skill`}</h2>
                        </div>
                    </div>
                </header>
                <div className="pa4 pb5-l">
                    <div className="ph3">
                        <div 
                        // onSubmit={this.handleSubmit}
                        className="measure-wide mt3">
                            <div className="mt4">
                            <h2>Skill details</h2>
                                <div className="w-100">
                                    <label htmlFor="" className="b f6">Name:</label>
                                    <input type="text" name="name" value={name} onChange={this.handleChange} className="w-100 mt1 pv2 ph2 f5" />
                                </div> 
                                <div className="mt3 w-100">
                                    <label htmlFor="" className="b f6">Skill description:</label>
                                    <textarea type="text" name="description" value={description} onChange={this.handleChange} className="w-100 mt1 pv2 ph2 f5" row="3" />
                                </div>             
                                <div className="mt3 w-100">
                                    <label htmlFor="" className="b f6">Domain Knowledge:</label>
                                    <select id="select" value={domainKnowledge} name="domainKnowledge" onChange={this.handleChange} className="w-100 mt1 pv2 ph2 f5">
                                        <option value="">Select domain knowledge</option>
                                        {
                                            filterOptions.domainKnowledges.map( (option,i) => <option key={`op-${i}`} value={option.key}>{option.name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="mt3 w-100">
                                    <label htmlFor="" className="b f6">Service Provider:</label>
                                    <select id="select" value={serviceProvider} name="serviceProvider" onChange={this.handleChange} className="w-100 mt1 pv2 ph2 f5">
                                        <option value="">Select service serviceProvider</option>
                                        {
                                            filterOptions.serviceProviders.map( op => <option key={op} value={op}>{op}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="mt3 w-100">
                                    <label htmlFor="" className="b f6">Area:</label>
                                    <select id="select" value={area} name="area" onChange={this.handleChange} className="w-100 mt1 pv2 ph2 f5">
                                        <option value="">Select area</option>
                                        {
                                            filterOptions.areas.map( (op,i) => <option key={op.key} value={op.key}>{op.name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="mt3 w-100">
                                <label htmlFor="" className="b f6">Activate approval workflow:</label>
                                    <div className="flex items-center w-100 mt1 pv2 ph2 f5">
                                        <input id="checkbox" name="workflowApproval" checked={workflowApproval} type="checkbox" onChange={this.handleChange} className="mr2" />
                                        <label htmlFor="checkbox" className="">Click to check box</label>
                                    </div>
                                </div>

                            </div>
                            <hr className="ba b--black-05 mv4"/>
                            <div className="mt4">
                                <h2>How to start conversation</h2>
                                {conversationInfo.length ?
                                    conversationInfo.map( (c, i) => (
                                        <div key={i} className="bg-navy-near-white pa2 mb1">
                                            <p className="mv0 f6 o-80">{c}<span id={i}
                                            onClick={this.removeSkillCommand} style={{float: 'right', fontSize: '2em', cursor: 'pointer'}}>-</span></p>
                                        </div> 
                                        )
                                    ) : null
                                }
                                <label htmlFor="" className="b f6">Add a command:</label>
                                <input type="text" name="skillCommandInput" className="w-100 mt1 pv2 ph2 f5 relative" onKeyPress={this.keyPress} value={skillCommandInput} onChange={this.handleChange} /><span className="add-input-plus"
                                    onClick={this.addSkillCommand}>+</span>
                            </div>
                            <hr className="ba b--black-05 mv4"/>
                            <div className="mt4">
                                <h2 className="mt0 mb2">Skill result</h2>
                                <label htmlFor="" className="b f6">Describe the skill result:</label>
                                <textarea name="skillResult" value={skillResult} onChange={this.handleChange} className="w-100 mt1 pv2 ph2 f5" row="3"/>
                            </div>
                            <hr className="ba b--black-05 mv4"/>
                            <div className="mt3">
                                <h2>Screenshots</h2>
                                {
                                    screenShots.length ?
                                    <div className="mt3 flex flex-wrap"> 
                                        {
                                            screenShots.map( (item,i) => {
                                                return(
                                                    <div key={i} className="ph2 mb3 w-third dim pointer">
                                                        <div className="aspect-ratio aspect-ratio--4x3">
                                                            <div className="aspect-ratio--object cover br1 ba b--black-10" style={{background: 'url(' + item + ') center'}}></div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    : null
                                }
                                <label htmlFor="" className="b f6">Add a url link to the screenshot:</label>
                                <input type="text" className="w-100 mt1 pv2 ph2 f5 relative" name="screenShotUrl" value={screenShotUrl} onKeyPress={this.keyPress} onChange={this.handleChange} /><span className="add-input-plus"
                                    onClick={this.addScreenShot}>+</span>                
                            </div>
                            <div className="mt4">
                                <span onClick={this.handleSubmit}><SubmitButton value={skill && skill._id ? 'Update skill' :'Add skill'}  loader={formLoaders.create}/></span>
                                <button type="button" className="dib btn btn-ghost pv2 ph3 f5" onClick={this.goBack} >Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, { match: { params } }) => ({
    skill: findSkill(state.skills.data, params.id),
    formLoaders: state.forms.skills
})

const mapDispatchToProps = {
    saveSkill,
    resetFormState, 
    error
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillForm);