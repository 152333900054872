import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalPicture from './ModalPicture';


class PictureComponent extends Component {

    state = {
        modalView: false
    };

    openModalView = () => {
        this.setState({modalView: true});
    }

    closeModalView = () => {
        this.setState({modalView: false})
    }

    render() {
        const {message: {value, title, size, downloadink}} = this.props;
        const { modalView } = this.state;

        return(
            <div className="pl2 bl mt3 b--black-05 bw3 measure">
                <img className="br1" style={{maxHeight:'240px', cursor: 'pointer'}} src={value} onClick={this.openModalView} alt={title}/>
                { title ? (
                    <p className="mv2 o-60">
                    { title } 
                    { size ? (`(${size})`) : null }</p>
                ) : null }
                { downloadink ? (<p className="mt2 mb0"><a href={downloadink} className="link">Download</a></p>) : null}
                { modalView ? <ModalPicture link={value} closeModalView={this.closeModalView}
                    description={title}/> : null}
            </div>
        );
    }
}


export default PictureComponent;

PictureComponent.propTypes = {
    message: PropTypes.object.isRequired
}
