import React from 'react';
import PropTypes from'prop-types';

const PopupMenu = ({status}) => {
    return (
        <div className="options-menu-dd br1 shadow-4 absolute" style={{top: 0, right: 0}}>
            <ul className="list pa1 ma0">
                <li>
                    <span 
                    onClick={() => console.log('changeStatus')}
                    className="db f6 fw5 o-60 hover-o-100 pa2 br2 pointer">Activate</span>
                </li>
                <li>
                    <span
                    onClick={() => console.log('deactivate')}
                    className="db f6 fw5 o-60 hover-o-100 pa2 br2 pointer">Deactivate</span>
                </li>
                <li>
                    <span
                    onClick={() => console.log('uninstall')}
                    className="db f6 fw5 o-60 hover-o-100 pa2 br2 pointer">Uninstall</span>
                </li>
            </ul>
        </div>
    );
};

export default PopupMenu;

PopupMenu.propTypes = {
    status: PropTypes.string.isRequired,
    // changeStatus: PropTypes.func.isRequired,
    // uninstall: PropTypes.func.isRequired,
}