import {
    RESET_CONFIRMATION_POPUP,
    RENDER_UPDATE_LIMITS_POPUP,
    RESET_UPDATE_LIMITS_POPUP
} from '../constants';


// Confirm delete
export const resetConfirmDelete = () => ({
    type: RESET_CONFIRMATION_POPUP
})

// export const confirmDelete = () => ({
//     type: 'null'
// })

// Update limit

export const updateLimit = (updateObj, type, updateAction) => (
    async dispatch => {
        dispatch({
            type: RENDER_UPDATE_LIMITS_POPUP,
            payload: {
                data: updateObj,
                type: type,
                ok: async (limit) => {
                    if(updateObj === 'undefined'){              
                        return dispatch(updateAction(limit));
                    }
                    return dispatch(updateAction(limit, updateObj));
                    }
            }
        })
    }
)

export const resetUpdateLimit = () => ({
    type: RESET_UPDATE_LIMITS_POPUP
})

