import React from 'react';
import { Link } from 'react-router-dom';

const  EmptyTable = ({mssg}) => (
    <table className="ph3 table w-100 tl">
        <tbody>
            <tr className="tc">
                <td colSpan="6">
                    <div className="pv5">
                        <img src="/assets/img/visuals-empty-users.png" style={{maxHeight:'64px'}} alt="" />
                        <h3 className="mt0 mv2">{mssg}</h3>
                        <p className="mt2 mb0">Follow link to <Link to="/users/addUser" className="link">add new users</Link> to your organisation.</p>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
);

export default EmptyTable;
