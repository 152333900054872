import { combineReducers } from 'redux';
import {
    ANALYTICS_LOGS_REQ,
    ANALYTICS_LOGS_SUCCESS,
    ANALYTICS_LOGS_ERROR,
    LOGS_SET_PAGE,
    FETCH_PAYLOAD_HISTORY_REQ,
    FETCH_PAYLOAD_HISTORY_SUCCESS,
    FETCH_PAYLOAD_HISTORY_ERROR,
    CLOSE_DETAILS_VIEW,
    ANALYTICS_FILTER_BY_STATUS,
    ANALYTICS_FILTER_BY_USER,
    ANALYTICS_FILTER_BY_SKILL,
    ANALYTICS_FILTER_BY_DATE
} from '../../constants';
import filters from './filters';

const initSt = {};

const data = (state = [], { type, data }) => {
    switch (type) {
        case ANALYTICS_LOGS_SUCCESS:
            return [
            ...data.payloads
        ]
        default:
            return state;
    }
}

initSt.pagination = {
    currentPage: 1,
    size: 0,
    recordsPerPage: 20
}

const pagination = (state = initSt.pagination, 
    {type, data}) => {
    switch(type){
        case ANALYTICS_LOGS_SUCCESS:
            return {...state,
                size: data.size
            };
        case LOGS_SET_PAGE:
            if(data > 0){
                return {...state,
                    currentPage: data
                };
            } else {
                return {...state};
            }
        case ANALYTICS_FILTER_BY_STATUS:
        case ANALYTICS_FILTER_BY_USER:
        case ANALYTICS_FILTER_BY_SKILL:
        case ANALYTICS_FILTER_BY_DATE:
            return initSt.pagination;
        default:
            return state;
    }
}

const isFetching = (state = false, {type}) => {
    switch(type){
        case ANALYTICS_LOGS_REQ:
            return true;
        case ANALYTICS_LOGS_SUCCESS:
        case ANALYTICS_LOGS_ERROR:
            return false;
        default:
            return state;
    }
}

const lastUpdated = (state = '', {type, data}) => {
    if(type === ANALYTICS_LOGS_SUCCESS){
        return data.date;
    }
    return state;
}

initSt.detailsView = {
    show: false,
    isFetching: false,
    record: {},
    history: [],
}

const detailsView =  (state = initSt.detailsView, 
    { type, payload }) => {
  switch (type) {
    case FETCH_PAYLOAD_HISTORY_REQ:
        return {...state,
            record: payload,
            show: true,
            isFetching: true
        };
    case FETCH_PAYLOAD_HISTORY_SUCCESS:
        return {...state,
            history: payload,
            isFetching: false 
        };
    case FETCH_PAYLOAD_HISTORY_ERROR:
        return {...state,
            isFetching: false
        };
    case CLOSE_DETAILS_VIEW:
        return initSt.detailsView;
    default:
        return state;
    }
}


export default combineReducers({
    data, pagination, isFetching, lastUpdated, 
    filters, detailsView});