import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AvailableSkills extends Component {

	render(){
		const { data } = this.props;
		const skillGroups = Object.keys(data);
		return (
			<div className="ml4 pl2">
				<p className="mv2 lh-copy">Hala's Digital Skills available for the usage:</p>
				{
					skillGroups.length ? (
						<div className="ph2 bl b--black-05 bw3">
						{
							skillGroups.map( i => {
								if(data[i].data.length) {
									const d = data[i];
									return (
										<React.Fragment key={i}>
											<h4 className="mb0">{d.name}</h4>
											<ul className="list ph0 mv0 lh-copy f6">
												{
													d.data.map( sk =>
														<li key={ sk.id } 
														className="mt1"><span className="link-action">{sk.name}</span></li>
													)
												}
											</ul>
										</React.Fragment>
									)
								}
								return null
							})
						}
						</div>
					) : null
				}
			</div>
		)
	}
}

export default AvailableSkills;

AvailableSkills.propTypes = {
	data: PropTypes.object.isRequired,
}