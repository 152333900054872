import React from 'react';
import { Link } from 'react-router-dom';

const Documentation = () => {
    return (
        <div className="w-100 pl1" style={{minHeight:'99vh'}}>
        <div className="bg-navy-near-white br1 br--top" style={{minHeight:'99vh'}}>

          <div className="pa4 br1 br--top">
            <div className="ph3">
              <div className="measure-wide center mv4 mv5-m mv6-l tc">
                <img src="/assets/img/visuals-empty-under-construction.png" style={{maxHeight:'64px'}} alt="" />
                <h2 className="mb2">Documentation section is under construction.</h2>
                <p className="mt2 mb0 lh-copy">We apologise for the inconvenience, <Link className="link" to="/contacts">contact us</Link> in case of any questions.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
};

export default Documentation;