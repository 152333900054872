import React from 'react';
import PropTypes from 'prop-types';

export const NotificationBlock = ({label, name, checked, handleChange, description}) => (
    <React.Fragment>
        <div className="flex items-center mt3">
            <input id="checkbox-1" type="checkbox" name={name} checked={checked} onChange={handleChange} className="mr2" />
            <label htmlFor="checkbox-1" className="lh-copy"><strong>{label}</strong></label>
        </div>
        { description ? <p className="pl2 ml3 mt0 o-60 f6">{description}</p> : null}
    </React.Fragment>
)

NotificationBlock.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    description: PropTypes.string,
}

export default NotificationBlock;