import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom';
import { fetchIntegrations, resetIntegrationFilters } from '../actions/integrations';
import Connections from '../components/Integrations/Connections';
import IBMCatalogue from '../components/Integrations/IBM/IBMCatalogue';
import Catalogue from '../components/Integrations/Catalogue';
import IntegrationPage from '../components/Integrations/IntegrationPage';

class ConnectionsRoot extends Component {

    componentDidMount(){
        this.props.fetchIntegrations();
    }

    componentWillUnmount(){
        this.props.resetIntegrationFilters();
    }

    render() {
        const { match } = this.props;
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${match.url}/:provider/:service/:id`} component={ IntegrationPage } />
                    <Route path={`${match.url}/catalogue/IBM`} component={ IBMCatalogue } />
                    <Route path={`${match.url}/catalogue`} component={ Catalogue } />
                    <Route path={`${match.url}/`} component={ Connections } />
                </Switch>
            </React.Fragment>
        )
    }
}

export default connect(null, {fetchIntegrations, resetIntegrationFilters})(ConnectionsRoot);