import React from 'react';
import PropTypes from 'prop-types';
import './Overview.sass';

// const coppyToClipboard = text => navigator.clipboard.writeText(text);

const Overview = ({skill, openScreenShotModal}) => {
    return (
        <div className="skill-overview">
            {/* <div className="ph3">
                <h2 className="skill-id mt0 mb2">Skill ID: 
                    <span onClick={() => coppyToClipboard(skill._id)}>{skill._id}</span>
                </h2>
            </div> */}
            {
                skill.conversationInfo ?
                    <div className="ph3">
                        <div className="measure-wide">
                            <h2 className="mt0 mb2">How to start conversation</h2>
                            <p>Use following commands to fulfill request:</p>
                            {
                                skill.conversationInfo.map( (item,i) => {
                                    return(
                                        <div key={i} className="bg-navy-near-white pa2 mb1">
                                            <p className="mv0 f6 o-80">{item}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                : null
            }
            {/* Skill Result */}
            {
                skill.skillResult ?
                    <div className="ph3 mt4">
                        <div className="measure-wide">
                            <h2 className="mt0 mb2">Skill result</h2>
                            <p className="mt2">{skill.skillResult}</p>
                        </div>
                    </div>
                : null
            }
            {/* Screenshots */}
            {
                skill.screenShots ?
                    <div className="ph2 mt4">
                        <div className="measure-wide">
                            <div className="mh2">
                                <h2 className="mt0 mb2">Screenshots</h2>
                            </div>
                            <div className="mt3 flex flex-wrap">
                                {
                                    skill.screenShots.map( (item,i) => {
                                        return(
                                            <div key={i} className="ph2 mb3 w-third dim pointer">
                                                <div className="aspect-ratio aspect-ratio--4x3">
                                                    <div className="aspect-ratio--object cover br1 ba b--black-10" id={i} onClick={openScreenShotModal} style={{background: 'url(' + item + ') center'}}></div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                : null
            }
        </div>
    );
};

export default Overview;

Overview.propTypes = {
    skill: PropTypes.object.isRequired,
    openScreenShotModal: PropTypes.func.isRequired,
}