import React, { useCallback, useEffect } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSkills } from '../../../actions/filtersData';
import { filterBySkill } from '../../../actions/analytics';

const Option = Select.Option;

function filterByInput(input, option) {
    return option.props.searchkey.indexOf(input.toLowerCase()) !== -1;
}

function SkillsFilter({ isFetching, data, fetchSkills, values, filterBySkill }) {

    useEffect(() => {
        if (!data.length) fetchSkills();
    }, [data]);

    const onChange = useCallback(val => {
        if (isFetching) return false;
        filterBySkill(val);
    }, [isFetching]);

    return (
        <Select
            mode="multiple"
            placeholder="Filter by skill name"
            value={values}
            onChange={onChange}
            filterOption={filterByInput}
        >
            {
                data.map(el=> (
                    <Option 
                        key={el.id}
                        searchkey={el.name.toLowerCase()}
                    >{el.name}</Option>
                ))
            }
        </Select>
    )
}

SkillsFilter.propTypes = {
    data: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    fetchSkills: PropTypes.func.isRequired,
    filterBySkill: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        data: state.filtersData.skills,
        values: state.analytics.filters.skills,
        isFetching: state.analytics.logs.isFetching
    }), {
        fetchSkills,
        filterBySkill
    }
)(SkillsFilter);