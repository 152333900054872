import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Amplify from '@aws-amplify/core';
import Title from './Title';
import DeletionList from './DeletionList';
import Description from './Description';
import SubmitButton from '../SubmitButton/index';
import { resetConfirmDelete } from '../../actions/popups';

const log = new Amplify.Logger('Confirm Delete::');

const errorMessage = (<div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">Please enter DELETE to confirm the deletion</div>);

class ConfirmDelete extends Component {

    state = {
        loader: false,
        inputValue: '',
        error: false
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        await this.setState({loader: true});
        if(this.validateConfirmation()){           
            await this.props.ok();
            this.setState({loader: false});
            this.props.resetConfirmDelete();   
        } else{
            this.setState({error: true, loader: false});
        }
    }

    handleChange = e => {
        this.setState({inputValue: e.target.value});
    }

    validateConfirmation = () => {
        return this.state.inputValue === 'DELETE';
    }

    componentWillReceiveProps(nextProps){
        if(this.props.show && !nextProps.show)
        this.setState({loader: false, inputValue: '', error: false});
    }

    render() {
        log.debug('render')
        const { type, selectedItems, resetConfirmDelete, show } = this.props;
        const { inputValue, error, loader } = this.state;
        const length = selectedItems.length;
        return  show ? (
                <div  className="z-max fixed w-100 vh-100 bg-black-90 container" style={{top: 0, right: 0, bottom: 0, left: 0}}>
                    <div  className="mw6 center pv3 pv4 ph2 mb2 mb5-l">
                        <div  className="pa3 pa4-ns pa5-l bg-white br1 ba b--black-10">
                            <Title type={type} length={length} />
                            <DeletionList list={selectedItems} type={type} />
                            <Description type={type} />
                            <form action="" onSubmit={this.handleSubmit} className="mt2">
                                <div  className="">
                                    <input id="" type="text" value={inputValue} onChange={this.handleChange} name=""  className="w-100 mt1 pv2 ph2 f5 red" />
                                    { error ? errorMessage : null}
                                </div>
                                <div  className="mt3">
                                    <SubmitButton value={'Confirm and delete'} loader={loader} />
                                    <input type="button"  value="Cancel" onClick={() => resetConfirmDelete()} className="dib btn btn-ghost pv2 ph3 f5 mr2" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        ) : null
    }
}

const mapStateToProps = state => ({
    selectedItems: state.popups.confirmDelete.data,
    type: state.popups.confirmDelete.type,
    ok: state.popups.confirmDelete.ok,
    show: state.popups.confirmDelete.show
})

export default connect(mapStateToProps, { resetConfirmDelete })(ConfirmDelete);

ConfirmDelete.propTypes = {
    selectedItems: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    ok: PropTypes.func,
    show: PropTypes.bool.isRequired,
}
