import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EmptyTable from './EmptyTable';
import { filterIntegrations } from '../../reducers/integrations';
import ConnectionItem from './ConnectionItem';
import Amplify from '@aws-amplify/core';

const log = new Amplify.Logger('Connections Table::');

const ConnectionsTable = ({ 
    integrations,
    filters: { provider, category, status, search }
 }) => {
    log.debug('render');
    const emptyTablemMssg =  (provider !== '' || category !== '' || status !== '' || search !== '') ? 'No integrations matching the current search options.' : 'You don\'t have any active integrations yet.';
    if (!integrations.length) {
        return <EmptyTable mssg={emptyTablemMssg} />
    }
    return (
        <table className="ph3 table w-100 tl">
            <tbody>
                <tr className="bg-navy-near-white">
                    <th>Name</th>
                    <th>Service provider</th>
                    <th>Service</th>
                    <th>Status</th>
                    {/* <th className="" style={{width:'40px'}}></th> */}
                </tr>
                {
                    integrations.map((int,k) => <ConnectionItem key={`int-${k}`} integration={int}/>)
                }
            </tbody>
        </table>
    );
}

const mapStateToProps = state => ({
    integrations: filterIntegrations(state.integrations.data, state.integrations.filters),
    filters: state.integrations.filters
})

export default connect(
    mapStateToProps,
    null
)(ConnectionsTable);

ConnectionsTable.propTypes = {
    integrations: PropTypes.array.isRequired,
    filters: PropTypes.shape({
        provider: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
};
