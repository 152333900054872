import React from 'react';
import ModalComponent from '../../ModalComponent/index';
import { Link } from 'react-router-dom';
import { services } from './objectContainer';
import './IBMCatalogue.sass';


class IBMCatalogue extends React.Component {
    state = {
        modal: false
    }

    toggleModalMessage = () => {
        this.setState({modal: !this.state.modal})
    }

    render(){        
        return(
            <div className="bg-white br1 br--top" style={{minHeight:'99vh'}}>
                    {/* <!-- Header part --> */}
                <div className="pa4 bg-navy-near-white br1 br--top">
                    <div className="ph3">
                        <div className="">
                            <p className="f6"><Link to="/connections/catalogue" className="link f6">← Catalogue</Link></p>
                            <div className="flex mt4">
                                <div className="flex-none br1 mr3" style={{width:'64px', height:'64px'}}>
                                    <img className="br1" src="/assets/img/integration-logo-ibm-watson.png" alt="IBM Watson logo"/>
                                </div>
                                <div>
                                    <h2 className="mt0 mb2">IBM Watson integrations</h2>
                                    <p className="mt2 mb0 lh-copy">The open platform to accelerate the adoption of trusted AI.<br/>Learn more in <Link to="/documentation" className="link">Documentation</Link>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                {/* <!-- Content part --> */}
                <div className="pa4 pb5-l">
                    <div>
                        {/* <div className="ph3">
                            <div className="measure-wide" style={{margin: 'auto', textAlign: 'center'}}>
                                <h3 className="mt0 mb2">Manual Integration</h3>
                            </div>
                        </div> */}
                        <div className="ph2 flex flex-wrap" >
                        {
                            services.map( s => {
                                return(
                                    <div key={s.img} className="w-50 w-third-l ph2 flex">
                                        <div className="mt3 pa3 bg-navy-near-white br1 flex flex-column justify-start">
                                            <h4 className="flex mt0 mb2"><img className="v-btm mr2" src={`/assets/img/16icn-ibm-${s.img}.svg`} alt={s.name}/>{s.name}</h4>
                                            <p className="mt0 mb2 o-60 lh-copy" >{s.info}</p>
                                            <Link to={s.link}  className="link mt2" style={{marginTop: 'auto', width: '3em' }}>+ Add</Link>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="ibm-link">
                        <div className="border"></div>
                        <h4 >or add the services automatially</h4>
                        <span onClick={this.toggleModalMessage} className="db tc btn btn-blue pv2 ph3 f5">Log in with IBMid</span>
                        {
                            this.state.modal ? 
                            <ModalComponent>
                                <div className="mw6 center ph2 mb2 mb5-l">
                                    <div className="pa3 pa4-ns pa5-l bg-white br1">
                                        <h1 className="f3 f2-ns mv0">Login with IBM ID</h1>
                                        <p className="mv3 lh-copy">This functionality will be available soon.</p>
                                        <span onClick={this.toggleModalMessage} className="db tc btn btn-blue pv2 ph3 f5">Close</span>
                                    </div>
                                </div>
                            </ModalComponent>
                            : null
                        }             
                    </div>
                </div>
            </div>
        );
    }
}

export default IBMCatalogue;
