import { combineReducers } from 'redux';
import * as c from '../../constants';

const invoices = (state =[], {type, data}) => {
    switch(type){
        case c.GET_INVOICES_SUCCESS:
            return data;
        case c.REQ_DELETE_INVOICE_SUCCESS:
            return state.filter( i => i._id !== data);
        default:
            return state;
    }
}

const isFetching = (state = false, {type}) => {
    switch (type) {
        case c.GET_INVOICES:
        case c.REQ_SAVE_INVOICE:
            return true;
        case c.GET_INVOICES_SUCCESS:
        case c.GET_INVOICES_ERROR:
        case c.REQ_SAVE_INVOICE_SUCCESS:
        case c.REQ_SAVE_INVOICE_ERROR:
            return false;
        default:
            return state;
    }
}

export default combineReducers({invoices, isFetching});

export const findInvoice = (state, id) => {
    if(id && state.length){
        return state.find( invoice => invoice._id === id);
    }
    return {};
}
