import React from 'react';
import { NavLink, Link } from 'react-router-dom';

export const SideNavLink = ({l, children}) => (
    <li>
        <NavLink exact={l.exact} to={l.to} activeClassName="link-menu-active" className="db flex items-center justify-between link-menu white o-60 hover-o-100 pa2 br1">
            <span className="flex items-center"><img style={{maxWidth: '16px'}} src={l.img} className="mr2" alt="" />
            {l.name}</span>
            {l.img_2 ? (<img src={l.img_2} title="Status: ON" alt="ON"/>) : null}
        </NavLink>
        {children ? children : null}
    </li>
);

export const ProfileNavLink = ({l, toggleProfileMenu}) => (
    <li>
        <Link to={l.link} 
        onClick={toggleProfileMenu} 
        className="db link-menu white o-60 hover-o-100 pa2 br2">{l.name}</Link>
    </li>
);

//export const up = (<span className="submenu-indicator">&#9650;</span>);
export const up = (<img src="/assets/img/16icn-collapse-white.svg" alt="Collapse"/>);

//export const down = (<span className="submenu-indicator">&#9660;</span>);
export const down = (<img src="/assets/img/16icn-expand-white.svg" alt="Expand" />);