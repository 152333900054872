import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { spendingOptions } from './objectContainer';
import NotificationBlock from './NotificationBlock';

const Spending = ({handleChange, options}) => (
    <React.Fragment>
         <h2 className="mt0 mb2">Spending notifications</h2>
                <p className="mt2">Receive notifications when your organisation spendings are reacing limits or passing threshold. Specify your limits and thresholds on the <br/><Link to="/settings/usage" className="link">Usage dashboard</Link> page.</p>
                {
                    spendingOptions.map( op => <NotificationBlock key={op.name} 
                        checked={options[op.name]} 
                        name={op.name} label={op.label} 
                        description={op.description} 
                        handleChange={handleChange} />)
                }
                <hr className="ba b--black-05 mv4" />
    </React.Fragment>
)

export default Spending;

Spending.propTypes = {
    options: PropTypes.shape({
        accountLimit: PropTypes.bool.isRequired,
        budgetWarning: PropTypes.bool.isRequired,
        spendingDigest: PropTypes.bool.isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
}