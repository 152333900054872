import * as c from '../constants';
import { combineReducers } from 'redux';

const error = (state = '', {type, data}) => {
  switch(type){
    case c.ERROR:
    case c.REQ_INTEGRATIONS_ERROR:
    case c.REQ_USERS_ERROR:
    case c.REQ_SKILLS_ERROR:
    case c.CREATE_USER_ERROR:
    case c.UPDATE_USER_ERROR:
    case c.DELETE_USERS_ERROR:
    case c.CREATE_INTEGRATION_ERROR:
    case c.UPDATE_INTEGRATION_ERROR:
    case c.DELETE_INTEGRATION_ERROR:
    case c.AUTH_ERROR:
    case c.DELETE_SKILL_ERROR:
    case c.REQ_COMPANY_INFO_ERROR:
    case c.SAVE_NOTIFICATION_SETTINGS_ERROR:
    case c.SET_SKILL_LIMIT_ERROR:
    case c.REQ_COMAPNY_SKILLS_ERROR:
    case c.SET_TOTAL_SKILLS_LIMIT_ERROR:
    case c.SET_SUPPORT_ITEM_LIMIT_ERROR:
    case c.SET_TOTAL_SUPPORT_LIMIT_ERROR:
    case c.UPDATE_COMPANY_INFO_ERROR:
      return data;
    case c.DISMISS_ERROR:
      return '';
    default:
      return state;
  }
}

export default combineReducers({error});