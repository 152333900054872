export const stats = [{
	name: 'Total messages from the Users',
	key: 'apiCalls'
}, {
	name: 'Total conversations',
	key: 'conversations'
}, {
	name: 'Number of unique users',
	key: 'users'
}, {
	name: 'Success rate',
	key: 'successRate'
}];

export const statusOptions = [{
	name: 'Processing',
	value: 'processing'
}, {
	name: 'Completed',
	value: 'completed'
}, {
	name: 'Failed',
	value: 'failed'
}, {
	name: 'Escaped',
	value: 'escaped'
}, {
	name: 'Current',
	value: 'current'
}, {
	name: 'Error',
	value: 'error'
}];

export const logsTable = [{
  name: 'Date & time',
  sortValue: 'byDate',
  key: 'date'
}, {
  name: 'Skill name',
  sortValue: 'byName',
  key: 'name'
}, {
  name: 'User Full Name',
  sortValue: 'byUser',
  key: 'userName'
}, {
	name: 'Messages',
	sortValue: 'byMessages',
	key: 'messages'
}, {
  name: 'API calls',
  sortValue: 'byApiCalls',
  key: 'apiCalls'
}];

export const detailView = [{
  name: 'Date & time',
  key: 'date',
}, {
  name: 'Status',
  key: 'status',
}, {
  name: 'User Full Name',
  key: 'userName',
}, {
  name: 'Skill',
  key: 'skill',
}, {
  name: 'Messages',
  key: 'messages',
},
//  {
//   name: 'Approved by',
//   key: 'approvedBy',
// }, {
//   name: 'Approved at',
//   key: 'approvedAt',
// }
];


export const dummyUsers = [{
    id: "ef640c80-4b5f-48b9-8add-df64ab77adab",
    firstName: 'Petar',
    lastName: 'Petrov',
    requests: 36
}, {
    id: "ef640c80-4b5f-48b9-8add-df64ab77ccab",
    firstName: 'Pavlo',
    lastName: 'Artiushyn',
    requests: 21
}, {
    id: "ll640c80-4b5f-48b9-8add-df64ab77adab",
    firstName: 'Mykyta',
    lastName: 'Bazhenov',
    requests: 43
}, {
    id: "de640c80-4b5f-48b9-8add-df64ab77adab",
    firstName: 'Anton',
    lastName: 'Radionov',
    requests: 55
}, {
    id: "ey640c80-4b5f-48b9-8add-df64ab77adab",
    firstName: 'Andrii',
    lastName: 'Rudchuk',
    requests: 120
}];


//  skill history mock

export const skillHistory = [{
    id: '12345678',
    date: '2018-11-26T15:33:45+0200',
    status: 'Processing',
    userName: 'Andrii Rudchuk',
    skillId: '62345678',
    name: 'Create New User',
    apiCalls: 4,
    approvedBy: 'Mykyta Bazhenov',
    approvedAt: '2018-11-26T15:55:39+0200',
    conversationHistory:[
        {
          id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
          isHala: false,
          date: '2018-11-26T15:58:36.460Z',
          value: [
            'Hello'
          ]
        },
        {
          id: '216103a0-f194-11e8-b252-f3167c6085cc',
          isHala: true,
          date: 1543247915226,
          data: [
            {
              type: 'text',
              value: [
                'How can I help you today?'
              ]
            },
            {
              type: 'text',
              value: 'do you like dancing??',
              quickReply: [
                'Yes i am a guru!',
                'No i am a man!'
              ]
            },
            {
              type: 'video',
              title: 'lets dance',
              value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
            }
          ],
          anim: false
        },
        {
          id: '25617d40-f194-11e8-a610-831f9bf574a2',
          isHala: false,
          date: '2018-11-26T15:58:41.940Z',
          value: [
            'create new user'
          ]
        },
        {
          id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
          isHala: true,
          date: 1543247920747,
          data: [
            {
              type: 'text',
              value: [
                'Smart form - Create new user was executed'
              ]
            },
            {
              type: 'form',
              title: 'Create new user',
              formData: [
                {
                  key: 'username',
                  type: 'text',
                  label: 'User ID in SAP',
                  value: 'some sap id',
                  required: true,
                  description: ''
                },
                {
                  key: 'firstname',
                  type: 'text',
                  label: 'First name',
                  value: 'Petar',
                  required: true,
                  description: ''
                },
                {
                  key: 'lastname',
                  type: 'text',
                  label: 'Last name',
                  value: 'Petrov',
                  required: true,
                  description: ''
                },
                {
                  key: 'company',
                  type: 'text',
                  label: 'Company name',
                  value: 'Hala',
                  required: true,
                  suggestion: {
                    title: 'Most relevant value for this field',
                    options: [
                      'HALA'
                    ]
                  }
                },
                {
                  key: 'e_mail',
                  type: 'text',
                  label: 'E-mail address',
                  value: 'some@email.com',
                  required: true,
                  description: ''
                },
                {
                  key: 'tel1_numbr',
                  type: 'text',
                  label: 'Telephone number',
                  value: '1234567',
                  optimal: true,
                  description: ''
                },
                {
                  key: 'reason',
                  type: 'text',
                  label: 'Provide the business reason for creation new user',
                  value: 'Who needs a reason...',
                  suggestion: {
                    title: 'Most relevant value for this field',
                    options: [
                      'New joiner',
                      'New employee in Finance Department'
                    ]
                  }
                },
                {
                  label: 'I agree with the Terms Of Service.',
                  value: false,
                  key: 'tosAgreement',
                  type: 'checkbox',
                  required: true,
                  description: 'this field should be shown next to the checkbox',
                  validationOptions: {}
                }
              ]
            }
          ],
          anim: false
        }
      ]
    }, {
	id: '12345678',
	date: '2018-11-26T15:45:45+0200',
	status: 'Completed',
	userName: 'Petar Petrov',
	skillId: '62345678',
	name: 'Create New User',
	apiCalls: 3,
	approvedBy: 'Mykyta Bazhenov',
	approvedAt: '2018-11-26T15:55:39+0200',
	conversationHistory:[{
			id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
			isHala: false,
			date: '2018-11-26T15:58:36.460Z',
			value: [
			'Hello'
			]
		},
		{
			id: '216103a0-f194-11e8-b252-f3167c6085cc',
			isHala: true,
			date: 1543247915226,
			data: [
			{
				type: 'text',
				value: [
				'How can I help you today?'
				]
			},
			{
				type: 'text',
				value: 'do you like dancing??',
				quickReply: [
				'Yes i am a guru!',
				'No i am a man!'
				]
			},
			{
				type: 'video',
				title: 'lets dance',
				value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
			}
			],
			anim: false
		},
		{
			id: '25617d40-f194-11e8-a610-831f9bf574a2',
			isHala: false,
			date: '2018-11-26T15:58:41.940Z',
			value: [
			'create new user'
			]
		},
		{
			id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
			isHala: true,
			date: 1543247920747,
			data: [
			{
				type: 'text',
				value: [
				'Smart form - Create new user was executed'
				]
			},
			{
				type: 'form',
				title: 'Create new user',
				formData: [
				{
					key: 'username',
					type: 'text',
					label: 'User ID in SAP',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'firstname',
					type: 'text',
					label: 'First name',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'lastname',
					type: 'text',
					label: 'Last name',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'company',
					type: 'text',
					label: 'Company name',
					value: '',
					required: true,
					suggestion: {
					title: 'Most relevant value for this field',
					options: [
						'HALA'
					]
					}
				},
				{
					key: 'e_mail',
					type: 'text',
					label: 'E-mail address',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'tel1_numbr',
					type: 'text',
					label: 'Telephone number',
					value: '',
					optimal: true,
					description: ''
				},
				{
					key: 'reason',
					type: 'text',
					label: 'Provide the business reason for creation new user',
					value: '',
					suggestion: {
					title: 'Most relevant value for this field',
					options: [
						'New joiner',
						'New employee in Finance Department'
					]
					}
				},
				{
					label: 'I agree with the Terms Of Service.',
					value: false,
					key: 'tosAgreement',
					type: 'checkbox',
					required: true,
					description: 'this field should be shown next to the checkbox',
					validationOptions: {}
				}
				]
			}
			],
			anim: false
		}]
	}, {
	id: '12345678',
	date: '2018-11-26T15:51:45+0200',
	status: 'Failed',
	userName: 'Anton',
	skillId: '62345678',
	name: 'Create New User',
	apiCalls: 5,
	approvedBy: 'Petar Petrov',
	approvedAt: '2018-11-26T15:55:39+0200',
	conversationHistory:[
		{
			id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
			isHala: false,
			date: '2018-11-26T15:58:36.460Z',
			value: [
			'Hello'
			]
		},
		{
			id: '216103a0-f194-11e8-b252-f3167c6085cc',
			isHala: true,
			date: 1543247915226,
			data: [
			{
				type: 'text',
				value: [
				'How can I help you today?'
				]
			},
			{
				type: 'text',
				value: 'do you like dancing??',
				quickReply: [
				'Yes i am a guru!',
				'No i am a man!'
				]
			},
			{
				type: 'video',
				title: 'lets dance',
				value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
			}
			],
			anim: false
		},
		{
			id: '25617d40-f194-11e8-a610-831f9bf574a2',
			isHala: false,
			date: '2018-11-26T15:58:41.940Z',
			value: [
			'create new user'
			]
		},
		{
			id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
			isHala: true,
			date: 1543247920747,
			data: [
			{
				type: 'text',
				value: [
				'Smart form - Create new user was executed'
				]
			},
			{
				type: 'form',
				title: 'Create new user',
				formData: [
				{
					key: 'username',
					type: 'text',
					label: 'User ID in SAP',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'firstname',
					type: 'text',
					label: 'First name',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'lastname',
					type: 'text',
					label: 'Last name',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'company',
					type: 'text',
					label: 'Company name',
					value: '',
					required: true,
					suggestion: {
					title: 'Most relevant value for this field',
					options: [
						'HALA'
					]
					}
				},
				{
					key: 'e_mail',
					type: 'text',
					label: 'E-mail address',
					value: '',
					required: true,
					description: ''
				},
				{
					key: 'tel1_numbr',
					type: 'text',
					label: 'Telephone number',
					value: '',
					optimal: true,
					description: ''
				},
				{
					key: 'reason',
					type: 'text',
					label: 'Provide the business reason for creation new user',
					value: '',
					suggestion: {
					title: 'Most relevant value for this field',
					options: [
						'New joiner',
						'New employee in Finance Department'
					]
					}
				},
				{
					label: 'I agree with the Terms Of Service.',
					value: false,
					key: 'tosAgreement',
					type: 'checkbox',
					required: true,
					description: 'this field should be shown next to the checkbox',
					validationOptions: {}
				}
				]
			}
			],
			anim: false
		}
		]
	}, {
		id: '12345678',
		date: '2018-11-26T15:51:45+0200',
		status: 'Failed',
		userName: 'Anton Radionov',
		skillId: '62345678',
		name: 'Create New User',
		apiCalls: 5,
		approvedBy: 'Mykyta Bazhenov',
		approvedAt: '2018-11-26T15:55:39+0200',
		conversationHistory:[
			{
				id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
				isHala: false,
				date: '2018-11-26T15:58:36.460Z',
				value: [
				'Hello'
				]
			},
			{
				id: '216103a0-f194-11e8-b252-f3167c6085cc',
				isHala: true,
				date: 1543247915226,
				data: [
				{
					type: 'text',
					value: [
					'How can I help you today?'
					]
				},
				{
					type: 'text',
					value: 'do you like dancing??',
					quickReply: [
					'Yes i am a guru!',
					'No i am a man!'
					]
				},
				{
					type: 'video',
					title: 'lets dance',
					value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
				}
				],
				anim: false
			},
			{
				id: '25617d40-f194-11e8-a610-831f9bf574a2',
				isHala: false,
				date: '2018-11-26T15:58:41.940Z',
				value: [
				'create new user'
				]
			},
			{
				id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
				isHala: true,
				date: 1543247920747,
				data: [
				{
					type: 'text',
					value: [
					'Smart form - Create new user was executed'
					]
				},
				{
					type: 'form',
					title: 'Create new user',
					formData: [
					{
						key: 'username',
						type: 'text',
						label: 'User ID in SAP',
						value: '',
						required: true,
						description: ''
					},
					{
						key: 'firstname',
						type: 'text',
						label: 'First name',
						value: '',
						required: true,
						description: ''
					},
					{
						key: 'lastname',
						type: 'text',
						label: 'Last name',
						value: '',
						required: true,
						description: ''
					},
					{
						key: 'company',
						type: 'text',
						label: 'Company name',
						value: '',
						required: true,
						suggestion: {
						title: 'Most relevant value for this field',
						options: [
							'HALA'
						]
						}
					},
					{
						key: 'e_mail',
						type: 'text',
						label: 'E-mail address',
						value: '',
						required: true,
						description: ''
					},
					{
						key: 'tel1_numbr',
						type: 'text',
						label: 'Telephone number',
						value: '',
						optimal: true,
						description: ''
					},
					{
						key: 'reason',
						type: 'text',
						label: 'Provide the business reason for creation new user',
						value: '',
						suggestion: {
						title: 'Most relevant value for this field',
						options: [
							'New joiner',
							'New employee in Finance Department'
						]
						}
					},
					{
						label: 'I agree with the Terms Of Service.',
						value: false,
						key: 'tosAgreement',
						type: 'checkbox',
						required: true,
						description: 'this field should be shown next to the checkbox',
						validationOptions: {}
					}
					]
				}
				],
				anim: false
			}
			]
		}, {
			id: '12345678',
			date: '2018-11-26T15:51:45+0200',
			status: 'Failed',
			userName: 'Andrii Rudchuk',
			skillId: '62345678',
			name: 'Create New User',
			apiCalls: 5,
			approvedBy: 'Mykyta Bazhenov',
			approvedAt: '2018-11-26T15:55:39+0200',
			conversationHistory:[
				{
					id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
					isHala: false,
					date: '2018-11-26T15:58:36.460Z',
					value: [
					'Hello'
					]
				},
				{
					id: '216103a0-f194-11e8-b252-f3167c6085cc',
					isHala: true,
					date: 1543247915226,
					data: [
					{
						type: 'text',
						value: [
						'How can I help you today?'
						]
					},
					{
						type: 'text',
						value: 'do you like dancing??',
						quickReply: [
						'Yes i am a guru!',
						'No i am a man!'
						]
					},
					{
						type: 'video',
						title: 'lets dance',
						value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
					}
					],
					anim: false
				},
				{
					id: '25617d40-f194-11e8-a610-831f9bf574a2',
					isHala: false,
					date: '2018-11-26T15:58:41.940Z',
					value: [
					'create new user'
					]
				},
				{
					id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
					isHala: true,
					date: 1543247920747,
					data: [
					{
						type: 'text',
						value: [
						'Smart form - Create new user was executed'
						]
					},
					{
						type: 'form',
						title: 'Create new user',
						formData: [
						{
							key: 'username',
							type: 'text',
							label: 'User ID in SAP',
							value: '',
							required: true,
							description: ''
						},
						{
							key: 'firstname',
							type: 'text',
							label: 'First name',
							value: '',
							required: true,
							description: ''
						},
						{
							key: 'lastname',
							type: 'text',
							label: 'Last name',
							value: '',
							required: true,
							description: ''
						},
						{
							key: 'company',
							type: 'text',
							label: 'Company name',
							value: '',
							required: true,
							suggestion: {
							title: 'Most relevant value for this field',
							options: [
								'HALA'
							]
							}
						},
						{
							key: 'e_mail',
							type: 'text',
							label: 'E-mail address',
							value: '',
							required: true,
							description: ''
						},
						{
							key: 'tel1_numbr',
							type: 'text',
							label: 'Telephone number',
							value: '',
							optimal: true,
							description: ''
						},
						{
							key: 'reason',
							type: 'text',
							label: 'Provide the business reason for creation new user',
							value: '',
							suggestion: {
							title: 'Most relevant value for this field',
							options: [
								'New joiner',
								'New employee in Finance Department'
							]
							}
						},
						{
							label: 'I agree with the Terms Of Service.',
							value: false,
							key: 'tosAgreement',
							type: 'checkbox',
							required: true,
							description: 'this field should be shown next to the checkbox',
							validationOptions: {}
						}
						]
					}
					],
					anim: false
				}
				]
			}, {
				id: '12345678',
				date: '2018-11-26T15:51:45+0200',
				status: 'Failed',
				userName: 'Mykyta Bazhenov',
				skillId: '62345678',
				name: 'Create New User',
				apiCalls: 5,
				approvedBy: 'Petar Petrov',
				approvedAt: '2018-11-26T15:55:39+0200',
				conversationHistory:[
					{
						id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
						isHala: false,
						date: '2018-11-26T15:58:36.460Z',
						value: [
						'Hello'
						]
					},
					{
						id: '216103a0-f194-11e8-b252-f3167c6085cc',
						isHala: true,
						date: 1543247915226,
						data: [
						{
							type: 'text',
							value: [
							'How can I help you today?'
							]
						},
						{
							type: 'text',
							value: 'do you like dancing??',
							quickReply: [
							'Yes i am a guru!',
							'No i am a man!'
							]
						},
						{
							type: 'video',
							title: 'lets dance',
							value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
						}
						],
						anim: false
					},
					{
						id: '25617d40-f194-11e8-a610-831f9bf574a2',
						isHala: false,
						date: '2018-11-26T15:58:41.940Z',
						value: [
						'create new user'
						]
					},
					{
						id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
						isHala: true,
						date: 1543247920747,
						data: [
						{
							type: 'text',
							value: [
							'Smart form - Create new user was executed'
							]
						},
						{
							type: 'form',
							title: 'Create new user',
							formData: [
							{
								key: 'username',
								type: 'text',
								label: 'User ID in SAP',
								value: '',
								required: true,
								description: ''
							},
							{
								key: 'firstname',
								type: 'text',
								label: 'First name',
								value: '',
								required: true,
								description: ''
							},
							{
								key: 'lastname',
								type: 'text',
								label: 'Last name',
								value: '',
								required: true,
								description: ''
							},
							{
								key: 'company',
								type: 'text',
								label: 'Company name',
								value: '',
								required: true,
								suggestion: {
								title: 'Most relevant value for this field',
								options: [
									'HALA'
								]
								}
							},
							{
								key: 'e_mail',
								type: 'text',
								label: 'E-mail address',
								value: '',
								required: true,
								description: ''
							},
							{
								key: 'tel1_numbr',
								type: 'text',
								label: 'Telephone number',
								value: '',
								optimal: true,
								description: ''
							},
							{
								key: 'reason',
								type: 'text',
								label: 'Provide the business reason for creation new user',
								value: '',
								suggestion: {
								title: 'Most relevant value for this field',
								options: [
									'New joiner',
									'New employee in Finance Department'
								]
								}
							},
							{
								label: 'I agree with the Terms Of Service.',
								value: false,
								key: 'tosAgreement',
								type: 'checkbox',
								required: true,
								description: 'this field should be shown next to the checkbox',
								validationOptions: {}
							}
							]
						}
						],
						anim: false
					}
					]
				}, {
					id: '12345678',
					date: '2018-11-26T15:51:45+0200',
					status: 'Failed',
					userName: 'Anton Radionov',
					skillId: '62345678',
					name: 'Create New User',
					apiCalls: 5,
					approvedBy: 'Andrii Rudchuk',
					approvedAt: '2018-11-26T15:55:39+0200',
					conversationHistory:[
						{
							id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
							isHala: false,
							date: '2018-11-26T15:58:36.460Z',
							value: [
							'Hello'
							]
						},
						{
							id: '216103a0-f194-11e8-b252-f3167c6085cc',
							isHala: true,
							date: 1543247915226,
							data: [
							{
								type: 'text',
								value: [
								'How can I help you today?'
								]
							},
							{
								type: 'text',
								value: 'do you like dancing??',
								quickReply: [
								'Yes i am a guru!',
								'No i am a man!'
								]
							},
							{
								type: 'video',
								title: 'lets dance',
								value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
							}
							],
							anim: false
						},
						{
							id: '25617d40-f194-11e8-a610-831f9bf574a2',
							isHala: false,
							date: '2018-11-26T15:58:41.940Z',
							value: [
							'create new user'
							]
						},
						{
							id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
							isHala: true,
							date: 1543247920747,
							data: [
							{
								type: 'text',
								value: [
								'Smart form - Create new user was executed'
								]
							},
							{
								type: 'form',
								title: 'Create new user',
								formData: [
								{
									key: 'username',
									type: 'text',
									label: 'User ID in SAP',
									value: '',
									required: true,
									description: ''
								},
								{
									key: 'firstname',
									type: 'text',
									label: 'First name',
									value: '',
									required: true,
									description: ''
								},
								{
									key: 'lastname',
									type: 'text',
									label: 'Last name',
									value: '',
									required: true,
									description: ''
								},
								{
									key: 'company',
									type: 'text',
									label: 'Company name',
									value: '',
									required: true,
									suggestion: {
									title: 'Most relevant value for this field',
									options: [
										'HALA'
									]
									}
								},
								{
									key: 'e_mail',
									type: 'text',
									label: 'E-mail address',
									value: '',
									required: true,
									description: ''
								},
								{
									key: 'tel1_numbr',
									type: 'text',
									label: 'Telephone number',
									value: '',
									optimal: true,
									description: ''
								},
								{
									key: 'reason',
									type: 'text',
									label: 'Provide the business reason for creation new user',
									value: '',
									suggestion: {
									title: 'Most relevant value for this field',
									options: [
										'New joiner',
										'New employee in Finance Department'
									]
									}
								},
								{
									label: 'I agree with the Terms Of Service.',
									value: false,
									key: 'tosAgreement',
									type: 'checkbox',
									required: true,
									description: 'this field should be shown next to the checkbox',
									validationOptions: {}
								}
								]
							}
							],
							anim: false
						}
						]
					}, {
						id: '12345678',
						date: '2018-11-26T15:51:45+0200',
						status: 'Failed',
						userName: 'Petar Petrov',
						skillId: '62345678',
						name: 'Create New User',
						apiCalls: 5,
						approvedBy: 'Mykyta Bazhenov',
						approvedAt: '2018-11-26T15:55:39+0200',
						conversationHistory:[
							{
								id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
								isHala: false,
								date: '2018-11-26T15:58:36.460Z',
								value: [
								'Hello'
								]
							},
							{
								id: '216103a0-f194-11e8-b252-f3167c6085cc',
								isHala: true,
								date: 1543247915226,
								data: [
								{
									type: 'text',
									value: [
									'How can I help you today?'
									]
								},
								{
									type: 'text',
									value: 'do you like dancing??',
									quickReply: [
									'Yes i am a guru!',
									'No i am a man!'
									]
								},
								{
									type: 'video',
									title: 'lets dance',
									value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
								}
								],
								anim: false
							},
							{
								id: '25617d40-f194-11e8-a610-831f9bf574a2',
								isHala: false,
								date: '2018-11-26T15:58:41.940Z',
								value: [
								'create new user'
								]
							},
							{
								id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
								isHala: true,
								date: 1543247920747,
								data: [
								{
									type: 'text',
									value: [
									'Smart form - Create new user was executed'
									]
								},
								{
									type: 'form',
									title: 'Create new user',
									formData: [
									{
										key: 'username',
										type: 'text',
										label: 'User ID in SAP',
										value: '',
										required: true,
										description: ''
									},
									{
										key: 'firstname',
										type: 'text',
										label: 'First name',
										value: '',
										required: true,
										description: ''
									},
									{
										key: 'lastname',
										type: 'text',
										label: 'Last name',
										value: '',
										required: true,
										description: ''
									},
									{
										key: 'company',
										type: 'text',
										label: 'Company name',
										value: '',
										required: true,
										suggestion: {
										title: 'Most relevant value for this field',
										options: [
											'HALA'
										]
										}
									},
									{
										key: 'e_mail',
										type: 'text',
										label: 'E-mail address',
										value: '',
										required: true,
										description: ''
									},
									{
										key: 'tel1_numbr',
										type: 'text',
										label: 'Telephone number',
										value: '',
										optimal: true,
										description: ''
									},
									{
										key: 'reason',
										type: 'text',
										label: 'Provide the business reason for creation new user',
										value: '',
										suggestion: {
										title: 'Most relevant value for this field',
										options: [
											'New joiner',
											'New employee in Finance Department'
										]
										}
									},
									{
										label: 'I agree with the Terms Of Service.',
										value: false,
										key: 'tosAgreement',
										type: 'checkbox',
										required: true,
										description: 'this field should be shown next to the checkbox',
										validationOptions: {}
									}
									]
								}
								],
								anim: false
							}
							]
						}, {
							id: '12345678',
							date: '2018-11-26T15:51:45+0200',
							status: 'Failed',
							userName: 'Mykyta Bazhenov',
							skillId: '62345678',
							name: 'Create New User',
							apiCalls: 5,
							approvedBy: 'Andrii Rudchuk',
							approvedAt: '2018-11-26T15:55:39+0200',
							conversationHistory:[
								{
									id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
									isHala: false,
									date: '2018-11-26T15:58:36.460Z',
									value: [
									'Hello'
									]
								},
								{
									id: '216103a0-f194-11e8-b252-f3167c6085cc',
									isHala: true,
									date: 1543247915226,
									data: [
									{
										type: 'text',
										value: [
										'How can I help you today?'
										]
									},
									{
										type: 'text',
										value: 'do you like dancing??',
										quickReply: [
										'Yes i am a guru!',
										'No i am a man!'
										]
									},
									{
										type: 'video',
										title: 'lets dance',
										value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
									}
									],
									anim: false
								},
								{
									id: '25617d40-f194-11e8-a610-831f9bf574a2',
									isHala: false,
									date: '2018-11-26T15:58:41.940Z',
									value: [
									'create new user'
									]
								},
								{
									id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
									isHala: true,
									date: 1543247920747,
									data: [
									{
										type: 'text',
										value: [
										'Smart form - Create new user was executed'
										]
									},
									{
										type: 'form',
										title: 'Create new user',
										formData: [
										{
											key: 'username',
											type: 'text',
											label: 'User ID in SAP',
											value: '',
											required: true,
											description: ''
										},
										{
											key: 'firstname',
											type: 'text',
											label: 'First name',
											value: '',
											required: true,
											description: ''
										},
										{
											key: 'lastname',
											type: 'text',
											label: 'Last name',
											value: '',
											required: true,
											description: ''
										},
										{
											key: 'company',
											type: 'text',
											label: 'Company name',
											value: '',
											required: true,
											suggestion: {
											title: 'Most relevant value for this field',
											options: [
												'HALA'
											]
											}
										},
										{
											key: 'e_mail',
											type: 'text',
											label: 'E-mail address',
											value: '',
											required: true,
											description: ''
										},
										{
											key: 'tel1_numbr',
											type: 'text',
											label: 'Telephone number',
											value: '',
											optimal: true,
											description: ''
										},
										{
											key: 'reason',
											type: 'text',
											label: 'Provide the business reason for creation new user',
											value: '',
											suggestion: {
											title: 'Most relevant value for this field',
											options: [
												'New joiner',
												'New employee in Finance Department'
											]
											}
										},
										{
											label: 'I agree with the Terms Of Service.',
											value: false,
											key: 'tosAgreement',
											type: 'checkbox',
											required: true,
											description: 'this field should be shown next to the checkbox',
											validationOptions: {}
										}
										]
									}
									],
									anim: false
								}
								]
							}, {
								id: '12345678',
								date: '2018-11-26T15:51:45+0200',
								status: 'Failed',
								userName: 'Andrii Rudchuk',
								skillId: '62345678',
								name: 'Create New User',
								apiCalls: 5,
								approvedBy: 'Mykyta Bazhenov',
								approvedAt: '2018-11-26T15:55:39+0200',
								conversationHistory:[
									{
										id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
										isHala: false,
										date: '2018-11-26T15:58:36.460Z',
										value: [
										'Hello'
										]
									},
									{
										id: '216103a0-f194-11e8-b252-f3167c6085cc',
										isHala: true,
										date: 1543247915226,
										data: [
										{
											type: 'text',
											value: [
											'How can I help you today?'
											]
										},
										{
											type: 'text',
											value: 'do you like dancing??',
											quickReply: [
											'Yes i am a guru!',
											'No i am a man!'
											]
										},
										{
											type: 'video',
											title: 'lets dance',
											value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
										}
										],
										anim: false
									},
									{
										id: '25617d40-f194-11e8-a610-831f9bf574a2',
										isHala: false,
										date: '2018-11-26T15:58:41.940Z',
										value: [
										'create new user'
										]
									},
									{
										id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
										isHala: true,
										date: 1543247920747,
										data: [
										{
											type: 'text',
											value: [
											'Smart form - Create new user was executed'
											]
										},
										{
											type: 'form',
											title: 'Create new user',
											formData: [
											{
												key: 'username',
												type: 'text',
												label: 'User ID in SAP',
												value: '',
												required: true,
												description: ''
											},
											{
												key: 'firstname',
												type: 'text',
												label: 'First name',
												value: '',
												required: true,
												description: ''
											},
											{
												key: 'lastname',
												type: 'text',
												label: 'Last name',
												value: '',
												required: true,
												description: ''
											},
											{
												key: 'company',
												type: 'text',
												label: 'Company name',
												value: '',
												required: true,
												suggestion: {
												title: 'Most relevant value for this field',
												options: [
													'HALA'
												]
												}
											},
											{
												key: 'e_mail',
												type: 'text',
												label: 'E-mail address',
												value: '',
												required: true,
												description: ''
											},
											{
												key: 'tel1_numbr',
												type: 'text',
												label: 'Telephone number',
												value: '',
												optimal: true,
												description: ''
											},
											{
												key: 'reason',
												type: 'text',
												label: 'Provide the business reason for creation new user',
												value: '',
												suggestion: {
												title: 'Most relevant value for this field',
												options: [
													'New joiner',
													'New employee in Finance Department'
												]
												}
											},
											{
												label: 'I agree with the Terms Of Service.',
												value: false,
												key: 'tosAgreement',
												type: 'checkbox',
												required: true,
												description: 'this field should be shown next to the checkbox',
												validationOptions: {}
											}
											]
										}
										],
										anim: false
									}
									]
								}, {
									id: '12345678',
									date: '2018-11-26T15:51:45+0200',
									status: 'Failed',
									userName: 'Mykyta Bazhenov',
									skillId: '62345678',
									name: 'Create New User',
									apiCalls: 5,
									approvedBy: 'Petar Petrov',
									approvedAt: '2018-11-26T15:55:39+0200',
									conversationHistory:[
										{
											id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
											isHala: false,
											date: '2018-11-26T15:58:36.460Z',
											value: [
											'Hello'
											]
										},
										{
											id: '216103a0-f194-11e8-b252-f3167c6085cc',
											isHala: true,
											date: 1543247915226,
											data: [
											{
												type: 'text',
												value: [
												'How can I help you today?'
												]
											},
											{
												type: 'text',
												value: 'do you like dancing??',
												quickReply: [
												'Yes i am a guru!',
												'No i am a man!'
												]
											},
											{
												type: 'video',
												title: 'lets dance',
												value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
											}
											],
											anim: false
										},
										{
											id: '25617d40-f194-11e8-a610-831f9bf574a2',
											isHala: false,
											date: '2018-11-26T15:58:41.940Z',
											value: [
											'create new user'
											]
										},
										{
											id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
											isHala: true,
											date: 1543247920747,
											data: [
											{
												type: 'text',
												value: [
												'Smart form - Create new user was executed'
												]
											},
											{
												type: 'form',
												title: 'Create new user',
												formData: [
												{
													key: 'username',
													type: 'text',
													label: 'User ID in SAP',
													value: '',
													required: true,
													description: ''
												},
												{
													key: 'firstname',
													type: 'text',
													label: 'First name',
													value: '',
													required: true,
													description: ''
												},
												{
													key: 'lastname',
													type: 'text',
													label: 'Last name',
													value: '',
													required: true,
													description: ''
												},
												{
													key: 'company',
													type: 'text',
													label: 'Company name',
													value: '',
													required: true,
													suggestion: {
													title: 'Most relevant value for this field',
													options: [
														'HALA'
													]
													}
												},
												{
													key: 'e_mail',
													type: 'text',
													label: 'E-mail address',
													value: '',
													required: true,
													description: ''
												},
												{
													key: 'tel1_numbr',
													type: 'text',
													label: 'Telephone number',
													value: '',
													optimal: true,
													description: ''
												},
												{
													key: 'reason',
													type: 'text',
													label: 'Provide the business reason for creation new user',
													value: '',
													suggestion: {
													title: 'Most relevant value for this field',
													options: [
														'New joiner',
														'New employee in Finance Department'
													]
													}
												},
												{
													label: 'I agree with the Terms Of Service.',
													value: false,
													key: 'tosAgreement',
													type: 'checkbox',
													required: true,
													description: 'this field should be shown next to the checkbox',
													validationOptions: {}
												}
												]
											}
											],
											anim: false
										}
										]
									}, {
										id: '12345678',
										date: '2018-11-26T15:51:45+0200',
										status: 'Failed',
										userName: 'Anton Radionov',
										skillId: '62345678',
										name: 'Create New User',
										apiCalls: 5,
										approvedBy: 'Mykyta Bazhenov',
										approvedAt: '2018-11-26T15:55:39+0200',
										conversationHistory:[
											{
												id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
												isHala: false,
												date: '2018-11-26T15:58:36.460Z',
												value: [
												'Hello'
												]
											},
											{
												id: '216103a0-f194-11e8-b252-f3167c6085cc',
												isHala: true,
												date: 1543247915226,
												data: [
												{
													type: 'text',
													value: [
													'How can I help you today?'
													]
												},
												{
													type: 'text',
													value: 'do you like dancing??',
													quickReply: [
													'Yes i am a guru!',
													'No i am a man!'
													]
												},
												{
													type: 'video',
													title: 'lets dance',
													value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
												}
												],
												anim: false
											},
											{
												id: '25617d40-f194-11e8-a610-831f9bf574a2',
												isHala: false,
												date: '2018-11-26T15:58:41.940Z',
												value: [
												'create new user'
												]
											},
											{
												id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
												isHala: true,
												date: 1543247920747,
												data: [
												{
													type: 'text',
													value: [
													'Smart form - Create new user was executed'
													]
												},
												{
													type: 'form',
													title: 'Create new user',
													formData: [
													{
														key: 'username',
														type: 'text',
														label: 'User ID in SAP',
														value: '',
														required: true,
														description: ''
													},
													{
														key: 'firstname',
														type: 'text',
														label: 'First name',
														value: '',
														required: true,
														description: ''
													},
													{
														key: 'lastname',
														type: 'text',
														label: 'Last name',
														value: '',
														required: true,
														description: ''
													},
													{
														key: 'company',
														type: 'text',
														label: 'Company name',
														value: '',
														required: true,
														suggestion: {
														title: 'Most relevant value for this field',
														options: [
															'HALA'
														]
														}
													},
													{
														key: 'e_mail',
														type: 'text',
														label: 'E-mail address',
														value: '',
														required: true,
														description: ''
													},
													{
														key: 'tel1_numbr',
														type: 'text',
														label: 'Telephone number',
														value: '',
														optimal: true,
														description: ''
													},
													{
														key: 'reason',
														type: 'text',
														label: 'Provide the business reason for creation new user',
														value: '',
														suggestion: {
														title: 'Most relevant value for this field',
														options: [
															'New joiner',
															'New employee in Finance Department'
														]
														}
													},
													{
														label: 'I agree with the Terms Of Service.',
														value: false,
														key: 'tosAgreement',
														type: 'checkbox',
														required: true,
														description: 'this field should be shown next to the checkbox',
														validationOptions: {}
													}
													]
												}
												],
												anim: false
											}
											]
										}, {
											id: '12345678',
											date: '2018-11-26T15:51:45+0200',
											status: 'Failed',
											userName: 'Mykyta Bazhenov',
											skillId: '62345678',
											name: 'Create New User',
											apiCalls: 5,
											approvedBy: 'Anton Radionov',
											approvedAt: '2018-11-26T15:55:39+0200',
											conversationHistory:[
												{
													id: '221d4ec0-f194-11e8-a610-831f9bf574a2',
													isHala: false,
													date: '2018-11-26T15:58:36.460Z',
													value: [
													'Hello'
													]
												},
												{
													id: '216103a0-f194-11e8-b252-f3167c6085cc',
													isHala: true,
													date: 1543247915226,
													data: [
													{
														type: 'text',
														value: [
														'How can I help you today?'
														]
													},
													{
														type: 'text',
														value: 'do you like dancing??',
														quickReply: [
														'Yes i am a guru!',
														'No i am a man!'
														]
													},
													{
														type: 'video',
														title: 'lets dance',
														value: 'https://www.youtube.com/watch?v=9bZkp7q19f0'
													}
													],
													anim: false
												},
												{
													id: '25617d40-f194-11e8-a610-831f9bf574a2',
													isHala: false,
													date: '2018-11-26T15:58:41.940Z',
													value: [
													'create new user'
													]
												},
												{
													id: '24ab73b0-f194-11e8-b252-f3167c6085cc',
													isHala: true,
													date: 1543247920747,
													data: [
													{
														type: 'text',
														value: [
														'Smart form - Create new user was executed'
														]
													},
													{
														type: 'form',
														title: 'Create new user',
														formData: [
														{
															key: 'username',
															type: 'text',
															label: 'User ID in SAP',
															value: '',
															required: true,
															description: ''
														},
														{
															key: 'firstname',
															type: 'text',
															label: 'First name',
															value: '',
															required: true,
															description: ''
														},
														{
															key: 'lastname',
															type: 'text',
															label: 'Last name',
															value: '',
															required: true,
															description: ''
														},
														{
															key: 'company',
															type: 'text',
															label: 'Company name',
															value: '',
															required: true,
															suggestion: {
															title: 'Most relevant value for this field',
															options: [
																'HALA'
															]
															}
														},
														{
															key: 'e_mail',
															type: 'text',
															label: 'E-mail address',
															value: '',
															required: true,
															description: ''
														},
														{
															key: 'tel1_numbr',
															type: 'text',
															label: 'Telephone number',
															value: '',
															optimal: true,
															description: ''
														},
														{
															key: 'reason',
															type: 'text',
															label: 'Provide the business reason for creation new user',
															value: '',
															suggestion: {
															title: 'Most relevant value for this field',
															options: [
																'New joiner',
																'New employee in Finance Department'
															]
															}
														},
														{
															label: 'I agree with the Terms Of Service.',
															value: false,
															key: 'tosAgreement',
															type: 'checkbox',
															required: true,
															description: 'this field should be shown next to the checkbox',
															validationOptions: {}
														}
														]
													}
													],
													anim: false
												}
												]
											}, ];