import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import { Form, Input } from 'antd';

class ConfirmSignUpForm extends ConfirmSignUp {

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) this.confirm(e);
        });
    }

    showComponent(theme) {
        const { hide } = this.props;
        const username = this.usernameFromAuthData();

        if (hide && hide.includes(ConfirmSignUpForm)) { return null; }

        const {
            getFieldDecorator
        } = this.props.form;

        return (
            <div>
                <div className="mw6 center pv3 pv4-ns">
                    <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                        <img className="" src="/assets/img/hala-logo.svg" alt="" />
                    </div>
                </div>
                <div className="mw6 center ph2 mb2 mb5-l">
                    <div className="pa3 pa4-ns pa5-l bg-white br1">
                        <h1 className="f3 f2-ns mv0">Verify your account</h1>
                        <p className="mt2 mb0 lh-copy">Verification code was sent to the <strong>{username}</strong>. Please check your mailbox and enter verification code from the e-mail.</p>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <div>
                                <label htmlFor="code" className="b f6">Verification code:</label>
                                {getFieldDecorator('code', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your Code!'
                                    },{
                                        type: "string",
                                        pattern: /^\d+$/,
                                        message: 'Code in invalid format!'
                                    }],
                                    validateTrigger: "onSubmit",
                                    onChange: this.handleInputChange
                                })(
                                    <Input type="text" className="w-100 mt1 pv2 ph2 f5" autoComplete="new-password" name="code"/>
                                )}
                            </div>
                            <div className="mt3">
                                <input type="submit" value="Confirm" className="w-100 btn btn-blue pv2 ph3 f5" />
                            </div>
                        </Form>
                        <p className="mt3 mb0 lh-copy">Didn't receave the code? <a href="/"
                        onClick={this.resend} className="link">Resend the code</a></p>
                        <p className="ma0 lh-copy">Already have an account? <a href="/"
                        onClick={() => this.changeState('signIn')} className="link">Log in now</a></p>
                    </div>
                </div>
            </div>
        )
    }
}

const WrappedConfirmSignUpForm = Form.create()(ConfirmSignUpForm);
export default WrappedConfirmSignUpForm;
