import React from 'react';
import { Link} from 'react-router-dom';

const NotFound = () => (
    <div className="sans-serif bg-navy navy" style={{position: 'absolute', top:0, bottom: 0, left: 0, right: 0}}>
        <div className="mw6 center pv3 pv4-ns">
            <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                <img src="/assets/img/hala-logo.svg" alt="" />
            </div>
        </div>
        <div className="ph2 mb2 mb5-l">
            <div className="pa3 pa4-ns pa5-l white aa tc">
                <div className="center measure">
                    <h1 className="error-404 f-headline white fw2 lh-solid mv0">404</h1>
                    <p className="mt4">We are sorry but the page you are looking for was not found.<br/>Go to <Link to="/" className="link link-white">Hala Dashboard</Link> or <Link to="/contacts" className="link link-white">contact us</Link>.</p>
                </div>
            </div>
        </div>
    </div>
);

export default NotFound;
