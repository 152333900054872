import {
    ANALYTICS_FILTER_BY_DATE,
    ANALYTICS_FILTER_BY_USER,
    ANALYTICS_FILTER_BY_SKILL,
    ANALYTICS_FILTER_BY_STATUS,
    ANALYTICS_FILTER_BY_TECHNICAL_USERS
} from '../../constants';
import moment from 'moment';

const init = {
    users: [],
    status: [],
    skills: [],
    from: moment().startOf('month').toISOString(), 
    to: moment().endOf('day').toISOString(),
    technicalUsers: false
};

const filters = (state = init, {type, payload}) => {
    switch (type) { 
        case ANALYTICS_FILTER_BY_TECHNICAL_USERS:
            return {
                ...state,
                technicalUsers: payload
            }; 
        case ANALYTICS_FILTER_BY_STATUS:
            return {
                ...state,
                status: payload
            };
        case ANALYTICS_FILTER_BY_SKILL:
            return {
                ...state,
                skills: payload
            };
        case ANALYTICS_FILTER_BY_USER:
            return {
                ...state,
                users: payload
            };
        case ANALYTICS_FILTER_BY_DATE:
            return {
                ...state,
                from: payload.from, 
                to: payload.to
            };
        default:
            return state;
    }
};

export default filters;