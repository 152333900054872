import React, { useCallback } from 'react';
import { filterByTechnicalUsers } from '../../../actions/analytics';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import './index.sass';

function TechnicalUsersFilter({
    onChange, checked, isTechnicalUser
}) {
    const _onChange = useCallback(e => onChange(e.target.checked), [onChange]);

    if (!isTechnicalUser) {
        return null;
    }

    return (
        <Checkbox className="tech-users-filter" checked={checked} onChange={_onChange}> Show Technical Users</Checkbox>
    )
}

TechnicalUsersFilter.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    isTechnicalUser: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        checked: state.analytics.filters.technicalUsers,
        isTechnicalUser: state.auth.user.roles.indexOf('TechnicalUser') !== -1
    }), 
    {
        onChange: filterByTechnicalUsers
    }
)(TechnicalUsersFilter);