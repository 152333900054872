import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import 'antd/lib/skeleton/style/index.css'

const SmallBlock = ({name, value, fetching}) => {
    const isRate = name === 'Success rate';
    return (
        <div className="w-50 w-25-l mb3 mb0-l ph2">
            <div className="ph3 pv4 bg-navy-near-white br1 tc">
                <Skeleton loading={fetching} active  paragraph={false}>
                    <p className="mt0 mb3 f6 fw5 o-60 lh-title">{name}</p>
                    <p className={isRate ? "f2 mt3 mb1 green" : "f2 mt3 mb1"}>{value + (isRate ? " %" : "")}</p>
                </Skeleton> 
            </div>
        </div>
    );
};

export default SmallBlock;

SmallBlock.propTyepes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    fetching: PropTypes.bool.isRequired,
}