import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom';
import { fetchUsers, setUserFilter, deselectAllUsers } from '../actions/users';
import UserPage from '../components/Users/UserPage';
import UsersList from '../components/Users/UsersList';

class UserManagment extends Component {

    componentDidMount(){
        this.props.fetchUsers();
    }

    componentWillUnmount(){
        const { setUserFilter, deselectAllUsers, selectedUsers } = this.props;
        setUserFilter('');
        deselectAllUsers(selectedUsers);
    }

    render() {
        const { match } = this.props;
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path={`${match.url}/addUser`} component={ UserPage } />
                    <Route path={`${match.url}/:id`} component={ UserPage } />
                    <Route exact path={`${match.url}/`} component={ UsersList } />
                </Switch>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    selectedUsers: state.users.selectedUsers
})

export default connect(mapStateToProps, {fetchUsers, setUserFilter, deselectAllUsers})(UserManagment);