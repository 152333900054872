import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

const  EmptyTable = ({mssg}) => (
    <table className="ph3 table w-100 tl">
        <tbody>
            <tr className="tc">
                <td colSpan="4">
                    <div className="pv5">
                        <img src="/assets/img/visuals-empty-integrations.png" style={{maxHeight:'64px'}} alt=""/>
                        <h3 className="mt0 mv2">{mssg}</h3>
                        {/* <p className="mt2 mb0">Browse <Link to="/connections/catalogue" className="link">catalogue</Link> to get started.</p> */}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
);

export default EmptyTable;

EmptyTable.propTypes = {
    mssg: PropTypes.string.isRequired,
}
