import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import { hasAccess } from '../../../containers/HasRoles';
import { roundPrice } from '../../../utils'; 

class InvoiceItem extends Component {

    constructor(){
        super();
        this.state = {
            showMenu: false
        }
    }

    getPDF = (id) => {
        const { invoice: { invoiceId } } = this.props;

        Storage.get(id)
        .then(result => {
            this.downloadPDF(`Hala-invoice-${invoiceId}.pdf`, result)
        })
        .catch(err => console.log(err));
    }

    downloadPDF = (filename, url) => {
        var element = document.createElement('a');
        element.setAttribute('download', filename);
        element.setAttribute('href', url);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    

    clickBody = () => {
        this.removeBodyListener();
        this.clickBodyTimeout = setTimeout(() => {
            this.setState({
                showMenu: false
            });
        }, 100);
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
        document.body.addEventListener("click", this.clickBody);
    }

    removeBodyListener = () => {
        if (this.state.showMenu) {
            document.body.removeEventListener("click", this.clickBody);
        }
    }

    componentWillUnmount(){
        document.body.removeEventListener("click", this.clickBody);
        clearTimeout(this.clickBodyTimeout);
    }

    chooseColor = (status) => {
        switch (status) {
            case 'Paid':
                return 'green';
            case 'Overdue':
                return 'red';
            case 'pending':
                return 'grey';
            default:
                break;
        }
    }

    renderMenu() {
        const { userRoles, invoice, confirmDeleteInvoice } = this.props;

        return (
            <div className={`options-menu-dd br1 shadow-4`} style={{position: 'absolute', right: 0, top: 0}}>
                <ul className="list pa1 ma0" style={{cursor: "pointer"}}>
                    <li>
                        <span className="db pointer o-60 hover-o-100 pa2 br2"
                        onClick={() => this.getPDF(invoice._id)}>
                            Download
                        </span>
                    </li>
                    {
                        hasAccess(['SuperAdmin'], userRoles, () => 
                            <li>
                                <Link to={`/settings/billing/${invoice._id}`} className="db o-60 hover-o-100 pa2 br2">
                                    Edit
                                </Link>
                            </li>
                        )
                    }
                    {
                        hasAccess(['SuperAdmin'], userRoles, () => 
                            <li>
                                <span onClick={() => confirmDeleteInvoice(invoice)} className="db o-60 hover-o-100 pa2 br2">Delete</span>
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }

    render(){
        const { invoice: { invoiceId, issueDate, dueDate, total, status } } = this.props;
        return (
            <tr>
                <td>{invoiceId}</td>
                <td>{moment(issueDate).format('LL')}</td>
                <td>{moment(dueDate).format('LL')}</td>
                <td>€{roundPrice(total)}</td>
                <td><span style={{color: this.chooseColor(status)}}>{status}</span></td>
                <td><div style={{position: 'relative'}}>
                        <img className="mr3 o-60 hover-o-100 pointer"
                            src="/assets/img/24icn-options.svg"
                            onClick={() => this.toggleMenu()}
                            alt="" />
                            {
                                this.state.showMenu 
                                ? this.renderMenu()  
                                : null
                            }
                    </div>
                </td>
            </tr>
        );
    }
};

InvoiceItem.propTypes = {
    userRoles: PropTypes.array.isRequired,  
    invoice: PropTypes.object.isRequired,
    confirmDeleteInvoice: PropTypes.func.isRequired,
}

export default InvoiceItem;