import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const areas = {
    'FI': {
        name: 'Finance',
        src: '/assets/img/16icn-account.svg'
    }, 
    'MM' : {
        name: 'Material Managment',
        src: '/assets/img/16icn-account.svg'
    },
    'SD': {
        name: 'Sales and Distribution',
        src: '/assets/img/16icn-user.svg'
    },
    'CO': {
        name: 'Controlling',
        src: '/assets/img/16icn-user.svg'
    },
    'BS': {
        name: 'Basis',
        src: '/assets/img/16icn-user.svg'
    },
    'General': {
        name: 'General',
        src: '/assets/img/16icn-account.svg'
    }
}

const SkillStoreBlock = ({skill}) => {
 
    // should be replaced with dynamically checking variable 
    const skillIsInstalled = false;

    return(
        <div className="w-50 w-third-l ph2">
            <div className="mt3 pa3 bg-navy-near-white br1 f6">
                <div className="flex items-center justify-between">
                    <div>
                    <img style={{marginRight: '0.4rem'}} className="v-btm" src={areas[skill.area].src} alt="" />
                    <span className="f6 fw5">{areas[skill.area].name}</span>
                    </div>
                    { skillIsInstalled ? <span className="f6 fw5 green">Installed</span> : null }
                </div>
                {/* <img className="v-btm pr1" src={areas[skill.area].src} alt=""/>
                <span className="f6 fw5">{areas[skill.area].name}</span>
                <img className="fr child" src="/assets/img/16icn-arrow-right.svg" alt=""/> */}
                <h4 className="mt2 mb2">{skill.name}</h4>
                <div className="flex items-center justify-between f6 fw5">
                    <p className="mv0 o-60">Service provider:</p>
                    <p className="mv0">{skill.serviceProvider}</p>
                </div>
                <div className="flex items-center justify-between f6 fw5">
                    <p className="mv0 o-60">Domain knowledge:</p>
                    <p className="mv0">{skill.domainKnowledge}</p>
                </div>
                <p className="mv0">
                    <Link className="link f6 fw5 mr3" to={`/skills/${skill._id}`}>Details</Link>
                    {/* { skillIsInstalled ? null : <span className="link f6 fw5">Install skill</span> } */}
                </p>
            </div>
        </div>
    )
}

export default SkillStoreBlock;

SkillStoreBlock.propTypes = {
    skill: PropTypes.object.isRequired,
}
