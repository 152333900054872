import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Profile from '../components/Settings/Profiles/Profile';
import Company from '../components/Settings/Profiles/Company';
import Notifications from '../components/Settings/Notifications/index';
import UsageDashboard from '../components/Settings/UsageDashboard/index';
import Billing from '../components/Settings/Billing/index';
import { fetchCompanyInfo } from '../actions/settings';
import { getInvoices } from '../actions/billing';
import HasRoles from './HasRoles';
import InvoiceForm from '../components/Settings/Billing/InvoiceForm';


class Settings extends Component {

	componentDidMount(){
		this.props.fetchCompanyInfo();
		this.props.getInvoices();
	}
	
	render() {
		const { match } = this.props;
		return (
			<Switch>
				<Route path={`${match.url}/notifications`}  component={HasRoles(['SuperAdmin'], Notifications)} />
				<Route path={`${match.url}/usage/:sortFilter?/:sortOption?`}  component={ UsageDashboard } />
				<Route exact path={`${match.url}/billing`}  component={ Billing } />
				<Route path={`${match.url}/billing/addNew`}  component={ InvoiceForm } />
				<Route path={`${match.url}/billing/:id`}  component={ InvoiceForm } />
				<Route path={`${match.url}/company`}  component={ Company } />
				<Route exact path={`${match.url}/`}  component={ Profile } />
			</Switch>
		)
	}
}

const mapStateToProps = (state) => ({
	
})

const mapDispatchToProps = {
	fetchCompanyInfo,
	getInvoices
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)

Settings.propTypes = {
	fetchCompanyInfo: PropTypes.func.isRequired,
}