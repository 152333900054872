import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetUpdateLimit } from '../../../actions/popups';


const errorMssg = (<div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">The input must be a number bigger than or equal to 0.</div>);


class UsageLimit extends Component {

    state = {
        value: '',
        error: false,
        loader: false
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({value, error: false});
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { value } = this.state;
        await this.setState({loader: true});
        if( isNaN(value) || value < 0){
            this.setState({error: true, loader: false});
        } else{
            await this.props.ok(+value);
            this.setState({loader: false, value: '', error: false});
            this.props.resetUpdateLimit();
        }
    }

    setName = () => {
        const { type } = this.props;
        let name = '';
        switch(type){
            case 'skill':
                name = this.props.updateObject.name;
                break;
            case 'totalSkills':
                name = 'Total Skills Limit';
                break;
            case 'support':
                name = this.props.updateObject.name;
                break;
            case 'totalSupport':
                name = 'Total Skills Limit';
                break;
            default:
                name =  '';
                break;
        }

        return name;
    }

    render(){
        const { value, error } = this.state;
        const { show, resetUpdateLimit } = this.props;
        return show ? (
            <div className="z-max fixed w-100 vh-100 bg-black-90 pa3" style={{top:0, left:0}}>
                <div className="mw6 center pv3 pv4 ph2 mb2 mb5-l">
                    <div className="pa3 pa4-ns pa5-l bg-white br1">
                        <h1 className="f3 f2-ns mt0 mb2 lh-title">Setup budget limit</h1>
                        <p className="mt2">Specify your limit for total cost of <span className="b blue-purple">{this.setName()}</span> skill. Also you can turn on email notification to be notified when 80% threshold is reached.</p>
                        <form onSubmit={this.handleSubmit} className="mt3">
                            <div className="">
                                <label htmlFor="" className="b f6">Maximum total cost in €:</label>
                                <input id="" type="text" name="" className="w-100 mt1 pv2 ph2 f5" value={value} onChange={this.handleChange}/>
                                { error ? <div>{errorMssg}</div> : null}
                            </div>
                            <div className="mt3">
                                <input type="submit" value="Confirm" className="dib btn btn-blue pv2 ph3 f5 mr2" />
                                <input type="button" value="Cancel" onClick={() => resetUpdateLimit()} className="dib btn btn-ghost pv2 ph3 f5" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        ) : null
    }
}

const mapStateToProps = state => ({
    updateObject: state.popups.updateLimits.data,
    type: state.popups.updateLimits.type,
    ok: state.popups.updateLimits.ok,
    show: state.popups.updateLimits.show
})

export default connect(mapStateToProps, { resetUpdateLimit })(UsageLimit);

UsageLimit.propTypes = {
    updateObject: PropTypes.object,
    type: PropTypes.string.isRequired,
    ok: PropTypes.func,
    show: PropTypes.bool.isRequired,
    resetUpdateLimit: PropTypes.func.isRequired,
    limit: PropTypes.number,
}