import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PopupMenu from './PopupMenu';
import { areas } from './objectContainer'


class SkillManagementBlock extends Component {

    state = {
        showMenu: false
    }

    clickBody = () => {
        this.removeBodyListener();
        this.clickBodyTimeout = setTimeout(() => {
            this.setState({
                showMenu: false
            });
        }, 100);
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
        document.body.addEventListener("click", this.clickBody);
    }

    removeBodyListener = () => {
        if (this.state.showMenu) {
            document.body.removeEventListener("click", this.clickBody);
        }
    }
    render(){
        const { skill } = this.props;
        // testing purpose , to be replaced when the real skill object is ready
        skill.status = 'Active';
        const { showMenu } = this.state;
        return(
            <div className="w-50 w-third-l ph2">
                <div className="mt3 pa3 bg-navy-near-white br1">
                    <div className="flex justify-between">
                        <div>
                            <img className="v-btm pr1" src={areas[skill.area].src} alt=""/>
                            <span className="f6 fw5">{areas[skill.area].name}</span>
                            <h4 className="mt2 mb2">{skill.name}</h4>
                        </div>
                        <div className="flex-none">
                            <div className="relative">
                                <img className="ml2 o-60 hover-o-100 pointer"
                                src="/assets/img/24icn-options.svg"
                                onClick={this.toggleMenu} alt="" />
                                {
                                    showMenu ? <PopupMenu status={skill.status} /> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mb2">
                        <div className="flex items-center justify-between f6 fw5">
                            <p className="mv0 o-60">Skill status:</p>
                            <p className={`mv0 ${skill.status === 'Active' ? 'green' : ''}`}>{skill.status}</p>
                        </div>
                        <div className="flex items-center justify-between f6 fw5">
                            <p className="mv0 o-60">Service provider:</p>
                            <p className="mv0">{skill.serviceProvider}</p>
                        </div>
                        <div className="flex items-center justify-between f6 fw5">
                            <p className="mv0 o-60">Domain knowledge:</p>
                            <p className="mv0">{skill.domainKnowledge}</p>
                        </div>
                    </div>
                    <p className="mv0">
                        <Link className="link f6 fw5 mr3"  to={`/skills/${skill.id}`}>Details</Link>
                        <span className="link f6 fw5">Configurations</span>
                    </p>
                </div>
          </div>
        )
    }
}

export default SkillManagementBlock;

SkillManagementBlock.propTypes = {
    skill: PropTypes.object.isRequired,
}
