import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { filterSkills } from '../../reducers/skills';
import FilterSkills from './FilterSkills';
import SkillManagementBlock from './SkillManagementBlock';
import { resetFilters } from '../../actions/skills';
import Amplify from '@aws-amplify/core';
const log = new Amplify.Logger('Skills Store::');

class SkillManagement extends Component {

    state = {
        modal: false
    }

    toggleModalMessage = () => {
        this.setState({modal: !this.state.modal})
    }

    componentWillMount(){
        this.props.resetFilters();
    }

    render() {
        log.debug('render');
        const { filteredSkills } = this.props;

        return (
            <div className="bg-white br1 br--top" style={{minHeight:'99vh', minWidth: '700px'}}>

                {/* <!-- Header part --> */}
                <div className="pa4 bg-navy-near-white br1 br--top">
                    <div className="ph3">
                        <div className="">
                            <div className="flex items-center">
                                <img className="section-icon" src="/assets/img/24icn-builder-black.svg"  alt="Hala App"/>
                                <h2 className="mb2">Skills management</h2>
                            </div>
                            <p className="mt2 mb0 lh-copy">Hala has pre-built digital skills that work with enterprise software. You do not need to spend time on training NLP and ML models. All you need is to make integration and activate the skills.<br/>
                            <br/>Learn more in <Link className="link" to="/documentaion">Documentaion</Link>.</p>
                        </div>
                    </div>
                </div>

                {/* <!-- Content part --> */}
                <div className="pa4 pb5-l">
                    <FilterSkills />
                    <div className="ph2 flex flex-wrap">
                        {
                            filterSkills.length > 0 ?
                                filteredSkills.map( skill => {
                                    return <SkillManagementBlock key={skill.id} skill={skill} />
                                })
                            : null
                        }
                    </div>

                </div>

                {/* {
                    this.state.modal ? 
                    <ModalComponent>
                        <div className="mw6 center ph2 mb2 mb5-l">
                            <div className="pa3 pa4-ns pa5-l bg-white br1">
                                <h1 className="f3 f2-ns mv0">Download All Skills</h1>
                                <p className="mv3 lh-copy">This functionality will be available soon.</p>
                                <span onClick={this.toggleModalMessage} className="db tc btn btn-blue pv2 ph3 f5">Close</span>
                            </div>
                        </div>
                    </ModalComponent>
                    : null
                }              */}

            </div>
        );
    }
}

const mapStateToProps = state => ({
        filteredSkills: filterSkills(state.skills.data, state.skills.filters),
        isFetching: state.skills.fetching,
})

export default connect(mapStateToProps, { resetFilters })(SkillManagement);

SkillManagement.propTypes = {
    filteredSkills: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
}
