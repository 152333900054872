import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    setUserFilter,
    confirmDeleteUsers
} from '../../actions/users';
import { usersToDelete } from '../../reducers/users';

export class UserFilter extends Component {

    handleSearch = e => {
        const searchedValue = e.target.value;
        this.props.setUserFilter(searchedValue);
    }

    render() {
        const { selectedDelUsers, filter, confirmDeleteUsers } = this.props;
        
        return (
            <div className="ph3 flex justify-between mb3">
                <form action="" className="flex">
                    <input id="company" type="text" name="" placeholder="Search..." value={filter} onChange={this.handleSearch} className="w-100 pv1 ph2 f5" />
                </form>
                <div className="">
                    <button style={{display: selectedDelUsers.length ? 'inline-block' : 'none'}} onClick={() => confirmDeleteUsers(selectedDelUsers)}
                    type="button" className="btn btn-ghost-red pv1 ph2 f5 mr2">Delete</button>
                    <button type="button" className="btn btn-blue pv1 ph2 f5"><Link className="no-link" to="/users/newUser">New user</Link></button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filter: state.users.filter,
    selectedDelUsers: usersToDelete(state.users.data, state.users.selectedUsers)
})

const mapDispatchToProps = {
    setUserFilter,
    confirmDeleteUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFilter)

UserFilter.propTypes = {
    filter: PropTypes.string.isRequired,
    selectedDelUsers: PropTypes.array.isRequired,
    setUserFilter: PropTypes.func.isRequired,
    confirmDeleteUsers:PropTypes.func.isRequired,
}