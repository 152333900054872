import React from 'react';
import PageHead from '../components/PageHead';

const Contacts = () => {
    return (
        <div className="bg-white br1 br--top" style={{minHeight:'100%'}}>
          <PageHead title="Contact us" />
          <div className="pa4 pb5-l">
            <div className="ph2">

              <div className="ph2">
                <div className=" lh-copy">
                  <h3 className="mt0 mb2">Technical support</h3>
                  <p className="mt2">Technical support is provided for the Service during the Agreements term and is not available as a separate offering. There are two channels to contact Customer Support:</p>
                </div>
              </div>
              <div className="flex  lh-copy">
                <div className="w-50 ph2">
                  <div className="mt2 pa3 bg-navy-near-white br1">
                    <h4 className="flex mt0 mb2"><img className="v-btm mr2" src="/assets/img/16icn-email.svg" alt=""/>Email access</h4>
                    <p className="mt0 mb2"><a className="link" href="mailto:support@hala.ai">support@hala.ai</a></p>
                    <p className="mt0 mb2"><a className="link" href="mailto:rudchuk@hala.ai">rudchuk@hala.ai</a></p>
                    <p className="mt0 mb2"><a className="link" href="mailto:mykyta.bazhenov@hala.ai">mykyta.bazhenov@hala.ai</a></p>
                  </div>
                </div>
                <div className="w-50 ph2">
                  <div className="mt2 pa3 bg-navy-near-white br1">
                    <h4 className="flex mt0 mb2"><img className="v-btm mr2" src="/assets/img/16icn-phone.svg" alt=""/>Voice access</h4>
                    <p className="mt0 mb2"><a className="link" href="tel:+37259823235">+372 59823235</a></p>
                    <p className="mt0 mb2"><a className="link" href="tel:+37258529042">+372 58529042</a></p>
                  </div>
                </div>
              </div>
              <div className="ph2">
                <div className=" lh-copy">
                  <p>Please ensure the service representative understands the business impact (Severity) of your issue.</p>
                  <p>* We provide only English language Support.</p>
                  <hr className="ba b--black-05 mv4"/>
                  <h3 className="mb2">Hours</h3>
                  <p className="mt2"><strong>Support Hours:</strong> Hala Support Center hours cover the prevailing business hours in the country where your product is licensed and/or your contract is registered.</p>
                  <p><strong>Off shift support:</strong> off shift hours are defined as all other hours outside of normal country business hours.&nbsp;During Off shift hours, we will use commercially reasonable efforts to respond by telephone within two hours to customer critical problems (severity 1).&nbsp;Off shift support will be provided in English.</p>
                  <hr className="ba b--black-05 mv4"/>
                  <h3 className="mb2">Language</h3>
                  <p className="mt2">Support language is English.</p>
                  <hr className="ba b--black-05 mv4"/>
                  <h3 className="mb2">Severity</h3>
                  <p className="mt2">The following table defines severity levels and provides some examples:</p>
                </div>
                <table className="table w-100 tl">
                  <tbody><tr className="bg-navy-near-white">
                    <th className="w-5">Severity</th>
                    <th className="w-55">Severity Definition</th>
                    <th className="w-20">Response Time Objectives</th>
                    <th className="w-20">Response Time Coverage</th>
                  </tr>
                  <tr>
                    <td className="v-top"><p className="mv0">1</p></td>
                    <td className="v-top"><p className="mv0"><strong>Critical business impact/service down:</strong> Business critical functionality is inoperable or critical interface has failed. This usually applies to a production environment and indicates an inability to access services resulting in a critical impact on operations. This condition requires an immediate solution.</p></td>
                    <td className="v-top"><p className="mv0">Within 1 hour</p></td>
                    <td className="v-top"><p className="mv0">24/7</p></td>
                  </tr>
                  <tr>
                    <td className="v-top"><p className="mv0">2</p></td>
                    <td className="v-top"><p className="mv0"><strong>Significant business impact:</strong> A service business feature or function of the service is severely restricted in its use or you are in jeopardy of missing business deadlines.</p></td>
                    <td className="v-top"><p className="mv0">Within 2 business hours</p></td>
                    <td className="v-top"><p className="mv0">M-F business hours</p></td>
                  </tr>
                  <tr>
                    <td className="v-top"><p className="mv0">3</p></td>
                    <td className="v-top"><p className="mv0"><strong>Minor business impact::</strong> A service business feature or function of the service is severely restricted in its use or you are in jeopardy of missing business deadlines.</p></td>
                    <td className="v-top"><p className="mv0">Within 4 business hours</p></td>
                    <td className="v-top"><p className="mv0">M-F business hours</p></td>
                  </tr>
                  <tr>
                    <td className="v-top"><p className="mv0">4</p></td>
                    <td className="v-top"><p className="mv0"><strong>Minimal business impact:</strong> An inquiry or non-technical request</p></td>
                    <td className="v-top"><p className="mv0">Within 1 business day</p></td>
                    <td className="v-top"><p className="mv0">M-F business hours</p></td>
                  </tr>
                </tbody></table>
              </div>

            </div>
          </div>


        </div>
    );
};

export default Contacts;