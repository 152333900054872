export const filterOptions = {
    providers: {
        defOption: 'providers',
        action: 'setSkillSearchProvider',
        data: [{
            key: 'SAP',
            name: 'SAP'
        }, {
            key: 'Hala',
            name: 'Hala'
        }]
    },
    areas: {
        defOption: 'areas',
        action: 'setSkillSearchArea',
        data: [{
            key: 'FI',
            name:'Finance'
        }, {
            key: 'MM',
            name: 'Material Managment'
        },{
            key: 'SD',
            name: 'Sales and Distribution'
        },{
            key: 'CO',
            name: 'Controlling'
        },{
            key: 'BS',
            name: 'Basis'
        },{
            key: 'General',
            name: 'General'
        }]
    },
    domainKnowledges: {
        defOption: 'domain knowladge',
        action: 'setSkillSearchDomainKnowledge',
        data: [{
            key: 'ERP',
            name:'Enterprise Resource Planning (ERP)'
        },{
            key: 'Communication',
            name: 'Communication tools'
        }]
    }
};

export const areas = {
    'FI': {
        name: 'Finance',
        src: '/assets/img/16icn-account.svg'
    }, 
    'MM' : {
        name: 'Material Managment',
        src: '/assets/img/16icn-account.svg'
    },
    'SD': {
        name: 'Sales and Distribution',
        src: '/assets/img/16icn-user.svg'
    },
    'CO': {
        name: 'Controlling',
        src: '/assets/img/16icn-user.svg'
    },
    'BS': {
        name: 'Basis',
        src: '/assets/img/16icn-user.svg'
    },
    'General': {
        name: 'General',
        src: '/assets/img/16icn-account.svg'}
}
