import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const TextInput = ({object, item, getFieldDecorator, errorMessage}) => (
    <div key={item.key} className={"mt3 " + item.key}>
        <label htmlFor="" className="b f6">{item.name}:</label>
        {!item.required ? <span className="fr f6 o-60">Optional</span> : null}
        {
            getFieldDecorator(item.key, {
                rules: item.validationRules,
                initialValue: object[item.key] || '',
                validateTrigger: "onChange",
                onChange: getFieldDecorator.handleInputChange
            })(<Input prefixCls="h"   type="text" className="w-100 mt1 pv2 ph2 f5" />)
        }
        {errorMessage(item.key)}
    </div>
);

export default TextInput;

TextInput.propTypes = {
    object: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    errorMessage: PropTypes.func.isRequired,
}