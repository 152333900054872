import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Form, Select, Input} from 'antd';
import PulseLoader from '../PulseLoader/index';
import { saveUser, selectUser, confirmDeleteUsers } from '../../actions/users';
import { findUser } from '../../reducers/users';
import SubmitButton from '../SubmitButton/index';
import 'antd/lib/select/style/index.css';
import { formFields } from './objectContainer';
import './UserPage.sass';
import PageHead from '../PageHead';
const { Option } = Select;

class UserPage extends Component {

    goBack = () => {
        this.props.history.goBack();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll } } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                if(this.props.user){
                    values.id = this.props.user.id;
                }
                this.props.actions.saveUser(values);
            } else{
                console.log("errors", err, 'values', values);
            }
        });
    }

    errorMessage = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors){
            if(errors.length > 0) {
                return (
                    <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
                )
            } else {
                return null;
            }
        }
    }

    render() {
        const { 
            isSuperAdmin,
            form: { getFieldDecorator }, isFetching, formLoaders, actions: { confirmDeleteUsers } } = this.props;
        const submitButtonValue = this.props.user && this.props.user.id ? 'Save changes' : 'Create new user';
        let user = this.props.user || {};

        if(isFetching){
            return (
                <div className="pa4 bg-navy-near-white br1 br--top" style={{minHeight: '100vh'}}>
                        <div className="ph3"><PulseLoader className="table-loader bg-navy-near-white"/></div>
                </div>
            )
        }
        return (
            <div  style={{minHeight: '99vh'}}>
                {
                    <PageHead 
                        title={user.id? "Edit user" : "Create new user"}
                        goBack={() => this.goBack()}
                    />
                }
                <div className="pa4 pb5-l">
                    <div className="ph3">
                        <Form onSubmit={this.handleSubmit}
                        className="measure-wide mt3">
                            { formFields.map( item =>{
                                return(
                                    <div key={item.key} className="mt3">
                                        <label htmlFor="" className="b f6">{item.name}:</label>
                                        {
                                            getFieldDecorator(item.key, {
                                                rules: item.validationRules,
                                                initialValue: user[item.key],
                                                validateTrigger: "onChange",
                                                onChange: this.handleInputChange
                                            })(<Input prefixCls="h"  type="text" className="w-100 mt1 pv2 ph2 f5" />)
                                        }
                                        {this.errorMessage(item.key)}
                                    </div>
                                );
                            })
                            }
                            <div className="mt3">
                                <label htmlFor="select" className="b f6">Role:</label>
                                    {
                                        getFieldDecorator('roles', {
                                            validateTrigger: "onChange",
                                            onChange: this.handleInputChange,
                                            initialValue: user.roles || ['User'],
                                            rules: [{
                                                required: true, 
                                                message: 'The Roles field is required!'
                                            }]
                                        })(<Select mode="multiple"
                                           name="" id="">
                                            { isSuperAdmin ? <Option value="SuperAdmin">SuperAdmin</Option> : null}
                                            <Option value="Admin">Admin</Option>
                                            <Option value="User">User</Option>
                                        </Select>)
                                    }
                                    {this.errorMessage('roles')}
                            </div>
                            <div className="flex justify-between items-center mt3">
                                <div>
                                    <SubmitButton value={submitButtonValue} loader={formLoaders.create} />
                                    <button type="button" className="dib btn btn-ghost pv2 ph3 f5 ml2"  onClick={this.goBack} >Cancel</button>
                                </div>
                                {
                                    user.id ?
                                        (<div>
                                            <span style={{cursor: 'pointer'}} onClick={() => confirmDeleteUsers([user])} className="link link-red">Delete user</span>
                                        </div>) : null
                                }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, {match}) => ({
    user: findUser(state.users.data, match.params.id),
    processing: state.processing,
    isFetching: state.users.fetching,
    formLoaders: state.forms.users,
    isSuperAdmin: state.auth.isSuperAdmin
})


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        saveUser,
        selectUser,
        confirmDeleteUsers
    }, dispatch)
})


UserPage.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    formLoaders: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(UserPage));