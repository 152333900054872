import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom';
import { fetchSkills, resetFilters } from '../actions/skills';
import SkillPage from '../components/SkillsStore/SkillPage';
import SkillForm from '../components/SkillsBuilder/SkillForm';
import SkillsStore from '../components/SkillsStore/SkillStore';
import SkillManagement from '../components/SkillsStore/SkillManagement';
import { hasAccess } from './HasRoles';

class Skills extends Component {

    componentDidMount(){
        this.props.fetchSkills();
    }

    componentWillUnmount(){
        this.props.resetFilters();
    }

    render() {
        const { match, userRoles } = this.props;
        return (
            <Switch>
                <Route exact path={`${match.url}/`} component={ SkillsStore } />
                <Route exact path={`${match.url}/management`} component={ hasAccess(['SuperAdmin'], userRoles, () => SkillManagement) } />
                <Route path={`${match.url}/management/addNew`} component={ hasAccess(['SuperAdmin'], userRoles, () => SkillForm) } />
                <Route path={`${match.url}/management/:id`} component={ hasAccess(['SuperAdmin'], userRoles, () => SkillForm) } />
                <Route exact path={`${match.url}/:id`} component={ SkillPage } />
            </Switch>
        )
    }
}

export default connect(
    state => ({
        userRoles: state.auth.user.roles
    })
    , {fetchSkills, resetFilters})(Skills);