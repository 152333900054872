export const skillColumns = [{
    name: 'Skill name',
    id: 'byName'
}, {
    name: 'Cost per request',
    id: 'byCost'
}, {
    name: 'Total requests',
    id: 'byRequest'
}, {
    name: 'Total cost',
    id: 'byTotalCost'
},
//  {
//     name: 'Cost limit',
//     id: 'byLimit'
// }
];