import React from 'react';
import PropTypes from 'prop-types';
// import close from '../../../assets/img/24icn-close.svg';

const ModalPicture = ({link, description, closeModalView}) => (
    <div className="z-max absolute w-100 vh-100 bg-navy-near-white pa3" style={{top: 0, left: 0}}>
        <span className="o-60 hover-o-100 fr" style={{cursor: 'pointer'}}>
            <img src="assets/img/24icn-close.svg"
                onClick={closeModalView} alt="Close" />
        </span>
        <img className="ma4 db br1 center" style={{maxWidth:'100%', maxHeight:'calc(100% - 64px)'}} src={link} alt={description ? description : ''} />
    </div>
);

export default ModalPicture;

ModalPicture.propTypes = {
    link: PropTypes.string.isRequired,
    closeModalView: PropTypes.func,
    description: PropTypes.string,
}
