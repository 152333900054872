import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setIntegrationSearchText,
    setIntegrationSearchStatus,
    setIntegrationSearchCategory,
    setIntegrationSearchProvider
} from '../../actions/integrations';
import { filterOptions } from './objectContainer';

const FilterSection = ({ 
    filters: { search, provider, category, status},
    actions: {setIntegrationSearchProvider, setIntegrationSearchCategory, setIntegrationSearchStatus, setIntegrationSearchText} 
}) => (
    <form action="" className="flex mr2 mb2">
        <input id="company" name="textSearch" type="text" value={search} onChange={e => setIntegrationSearchText(e.target.value)} placeholder="Search..." className="w-100 pv1 ph2 f5" />
        <div className="ml2 w-100">
            <select id="select" name="provider" value={provider} onChange={e => setIntegrationSearchProvider(e.target.value)} className="input-reset pv1 pl2 pr4 f5 w-100">
                <option value="">All service providers</option>
                {
                    filterOptions.provider.map( (option,i) => <option key={`op-${i}`} value={option}>{option}</option>)
                }
            </select>
        </div>
        <div className="ml2 w-100">
            <select id="select" name="category" value={category} onChange={e => setIntegrationSearchCategory(e.target.value)} className="input-reset pv1 pl2 pr4 f5 w-100">
                <option value="">All categories</option>
                {
                    filterOptions.category.map( (op,i) => <option key={op.key} value={op.key}>{op.name}</option>)
                }
            </select>
        </div>
        <div className="ml2 w-100">
            <select id="select" name="status" value={status} onChange={e => setIntegrationSearchStatus(e.target.value)} className="input-reset pv1 pl2 pr4 f5 w-100">
                <option value="">All statuses</option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
            </select>
        </div>
    </form>
);

const mapStateToProps = state => ({
    filters: state.integrations.filters
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        setIntegrationSearchText,
        setIntegrationSearchStatus,
        setIntegrationSearchProvider,
        setIntegrationSearchCategory
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);

FilterSection.propTypes = {
    filters: PropTypes.shape({
        search: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        category:PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
    actions: PropTypes.shape({
        setIntegrationSearchText: PropTypes.func.isRequired,
        setIntegrationSearchStatus: PropTypes.func.isRequired,
        setIntegrationSearchProvider: PropTypes.func.isRequired,
        setIntegrationSearchCategory: PropTypes.func.isRequired,
    }).isRequired,
}
