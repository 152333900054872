import { Component } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import PropTypes from 'prop-types';
import { dismissError } from '../actions/errors';
import './Notifications.css';

const processMsg = (msg) => {
    switch(msg){
        case 'PreAuthentication failed with error Access denied!.':
            return 'You have been denied access to the portal!';
        case 'Network Error':
            return 'You are not authorized to perform this action!';
        default:
            return msg;
    }
}

class Notifications extends Component {

    shouldComponentUpdate(nextProps) {
        if (nextProps.error) {
            notification.open({
                message: processMsg(nextProps.error),
                className: 'sans-serif',
                duration: 5
            });
            this.props.dismissError();
        }
        return false;
    }

    render() {
        return null;
    }
}

Notifications.propTypes = {
    error: PropTypes.string.isRequired,
    dismissError: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    error: state.notifications.error
})

export default connect(
    mapStateToProps, 
    { dismissError }
)(Notifications);