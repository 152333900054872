import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
// import Chart from './Chart';
import PictureComponent from './PictureComponent';
import VideoComponent from './VideoComponent';
import FormResult from './FormResult';
import TextComponent from './TextComponent';
import MessageLogo from './MessageLogo';
import MessageSpinner from './MessageSpinner';
import classNames from'classnames';
import './Message.sass';
// import FilePicker from './FilePicker';
import AvailableSkills from './AvailableSkills';
import SmartTable from '../SmartTable';
import S3ImageViewer from './S3ImageViewer';

function charAt(str) {
    return str.charAt(0).toUpperCase();
}

function _logo(msg) {
    if (msg.isHala) return null;
    return `${charAt(msg.user.firstName)}${charAt(msg.user.lastName)}`;
}

class Message extends Component {

    writeMessage = () => {
        const { message } = this.props;
        if(message) {
            if (message.loading) 
                return [<MessageSpinner key={message.id}/>];
            return message.data
            .filter(el => !el.hide)
            .map((d,i) => {
                if(d.type === 'text') {
                    return (<TextComponent key={`${message._id}-${i}`} message={message} dataId={i} />);
                } else if(d.type === 'picture') {
                    return (<PictureComponent key={`${message._id}-${i}`} message={d} />);
                } else if(d.type === 'video') {
                    return (<VideoComponent key={`${message._id}-${i}`} message={d} />);
                } else if(d.type === 'formResult') {
                    return <FormResult key={`${message._id}-${i}`} message={d} dataId={i} />;
                } else if(d.type === 'chart') {
                    return null //(<Chart key={`${message._id}-${i}`} data={d.value} />);
                } else if (d.type === 'filePicker' && message.isHala === false) {
                    return <S3ImageViewer key={`${message._id}-${i}`} data={d} />
                } else if (d.type === 'availableSkills') {
                    return (<AvailableSkills key={`${message._id}-${i}`} data={d.data} />)
                } else if (d.type === 'table') {
                    return <SmartTable key={`${message._id}-${i}`} data={d} />
                } else return null;
            })
        } else {
            return <TextComponent key={message.id} message={message}/>
        }
    }

    render() {
        const { message, user, msgRef } = this.props;
        const msg = this.writeMessage();
        let _user = {};
        if(message && message.author){
            _user = message.author;
        } else {
            _user.firstName = user.name;
            _user.lastName = user.family_name;
        }

        if (message.isHala && !msg.length) return null;

        const logo = _logo(message);

        return (
            <div ref={msgRef} 
            className={classNames('message', {anim: message.anim})}>
                <div className="message-content">
                    <MessageLogo isHala={message.isHala} logo={logo}/>
                    <div className={`pa2${message.isHala ? '' : ' bg-navy-near-white'}`}>
                        <h4 className="mt1 mb2">{ message.isHala ? 'Hala' : message.user.firstName }</h4>
                        { 
                            message.date
                            ? (<span className="message-date">{moment(message.date).format("H:mm")}</span>) 
                            : null
                        }
                        { msg }
                    </div>
                </div>
            </div>
        );
    }

}

Message.propTypes = {
    message: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(
    mapStateToProps,
    null
)(Message);