import React from 'react';
import './Dashboard.sass';

const diagramText = [
	'User make registration at Hala Portal.',
	'The administrator can add other users that will have access to the Hala Chat.',
	'In the Integration module, the administrator can add connections to the backend software like SAP and connections to IBM Watson Service, if the user wants to use own content and conversation. Otherwise, admin can leave integration with IBM Watson blank and leverage pre-defined Hala Digital Skills without the needs to set up IBM account.',
	'In Skill Store available the Digital Skills, this pre-trained processes built on top of IBM Watson Services. Admin can download interested skills.',
	'Admins need to install downloaded skills into the backend software, for example in SAP.',
	'In the section “Hala app” you can get the link to the Hala communication interface.',
	'Start communication with Hala.'
];

const Dashboard = () => (
	<div className="bg-white br1 br--top" style={{ minHeight: '100%' }}>
		{/* <!-- Header part --> */}
		<div className="pa4 bg-navy-near-white br1 br--top">
			<div className="ph3">
				<div className="flex items-center">
					<img className="section-icon" src="/assets/img/24icn-dashboard-black.svg" alt="Hala App" />
					<h2 className="mb2">Getting started tutorial</h2>
				</div>
				<p className="mt2 mb0">In this short tutorial, we introduce the Hala Client Portal and go through the process of reviewing the portal.</p>
			</div>
		</div>

		{/* <!-- Content part --> */}
		<div className="pa4 pb5-l">
			<div className="ph2">
				<div className="ph2">
					<div className="lh-copy">
						<h3 className="mt0 mb2">How it works</h3>
						<p className="mt2">This diagram shows the overall process flow:</p>
						<img className="mv3" src="/assets/img/test.png" alt="" />
						<ol>
							{
								diagramText.map((item, i) => <li key={i}>{item}</li>)
							}
						</ol>
					</div>
				</div>

			</div>
		</div>
	</div >
);
export default Dashboard;