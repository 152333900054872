import React from 'react';
import { Link } from 'react-router-dom';
import PageHead from '../../PageHead';
// import CompanyInfo from './CompanyInfo';
import Invoices from './Invoices';

const Billing = () => (
    <div style={{minHeight: '99vh'}}>
        <PageHead title="Billing" />
        <div className="pa4 pb5-l">
            <div className="ph3">
                {/* <CompanyInfo /> */}
                <Invoices />
                <div className="measure-wide">
                    <hr className="ba b--black-05 mv4" />
                    <h2 className="mt0 mb2">Suspend organisation</h2>
                    <p className="mt2">You can disable all paid features by temporary suspending your organisation. You can activate your account any time.</p>
                    <p><Link to="/contacts" className="dib btn btn-ghost pv2 ph3 f5 link">Contact us</Link></p>
                </div>
            </div>
        </div>
    </div>
);

export default Billing;
