import React, { useCallback } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { filterByStatus} from '../../../actions/analytics';

const Option = Select.Option;

function filterByInput(input, option) {
    return option.key.indexOf(input.toLowerCase()) !== -1;
}

const data = [
    'Completed',
    'Processing',
    'Failed',
    'Escaped',
    'Error',
].map(val => {
    return {
        key: val.toLowerCase(),
        val
    };
});

function StatusFilter({isFetching, values, filterByStatus}) {

    const onChange = useCallback(val => {
        if (isFetching) return false;
        filterByStatus(val);
    }, [isFetching]);

    return (
        <Select
            mode="multiple"
            placeholder="Filter by status"
            value={values}
            onChange={onChange}
            filterOption={filterByInput}
        >
            {
                data.map(el=> (
                    <Option key={el.key} >{el.val}</Option>
                ))
            }
        </Select>
    )
}

StatusFilter.propTypes = {
    values: PropTypes.array.isRequired,
    filterByStatus: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        values: state.analytics.filters.status,
        isFetching: state.analytics.logs.isFetching
    }), {
        filterByStatus
    }
)(StatusFilter);