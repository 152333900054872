import * as c from '../constants';
import { updateLimit } from './popups';
import * as api from '../services/api';
import { error } from './errors';

// Update Notifications

export const saveNotificationSettings = () => ({
    type: c.SAVE_NOTIFICATION_SETTINGS
})

export const saveNotificationSettingsSuccess = data => ({
    type: c.SAVE_NOTIFICATION_SETTINGS_SUCCESS,
    data
})

export const saveNotificationSettingsError = data => ({
    type: c.SAVE_NOTIFICATION_SETTINGS_ERROR,
    data
})

export const saveNotifications = (data) => (
    async dispatch => {
        dispatch(saveNotificationSettings());
        try{
            await api.saveNotifications(data);
            dispatch(saveNotificationSettingsSuccess(data));
        }  catch (err){
            dispatch(error(err.message));
            dispatch(saveNotificationSettingsError(err.message));
        }
    }
)

// Fetch comany info

const reqCompanyInfo = () => ({
    type: c.REQ_COMPANY_INFO
})

const reqCompanyInfoSuccess = (data) => ({
    type: c.REQ_COMPANY_INFO_SUCCESS,
    data,
    date: new Date().toISOString()
})

const reqCompanyInfoError = data => ({
    type: c.REQ_COMPANY_INFO_ERROR,
    data
})

export const fetchCompanyInfo = () => (
    async dispatch => {
        dispatch(reqCompanyInfo());
        try{
            const data = await api.getCompanySettings();
            dispatch(reqCompanyInfoSuccess(data));
        }  catch (err){
            dispatch(error(err.message));
            dispatch(reqCompanyInfoError(err.message));
        }
    }
)

// update company profile information

const reqUpdateCompanyInfo = () => ({
    type: c.UPDATE_COMPANY_INFO
}) 

const reqUpdateCompanyInfoSuccess = data => ({
    type: c.UPDATE_COMPANY_INFO_SUCCESS,
    data
})

const reqUpdateCompanyInfoError = data => ({
    type: c.UPDATE_COMPANY_INFO_ERROR,
    data
})

export const updateCompanyInfo = data => (
    async dispatch => {
        dispatch(reqUpdateCompanyInfo());
        try{
            await api.updateCompany(data);
            dispatch(reqUpdateCompanyInfoSuccess(data));
        } catch(err){
            dispatch(error(err.message));
            dispatch(reqUpdateCompanyInfoError(err.message));
        }
    }
)  


// Fetch company skills - NB no backend implemented yet

const reqCompanySkillsObject = () => ({
    type: c.REQ_COMAPNY_SKILLS
})

const reqCompanySkillsObjectSuccess = (data) => ({
    type: c.REQ_COMAPNY_SKILLS_SUCCESS,
    data,
    date: new Date().toISOString()
})

const reqCompanySkillsObjectError = data => ({
    type: c.REQ_COMAPNY_SKILLS_ERROR,
    data
})

export const fetchreqCompanySkillsObject = () => (
    async dispatch => {
        dispatch(reqCompanySkillsObject());
        try{
            const settings = await api.getCompanySkills();
            dispatch(reqCompanySkillsObjectSuccess(settings));
        }  catch (err){
            dispatch(error(err.message));
            dispatch(reqCompanySkillsObjectError(err.message));
        }
    }
)
// set skill limit

const set_skill_limit = () => ({
    type: c.SET_SKILL_LIMIT
})

const set_skill_limit_success = data => ({
    type: c.SET_SKILL_LIMIT_SUCCESS,
    data
})
const set_skill_limit_error = data => ({
    type: c.SET_SKILL_LIMIT_ERROR,
    data
})

export const setSkillLimit = (skill, limit) => (
    async dispatch => {
        dispatch(set_skill_limit());
        try{
            const updatedSkill = await api.setSkillLimit(skill, limit);
            dispatch(set_skill_limit_success(updatedSkill));
        }  catch (err){
            dispatch(error(err.message));
            dispatch(set_skill_limit_error(err.message));
        }
    }
)

export const updateSkillLimit = (skill) => {
    return updateLimit(skill, 'skill', setSkillLimit)
}

// set total skills limit

const set_total_skills_limit = () => ({
    type: c.SET_TOTAL_SKILLS_LIMIT
})

const set_total_skills_limit_success = data => ({
    type: c.SET_TOTAL_SKILLS_LIMIT_SUCCESS,
    data
})
const set_total_skills_limit_error = data => ({
    type: c.SET_TOTAL_SKILLS_LIMIT_ERROR,
    data
})

export const setTotalSkillsLimit = (limit) => (
    async dispatch => {
        dispatch(set_total_skills_limit());
        try{
            const updatedSkillsLimit = await api.setTotalSkillsLimit(limit);
            dispatch(set_total_skills_limit_success(updatedSkillsLimit));
        }  catch (err){
            dispatch(error(err.message));
            dispatch(set_total_skills_limit_error(err.message));
        }
    }
)

export const updateTotalSkillsLimit = () => {
    return updateLimit(null,  'totalSkills', setTotalSkillsLimit)
}


// set support item limit

const set_support_item_limit = () => ({
    type: c.SET_SUPPORT_ITEM_LIMIT
})

const set_support_item_limit_success = data => ({
    type: c.SET_SUPPORT_ITEM_LIMIT_SUCCESS,
    data
})
const set_support_item_limit_error = data => ({
    type: c.SET_SUPPORT_ITEM_LIMIT_ERROR,
    data
})

export const setSupportItemLimit = (item, limit) => (
    async dispatch => {
        dispatch(set_support_item_limit());
        try{
            const updatedSkill = await api.setSupportItemLimit(item, limit);
            dispatch(set_support_item_limit_success(updatedSkill));
        }  catch (err){
            dispatch(error(err.message));
            dispatch(set_support_item_limit_error(err.message));
        }
    }
)

export const updateSupportItemLimit = (item) => {
    return updateLimit(item, 'support', setSupportItemLimit)
}

// set total support limit

const set_total_support_limit = () => ({
    type: c.SET_TOTAL_SUPPORT_LIMIT
})

const set_total_support_limit_success = data => ({
    type: c.SET_TOTAL_SUPPORT_LIMIT_SUCCESS,
    data
})
const set_total_support_limit_error = data => ({
    type: c.SET_TOTAL_SUPPORT_LIMIT_ERROR,
    data
})

export const setTotalSupportLimit = (limit) => (
    async dispatch => {
        
        dispatch(set_total_support_limit());
        try{
            const updatedSupportLimit = await api.setTotalSupportLimit(limit);
            dispatch(set_total_support_limit_success(updatedSupportLimit));
        }  catch (err){
            dispatch(error(err.message));
            dispatch(set_total_support_limit_error(err.message));
        }
    }
)

export const updateTotalSupportLimit = () => {
    return updateLimit(null,  'totalSupport', setTotalSupportLimit)
}

// Update companyContacts 

export const getCompanyContactsOptions = (value) => (
    async dispatch => {
        const params = {
            stringValue: value
        }
        const data = await api.getUsers(params);
        dispatch({
            type: c.COMPANY_CONTACTS_OPTIONS,
            data
        })
    }
)