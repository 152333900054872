import { combineReducers } from 'redux';
import {
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    DELETE_USERS,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_ERROR,
    RESET_FORM_STATE,
    CREATE_INTEGRATION,
    CREATE_INTEGRATION_ERROR,
    CREATE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION,
    UPDATE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION_ERROR,
    DELETE_INTEGRATION,
    DELETE_INTEGRATION_SUCCESS,
    DELETE_INTEGRATION_ERROR,
    CREATE_SKILL,
    CREATE_SKILL_ERROR,
    CREATE_SKILL_SUCCESS,
    UPDATE_SKILL,
    UPDATE_SKILL_SUCCESS,
    UPDATE_SKILL_ERROR,
    DELETE_SKILL,
    DELETE_SKILL_SUCCESS,
    DELETE_SKILL_ERROR
} from '../constants'




const usersInitialState = {
    redirect: false,
    create: false,
    delete: false
};

const users = (state = usersInitialState, {type}) => {
    switch(type){
        case CREATE_USER:
        case UPDATE_USER:
            return {...state,
                create: true};
        case DELETE_USERS:
            return {...state,
                delete: true};
        case CREATE_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
            return {...usersInitialState,
                redirect: true};
        case DELETE_USERS_SUCCESS:
        case CREATE_USER_ERROR:
        case UPDATE_USER_ERROR:
        case DELETE_USERS_ERROR:
        case RESET_FORM_STATE:
            return integrationsInitialState;
        default:
            return state;
    }
}

const integrationsInitialState = {
    redirect: false,
    create: false,
    delete: false
};

const integrations = (state = integrationsInitialState, {type}) => {
    switch(type){
        case CREATE_INTEGRATION:
        case UPDATE_INTEGRATION:
            return {...state,
                create: true};
        case DELETE_INTEGRATION:
            return {...state,
                delete: true};
        case CREATE_INTEGRATION_SUCCESS:
        case UPDATE_INTEGRATION_SUCCESS:
            return {...usersInitialState,
                redirect: true};
        case DELETE_INTEGRATION_SUCCESS:
        case CREATE_INTEGRATION_ERROR:
        case UPDATE_INTEGRATION_ERROR:
        case DELETE_INTEGRATION_ERROR:
        case RESET_FORM_STATE:
            return integrationsInitialState;
        default:
            return state;
    }
}

const skillsInitialState = {
    redirect: false,
    create: false,
    delete: false
};

const skills = (state = skillsInitialState, {type}) => {
    switch(type){
        case CREATE_SKILL:
        case UPDATE_SKILL:
            return {...state,
                create: true};
        case DELETE_SKILL:
            return {...state,
                delete: true};
        case CREATE_SKILL_SUCCESS:
        case UPDATE_SKILL_SUCCESS:
            return {...usersInitialState,
                redirect: true};
        case DELETE_SKILL_SUCCESS:
        case CREATE_SKILL_ERROR:
        case UPDATE_SKILL_ERROR:
        case DELETE_SKILL_ERROR:
        case RESET_FORM_STATE:
            return skillsInitialState;
        default:
            return state;
    }
}

export default combineReducers({users, integrations, skills});
