import React, { Component } from 'react';
import { Form, Input, DatePicker } from 'antd';
import { connect } from 'react-redux';
import 'antd/lib/date-picker/style/index.css';
import SubmitButton from '../../SubmitButton';
import { saveInvoice } from '../../../actions/billing';
import { findInvoice } from '../../../reducers/settings/billing';
import moment from 'moment';
import './invoiceForm.css';

const formFields = [{
    key: 'invoiceId',
    name: 'Invoice ID',
    type: 'text'
},{
    key: 'company',
    name: 'Company ID',
    type: 'text'
},{
    key: 'issueDate',
    name: 'Issue date',
    type: 'date'
}, {
    key: 'dueDate',
    name: 'Due date',
    type: 'date'
}, {
    key: 'total',
    name: 'Total',
    type: 'text'
}, {
    key: 'status',
    name: 'Status',
    type: 'select',
    options: ['Pending', 'Paid']
}];

class InvoiceForm extends Component {

    componentDidUpdate(prevProps){
        prevProps.invoice !== this.props.invoice && this.forceUpdate();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll } } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                if(this.props.invoice && this.props.invoice._id){
                    values._id = this.props.invoice._id;
                }
                values.issueDate = moment(values.issueDate).toISOString();
                values.dueDate = moment(values.dueDate).toISOString();
                this.props.saveInvoice(values);
            } else{
                console.log("errors", err, 'values', values);
            }
        });
    }

    errorMessage = (field) => {
        let errors = this.props.form.getFieldError(field);
        return errors && errors.length && (<div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>);
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { form: { getFieldDecorator } } =  this.props;       
        const invoice = this.props.invoice || {};
        const submitButtonValue = invoice && invoice._id ? 'Update invoice' : 'Add invoice';
        return (
            <div  style={{minHeight: '99vh'}}>
                <div className="pa4 bg-navy-near-white br1 br--top">
                    <div className="ph3">
                        <h2>Create Invoice</h2>
                    </div>
                </div>
                <div className="pa4 pb5-l">
                    <div className="ph3">
                        <Form className="mt3 measure-wide" onSubmit={this.handleSubmit}>
                            { 
                                formFields.map( item =>{
                                    switch(item.type){
                                        case 'text':
                                        return(
                                            <div key={item.key} className="mt3">
                                                <label htmlFor="" className="b f6">{item.name}:</label>
                                                {
                                                    getFieldDecorator(item.key, {
                                                        rules: item.validationRules || [],
                                                        initialValue: invoice[item.key],
                                                        validateTrigger: "onChange",
                                                        onChange: this.handleInputChange
                                                    })(<Input prefixCls="h"  type="text" className="w-100 mt1 pv2 ph2 f5" />)
                                                }
                                                {this.errorMessage(item.key)}
                                            </div>
                                        );
                                        case 'date':
                                        return(
                                            <div key={item.key} className="dib mt3 w-50">
                                                <p><label htmlFor="" className="b f6">{item.name}:</label></p>
                                                {
                                                    getFieldDecorator(item.key, {
                                                        rules: item.validationRules || [],
                                                        initialValue: moment(new Date()),
                                                        validateTrigger: "onChange",
                                                        onChange: this.handleInputChange
                                                    })(<DatePicker onChange={this.handleDateChange}/>)
                                                }
                                                {this.errorMessage(item.key)}
                                            </div>
                                        );
                                        case 'select':
                                        return (
                                            <div key={item.key} className="mt3">
                                                <label htmlFor="select" className="b f6">Role:</label>
                                                    {
                                                        getFieldDecorator(item.key, {
                                                            validateTrigger: "onChange",
                                                            onChange: this.handleInputChange,
                                                            initialValue: invoice[item.key] || 'Pending',
                                                            rules: []
                                                        })(<select className="w-100 input-reset mt1 pv2 pl2 pr4 f5"
                                                        name="" id="">
                                                            { item.options.map( (o, i) => <option key={`${o}-${i}`} value={o}>{o}</option>) }
                                                        </select>
                                                        )
                                                    }
                                                    {this.errorMessage(item.key)}
                                            </div>
                                        )
                                        default:
                                            return null;
                                    }
                                })
                            }
                            <div className="flex justify-between items-center w-100 mt3">
                                <div className="mt2">
                                    <SubmitButton value={submitButtonValue} loader={false} />
                                    <button type="button" className="dib btn btn-ghost pv2 ph3 f5 ml2"  onClick={this.goBack} >Cancel</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}
 
const mapStateToProps = (state, {match: { params }}) => ({
    invoice: findInvoice(state.settings.billing.invoices, params.id)
})

const mapDispatchToProps = {
    saveInvoice
}


export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoiceForm));
