import { combineReducers } from 'redux';
import {
    REQ_INTEGRATIONS,
    REQ_INTEGRATIONS_SUCCESS,
    SET_INTEGRATION_SEARCH,
    SET_INTEGRATION_PROVIDER,
    SET_INTEGRATION_CATEGORY,
    SET_INTEGRATION_STATUS,
    RESET_INTEGRATION_FILTER,
    SELECT_INTEGRATIONS,
    CREATE_INTEGRATION,
    CREATE_INTEGRATION_SUCCESS,
    CREATE_INTEGRATION_ERROR,
    UPDATE_INTEGRATION,
    UPDATE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION_ERROR,
    DELETE_INTEGRATION,
    DELETE_INTEGRATION_ERROR,
    DELETE_INTEGRATION_SUCCESS
} from '../constants';

const data = (state = [], {type, data}) => {
    switch (type) {
        case REQ_INTEGRATIONS_SUCCESS:
        return [...data];
        case CREATE_INTEGRATION_SUCCESS:
            return [...state, data];
        case UPDATE_INTEGRATION_SUCCESS:
            return state.map( int => {
                // if (int.id === data.id ) {


                //     return {

                //     }

                // } else return int


                return int.id === data.id ? {
                    ...int,
                    ...data
                } : int;
            })
        case DELETE_INTEGRATION_SUCCESS:
            return state.filter(int => {
                return int.id !== data.id;
            });
        default:
            return state;
    }
}

const fetching = (state = false, {type}) => {
    switch (type) {
        case REQ_INTEGRATIONS:
        case CREATE_INTEGRATION:
        case UPDATE_INTEGRATION:
        case DELETE_INTEGRATION:
            return true;
        case REQ_INTEGRATIONS_SUCCESS:
        case CREATE_INTEGRATION_SUCCESS:
        case CREATE_INTEGRATION_ERROR:
        case UPDATE_INTEGRATION_SUCCESS:
        case UPDATE_INTEGRATION_ERROR:
        case DELETE_INTEGRATION_SUCCESS:
        case DELETE_INTEGRATION_ERROR:
            return false;
        default:
            return state;
    }
}


const filtersInitialState = {
    search: '',
    provider: '',
    category: '',
    status: ''
};
const filters = (state = filtersInitialState, {type, value}) => {
    switch (type) {
        case SET_INTEGRATION_SEARCH:
            return {...state,
            search: value
        };
        case SET_INTEGRATION_PROVIDER:
            return {...state,
            provider: value
        };
        case SET_INTEGRATION_CATEGORY:
            return {...state,
            category: value
        };
        case SET_INTEGRATION_STATUS:
            return {...state,
                status: value
            }
        case RESET_INTEGRATION_FILTER:
            return filtersInitialState;
        default:
            return state;
    }
}

const selectedIntegrations = (state = [], { type, data }) => {
    switch (type) {
        case SELECT_INTEGRATIONS:
            return [
                ...data
            ]
        case DELETE_INTEGRATION_SUCCESS:
            return state.filter(id =>
                data.indexOf(id) === -1
            )
        default:
            return state
    }
}



export default combineReducers({data, fetching, selectedIntegrations, filters});

// Selectors

export const filterIntegrations = (state, filter) => {
    state = filterByStatus(state, filter.status);
    state = filterByProvider(state, filter.provider);
    state = filterByCategory(state, filter.category);
    state = filterBySearchWord(state, filter.search);
    return state;
    //return filterBySearchWord(filterByCategory(filterByProvider(filterByStatus(state, filter.status), filter.provider), filter.category), filter.search);
}

const filterByProvider = (state, filter) => {
   // console.log('filterByProvider satte ', state);
    if(filter !== ''){
       return state.filter( int => {
         console.log('provider ', int.provider, filter, int.provider === filter)
            return int.provider === filter;
        })
    } else{
        return state;
    }
}

const filterByCategory = (state, filter) => {
 // console.log('filterByCategory satte ', state);
 const categories = {
     'ERP': ['SAP', 'Microsoft', 'Oracle'],
     'CRM': ['SAP', 'Microsoft', 'Oracle', 'Salesforce'],
     'TP': ['IBM', 'Google'],
     'CT': ['Slack']
 }
    if(filter !== ''){
       return state.filter( int => {
            return categories[filter].indexOf(int.provider) > -1;
        })
    } else {
        return state;
    }
}

const filterByStatus = (state, filter) => {
    if(filter !== ''){
        return state.filter( int => {
            if(filter === 'Active') {
                return int.status === true;
            } 
            return int.status === false;
        })
    }
    return state;
}

const filterBySearchWord = (state, filter) => {
    if (!state.length || !filter) return state;
    return state.filter( int => {
    	return ((int.name.toLowerCase().indexOf(filter) > -1) ||
        (int.service.toLowerCase().indexOf(filter) > -1) ||
        (int.provider.toLowerCase().indexOf(filter) > -1));
    })
}

export const findIntegration = (state, id) => {
    if(id !== 'newIntegration'){
        return state.find( int => {
            return int.id === id;
        })
    } return {};
}
