
export const defValidationRules = [{
    whitespace: true, message: 'The field can not be empty!'
}, {
    required: true, message: 'The field is required!'
}];

export const details = [{
    service: 'Watson Assistant',
    data: [{
        type: 'text',
        key: 'name',
        name: 'Name of the service',
        confidential: false,
        validationRules: defValidationRules
    }, {
        type: 'text',
        key: 'workspaceId',
        name: 'Worksace ID',
        confidential: true,
        validationRules: defValidationRules
    }]
}, {
    service: 'NLC',
    data: [ {
        type: 'text',
        key: 'name',
        name: 'Name of the service',
        confidential: false,
        validationRules: defValidationRules
    }, {
        type: 'text',
        key: 'classifierId',
        name: 'Classifier ID',
        confidential: true,
        validationRules: defValidationRules
    }]
}, {
    service: 'NLU',
    data: [ {
        type: 'text',
        key: 'name',
        name: 'Name of the service',
        confidential: false,
        validationRules: defValidationRules
    }, {
        type: 'text',
        key: 'classifierId',
        name: 'Classifier ID',
        confidential: true,
        validationRules: defValidationRules
    }]
}, {
    service: 'Discovery',
    data: [ {
        type: 'text',
        key: 'name',
        name: 'Name of the service',
        confidential: false,
        validationRules: defValidationRules
}, {
        type: 'text',
        key: 'collectionId',
        name: 'Collection ID',
        confidential: true,
        validationRules: defValidationRules
    }, {
        type: 'text',
        key: 'environmentId',
        name: 'Environment ID',
        confidential: true,
        validationRules: defValidationRules
    }]
}, {
    service: 'WKS',
    data: [ {
        type: 'text',
        key: 'name',
        name: 'Name of the service',
        confidential: false,
        validationRules: defValidationRules
}, {
        type: 'text',
        key: 'modelId',
        name: 'Model ID',
        confidential: true,
        validationRules: defValidationRules
    }]
}];

export const credentials = [{
    type: 'text',
    key: 'url',
    name: 'URL',
    confidential: true,
    validationRules: [...defValidationRules,{
        type: 'url',
        message: 'The input is not valid URL!'
    }]
}, {
    type: 'password',
    key: 'password',
    name: 'Password',
    confidential: true,
    validationRules: [...defValidationRules,{
        type: 'string',
        message: 'The input is not a valid password!'
    }]
}, {
    type: 'text',
    key: 'username',
    name: 'Username',
    confidential: true,
    validationRules: defValidationRules
}];

export const services = [{
    name: 'Watson Assistant',
    info: 'Build and deploy chatbots and virtual agents across a variety of channels.',
    link: '/connections/IBM/Watson Assistant/addNew',
    img: 'watson'
},{
    name: 'Knowledge Studio',
    info: 'Teach Watson the language of your domain with custom models that identify.',
    link: '/connections/IBM/WKS/addNew',
    img: 'knowledge'
},{
    name: 'Natural Language Understanding',
    info: 'Natural language processing for text. Understanding keywords, concepts, etc.',
    link: '/connections/IBM/NLU/addNew',
    img: 'language-understanding'
},{
    name: 'Natural Language Classifier',
    info: 'Perform natural language classNameification on question texts.',
    link: '/connections/IBM/NLC/addNew',
    img: 'language-classifier'
},{
    name: 'Discovery',
    info: 'Uncover deep connections throughout your data by using advance AI.',
    link: '/connections/IBM/Discovery/addNew',
    img: 'discovery'
}];