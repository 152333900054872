import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form, Input, Switch } from 'antd';
import { createIntegration, updateIntegration } from '../../../actions/integrations';
import SubmitButton from '../../SubmitButton';
import { details, defValidationRules, credentials } from './objectContainer';
import 'antd/lib/switch/style/index.css';

class FormIBM extends Component {

    state = {
        switchStatus: false
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handleCheck = checked => {
        this.setState({switchStatus: checked});
    }

    checkIfConfidential = (isConfidential) => {
        const isNotNew = !!this.props.integration;
        const isNotAdmin = !this.props.isSuperAdmin;
        return isNotNew && isNotAdmin && isConfidential;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll }, createIntegration, updateIntegration } = this.props;
        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                values.status = this.state.switchStatus;
                if(this.props.integration && this.props.integration.id) values.id = this.props.integration.id;
                if(values.id){
                    // values.service = this.props.data.service;
                    // values.provider = this.props.data.provider;
                    updateIntegration(values);
                } else{
                    values.service = this.props.data.service;
                    values.provider = this.props.data.provider;
                    createIntegration(values);
                }
            } else{
                console.log("errors", err, 'values', values);
            }
        });
    }

    errorMessage = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors){
            if(errors.length > 0) {
                return (
                    <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
                )
            } else {
                return null;
            }
        }
    }

    validateUniqueId = (rule, value, callback) => {
        if(!this.props.integration){
            if(value === undefined || value === ''){
                callback(new Error('Unique ID can not be empty!'))
            }else if(value &&
                this.props.integrations.filter( i => {
                    return i.uniqueId === value;
                }).length === 0){
                callback();
            } else{
                callback(new Error('Integration with such Unique ID already exists!'))
            }
        } else{
            return callback();
        }
            
    }

    componentDidMount(){
        if(this.props.integration && this.props.integration.status) this.setState({switchStatus: this.props.integration.status});
    }

    render(){
        const { form: { getFieldDecorator }, data, formLoaders } = this.props;
        const integration = this.props.integration || {};
        const serviceDetails = details.find( s => { return s.service === data.service});
        const defText = 'confidentional';
        const submitButtonName = this.props.integration ? 'Update integration' : 'Create integration';    
        return (
            <Form prefixCls="h" onSubmit={this.handleSubmit} className="measure-wide mt3">

                {
                    data.service !== 'WKS' ?
                        (<React.Fragment>
                            <h2 className="mt0 mb3">Integrations settings</h2>
                            <h4 className="mt3 mb2">Service credentials</h4>
                            {
                                credentials.map( item => {
                                    const confid = this.checkIfConfidential(item.confidential);
                                    return(
                                        <div key={item.key} className="mt3">
                                            <label className="b f6">{item.name}:</label>
                                            {
                                                getFieldDecorator(item.key, {
                                                    initialValue: confid ? defText :  integration[item.key],
                                                    rules: confid ? defValidationRules : item.validationRules,
                                                    validateTrigger: "onChange",
                                                    onChange: this.handleInputChange
                                                })(<Input prefixCls="h" disabled={confid} type={confid ? 'password' : item.type} className="w-100 mt1 pv2 ph2 f5"/>)
                                            }
                                            {
                                                this.errorMessage(item.key)
                                            }
                                        </div>
                                    )
                                })
                            }
                            <hr className="ba b--black-05 mv4"/>
                        </React.Fragment>
                        ) : null
                }
                <h4 className="mt0 mb2">Service details</h4>
                <div className="mt3">
                    <div className="mt3">
                        <label className="b f6">Unique ID:</label>
                        {
                            getFieldDecorator('uniqueId', {
                                    initialValue: integration.uniqueId,
                                    validateTrigger: "onChange",
                                rules:[{
                                        validator: this.validateUniqueId,
                                }],
                                    onChange: this.handleInputChange
                            })(<Input prefixCls="h" type='text' className="w-100 mt1 pv2 ph2 f5"/>)
                        }
                        {
                            this.errorMessage('uniqueId')
                        }
                    </div>
                    {
                        serviceDetails.data.map( item => {
                            const confid = this.checkIfConfidential(item.confidential);
                            return(
                                <div key={item.key} className="mt3">
                                    <label className="b f6">{item.name}:</label>
                                    {
                                        getFieldDecorator(item.key, {
                                            rules: confid ? defValidationRules : item.validationRules,
                                            initialValue: confid ? defText :  integration[item.key],
                                            validateTrigger: "onChange",
                                            onChange: this.handleInputChange
                                        })(<Input prefixCls="h" disabled={confid} type={confid ? 'password' : item.type} className="w-100 mt1 pv2 ph2 f5"/>)
                                    }
                                    {
                                        this.errorMessage(item.key)
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <hr className="ba b--black-05 mv4"/>
                <h4 className="mt0 mb2">Service status</h4>
                <div className="mt3">
                  <Switch checked ={this.state.switchStatus} disabled={this.checkIfConfidential(true)}  onChange={this.handleCheck}/>
                </div>
                <div className="mt4">
                    <SubmitButton value={submitButtonName}  loader={formLoaders.create}/>
                    <button type="button" className="dib btn btn-ghost pv2 ph3 f5" onClick={this.goBack} >Cancel</button>
                    </div>  
                </Form>
        );
    }

}

FormIBM.propTypes = {
    integrations: PropTypes.array.isRequired,
    formLoaders: PropTypes.object.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired, 
    data: PropTypes.object.isRequired,
    createIntegration: PropTypes.func.isRequired,
    updateIntegration: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    integrations: state.integrations.data,
    formLoaders: state.forms.integrations,
    isSuperAdmin: state.auth.isSuperAdmin
})

export default withRouter(connect(
    mapStateToProps, {
        createIntegration, 
        updateIntegration
    }
)(Form.create()(FormIBM)));