import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    updateSkillLimit,
    updateTotalSkillsLimit
} from '../../../actions/settings';
// import OptionsPopup from './OptionsPopup';
import { roundPrice } from '../../../utils';

class SkillRow extends Component {

    state = {
        showMenu: false
    }

    clickBody = () => {
        this.removeBodyListener();
        this.clickBodyTimeout = setTimeout(() => {
            this.setState({
                showMenu: false
            });
        }, 100);
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
        document.body.addEventListener("click", this.clickBody);
    }

    removeBodyListener = () => {
        if (this.state.showMenu) {
            document.body.removeEventListener("click", this.clickBody);
        }
    }

    render(){
        
        // const { showMenu } = this.state;
         
        return this.props.skill ? (
            <tr>
                <td><span className="link">{this.props.skill.name}</span></td>
                <td className="tr">€{this.props.skill.price}</td>
                <td className="tr">{this.props.skill.requests}</td>
                <td className="tr">€{roundPrice(this.props.skill.total)}</td>
                {/* <td><span className="o-60">{!!this.props.skill.limit ? this.props.skill.limit : '-'}</span></td>
                <td>
                    <div className="relative">
                        <img className="mr3 o-60 hover-o-100 pointer"
                        src="/assets/img/24icn-options.svg"
                        onClick={this.toggleMenu} alt="" />
                        {
                            showMenu ? <OptionsPopup skill={this.props.skill} 
                            updateFunction={() => this.props.updateSkillLimit(this.props.skill)}/> : null
                        }
                    </div>
                </td> */}
            </tr>
        ) 
        :
        (
            <tr>
                <td colSpan="2"><strong>Total spent in period:</strong></td>
                <td className="tr">{this.props.totalRequests}</td>
                <td className="tr"><strong>€{this.props.total}</strong></td>
                {/* <td>€{this.props.totalLimit}</td> */}
                {/* <td>
                    <div className="relative">
                        <img className="mr3 o-60 hover-o-100 pointer"
                        src="/assets/img/24icn-options.svg"
                        onClick={this.toggleMenu} alt="" />
                        {
                            showMenu ? <OptionsPopup 
                            limit={this.props.totalLimit}
                            updateFunction={this.props.updateTotalSkillsLimit} /> : null
                        }
                    </div>
                </td> */}
            </tr>
        )
    };
}

const mapDispatchToProps = {
    updateSkillLimit,
    updateTotalSkillsLimit
}

export default connect(null, mapDispatchToProps)(SkillRow);

SkillRow.propTypes = {
    skill: PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        requests: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        // limit: PropTypes.number.isRequired,
    }),
    totalLimit: PropTypes.number,
    totalRequests: PropTypes.number,
    total: PropTypes.string,
    updateSkillLimit: PropTypes.func.isRequired,
    updateTotalSkillsLimit: PropTypes.func.isRequired,
}