import * as c from '../constants';
import * as api from '../services/api';
import history from '../history';


// User form actions
export const resetFormState = () => ({
    type: c.RESET_FORM_STATE
})

// Users list page actions
export const setUserFilter = filter => ({
    type: c.SET_USERS_FILTER,
    filter
})

export const selectUser = data => ({
    type: c.SELECT_USER,
    data
})

export const deselectUser = data => ({
    type: c.DESELECT_USER,
    data
})

export const selectAllUsers = data => ({
    type: c.SELECT_ALL_USERS,
    data
})

export const deselectAllUsers = data => ({
    type: c.DESELECT_ALL_USERS,
    data
})

const requestUsers = () => ({
    type: c.REQ_USERS
})

const requestUsersError = data => ({
    type: c.REQ_USERS_ERROR,
    data
})

const requestUsersSuccess = data => ({
    type: c.REQ_USERS_SUCCESS,
    data
})


// User manipulations actions

const requestUpdateUser = data => ({
    type: c.UPDATE_USER,
    data
})

const updateUserSuccess = data => ({
    type: c.UPDATE_USER_SUCCESS,
    data
})

const updateUserError = data => ({
    type: c.UPDATE_USER_ERROR,
    data
})

const requestCreateUser = data => ({
    type: c.CREATE_USER,
    data
})

const createUserSuccess = data => ({
    type: c.CREATE_USER_SUCCESS,
    data
})

const createUserError = data => ({
    type: c.CREATE_USER_ERROR,
    data
})

export const newUser = () => ({
    type: c.NEW_USER
});

export const cancelUserForm = () =>({
    type: c.CANCEL_USER_FORM
});

export const editUser = data =>({
    type: c.EDIT_USER,
    data
});

export const requestDeleteUsers = data =>({
    type: c.DELETE_USERS,
    data
});
export const deleteUsersSuccess = data =>({
    type: c.DELETE_USERS_SUCCESS,
    data
});
export const deleteUsersError = data =>({
    type: c.DELETE_USERS_ERROR,
    data
});

export const fetchUsers = () => (
    async dispatch => {
        dispatch(requestUsers());
        try {

            const params = {
            }

            const users = await api.getUsers(params);
            users.map((el,k) => {
                el.key = k;
                return el;
            });
            dispatch(requestUsersSuccess(users));
        } catch (err) {
            dispatch(requestUsersError(err.message));
        }
    }
)

export const saveUser = user => (
    async dispatch => {
        if (user.id) dispatch(updateUser(user));
        else dispatch(createUser(user));
    }
)

export const updateUser = user => (
    async dispatch => {
        dispatch(requestUpdateUser(user));
        try {
            dispatch(updateUserSuccess(await api.updateUser(user)));
            if(history.location.pathname !== '/settings') history.push('/users');
        } catch (err) {
            dispatch(updateUserError(err.message));
        }
    }
);

export const createUser = user => (
    async dispatch => {
        dispatch(requestCreateUser(user));
        try {
            let u = await api.createUser(user);
            if (u.id) {
                dispatch(createUserSuccess(u));
                history.push('/users');
            }
            else dispatch(createUserError(u.errorMessage));
        } catch (err) {
            dispatch(createUserError(err.message || err.errorMessage));
        }
    }
);

export const deleteUsers = users => (
    async dispatch => {
        dispatch(requestDeleteUsers(users));

        // return new Promise((approve, reject) => {

        //         setTimeout(() => {
        //             console.log('TimeoutCOmplite');
        //             approve();
        //         }, 5000);

        //     })
            try {
                dispatch(deleteUsersSuccess(await api.deleteUsers(users)));
                history.push('/users');
                return Promise.resolve();
            } catch (err) {
                dispatch(deleteUsersError(err.message));
            }
    }
);

export const confirmDeleteUsers = data => (
    async dispatch => {
        dispatch({
            type: c.RENDER_CONFIRMATION_POPUP,
            payload: {
                data: data,
                type: 'user',
                ok: async () => {
                    return dispatch(deleteUsers(data.map( u => u.id)))
                }
            }
        });
    }
)
