export const profileNav = [{
    name: 'Profile',
    link: '/settings',
}, {
    name: 'Notifications',
    link: '/settings/notifications',
    roles: ['SuperAdmin']
},{
    name: 'Contact us',
    link: '/contacts'
},{
    name: 'Documentation',
    link: '/documentation'
},{
    name: 'Legal information',
    link: '/legalInformation'
}];

export const sideNav_1 = [{
    name: 'Dashboard',
    img: '/assets/img/Dashboard.svg',
    exact: true,
    to: '/'
}, {
    name: 'Dashboard Old',
    img: '/assets/img/Dashboard.svg',
    exact: true,
    to: '/dashboard-old',
    roles: ['SuperAdmin']
}, {
    name: 'User management',
    img: '/assets/img/UserManagement.svg',
    exact: false,
    roles: ['SuperAdmin'],
    to: '/users'
}, {
    name: 'Company Profile',
    img: '/assets/img/CompanyProfile.svg',
    exact: false,
    to: '/settings/company'
}, {
    name: 'Usage Dashboard',
    img: '/assets/img/UsageDashboard.svg',
    exact: false,
    to: '/settings/usage'
}, {
    name: 'Billing',
    img: '/assets/img/Billing.svg',
    exact: false,
    to: '/settings/billing'
},{
    name: 'Integrations',
    img: '/assets/img/Integration.svg',
    exact: false,
    roles: ['SuperAdmin'],
    to: '/integrations',
}, {
    name: 'Integrations OLD',
    img: '/assets/img/24icn-integration-white.svg',
    exact: false,
    to: '/connections',
    roles: ['SuperAdmin'],
    items: [{
        name: 'Connections',
        img: '/assets/img/16icn-child-path-white.svg',
        exact: true,
        to: '/connections'
    }, {
        name: 'Catalogue',
        img: '/assets/img/16icn-child-path-white.svg',
        exact: false,
        to: '/connections/catalogue'
    }]
},{
    name: 'Skills',
    img: '/assets/img/skills.svg',
    exact: false,
    roles: ['SuperAdmin'],
    to: '/skills'
},{
    name: 'Skills Old',
    img: '/assets/img/24icn-builder-white.svg',
    exact: false,
    to: '/skills',
    roles: ['SuperAdmin'],
    items: [{
        name: 'Skill Store',
        img: '/assets/img/16icn-child-path-white.svg',
        exact: true,
        to: '/skills'
    }, {
        name: 'Skills management',
        img: '/assets/img/16icn-child-path-white.svg',
        exact: false,
        to: '/skills/management'
    }]
}];