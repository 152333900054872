import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import './Settings/UsageDashboard/usageDashboard.sass';

const RefreshContent = ({ lastUpdated, fetching, action }) => (
    <div className="measure-wide">
        <p className="mt2 mb2 f6 fw5">
            <span className="o-60">Last updated: {lastUpdated && moment(lastUpdated).format("D MMM YYYY, H:mm:ss")}</span>
            <span className="ml1 o-60 hover-o-100 pointer" onClick={action}>
                <img className={"v-mid mr1" + (fetching ? " spin" : "")} src="/assets/img/16icn-refresh.svg" alt="Refresh" title="Refresh data" />
                Refresh data
            </span>
        </p>
    </div>
)

export default RefreshContent;

RefreshContent.propTypes = {
    lastUpdated: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
}
