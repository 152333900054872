import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InvoiceItem from './InvoiceItem';
import EmptyTable from './EmptyTable';
import { hasAccess } from '../../../containers/HasRoles';
import { getInvoices } from '../../../actions/billing';
import { confirmDeleteInvoice } from '../../../actions/billing';

class Invoices extends Component {

    componentDidMount() {
        this.props.getInvoices();
    }

    render(){
        const { userRoles, billing: { invoices }, confirmDeleteInvoice } = this.props;
        return(
            <div>
                <div>
                    <h2 className="mt0 mb2">Invoices</h2>
                    <p className="mt2">For the current moment we accept only bank transfers as a payment method. Keep in mind that bank tranfer takes 2-3 business days.</p> 
                </div>
                {
                    hasAccess(['SuperAdmin'], userRoles, () => 
                    <div className="self-end">
                        <Link to="/settings/billing/addNew" className="btn btn-blue pv1 ph2 f5">Add Invoice</Link>
                    </div>)
                }
                <table className="table w-100 tl mt3">
                    <thead>
                        <tr className="bg-navy-near-white">
                            <th>Invoice ID</th>
                            <th>Issue date</th>
                            <th>Due date</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th className="" style={{width:'40px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            invoices && invoices.length ? 
                            invoices.map( i=> <InvoiceItem 
                                key={i._id}
                                userRoles={userRoles}  
                                invoice={i}
                                confirmDeleteInvoice={confirmDeleteInvoice}
                                 />)
                            : <EmptyTable  span={6} />
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    billing: state.settings.billing,
    userRoles: state.auth.user.roles,
})

const mapDispatchToProps = {
    getInvoices,
    confirmDeleteInvoice,
}


export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

Invoices.propTypes = {
    billing: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    getInvoices: PropTypes.func.isRequired,
    confirmDeleteInvoice: PropTypes.func.isRequired,
}