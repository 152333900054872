import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageHead from '../../PageHead';
import Platform from './Platform';
import Spending from './Spending';
import SubmitButton from '../../SubmitButton';
import { saveNotifications } from '../../../actions/settings';
import { notificationsHeader } from './objectContainer';



class Notifications extends Component {

    constructor(props){
        super(props);
        this.state = {
            updates: {...this.props.updates},
            limits: {...this.props.limits}
        }
        this.handleChange = this.handleChange.bind(this);
        this.resetState = this.resetState.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    handleChange = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        const { updates, limits } = this.state;
        if(updates.hasOwnProperty([name])) this.setState({updates: {...updates, [name]: checked}}); 
        if(limits.hasOwnProperty([name])) this.setState({limits: {...limits, [name]: checked}});
    }

    resetState = () => {
        const { limits, updates } = this.props;
        this.setState({updates:{...updates}, limits: {...limits}});
    }

    saveChanges = (e) => {
        e.preventDefault();  
        this.props.saveNotifications(this.state);
    }

    render(){
        const { updates, limits } = this.state;
        const { loader } = this.props;
        return(
            <div style={{minHeight: '99vh', backgroundColor: 'white'}}>
                <PageHead {...notificationsHeader} />
                <div className="pa4 pb5-l">
                    <div className="ph3">
                        <div className="measure-wide">
                        <form onSubmit={this.saveChanges} >
                            <Platform options = {updates} handleChange={this.handleChange} />
                            <Spending  options = {limits} handleChange={this.handleChange} />
                            <div className="mt4">
                                <SubmitButton loader={loader} value={'Save changes'} />
                                <input type="button" value="Cancel" className="dib btn btn-ghost pv2 ph3 f5" onClick={this.resetState} />
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    updates: state.settings.updates,
    limits: state.settings.limits,
    loader: state.settings.fetching.updating
})

const mapDispatchToProps = {
    saveNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

Notifications.propTypes = {
    notifications: PropTypes.shape({
        announcements: PropTypes.bool.isRequired,
        maintenance: PropTypes.bool.isRequired,
        newSkills: PropTypes.bool.isRequired,
    }).isRequired,
    limits: PropTypes.shape({
        accountLimit: PropTypes.bool.isRequired,
        budgetWarning: PropTypes.bool.isRequired,
        spendingDigest: PropTypes.bool.isRequired,
    }).isRequired,
}