import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findIntegration } from '../../reducers/integrations';
import { redirect } from '../../actions/redirect';
import FormIBM from './IBM/FormIBM';
import FormSAP from './SAP/FormSAP';
// import IntegrationPageHead from './IntegrationPageHead';
import PageHead from '../PageHead';

const IntegrationPage = ({ match: { params }, integration, redirect, history: { goBack } }) => {
    // const backLinkName = params.id !== 'addNew' ? '← Connections' : (params.provider === 'IBM' ? '← IBM catalogue' : '← Catalogue');
    const { provider, service } = params;
    return (
        <div className="bg-white br1 br--top" style={{minHeight:'99vh'}}>
            <PageHead 
                title={provider === 'SAP' ? 'SAP integration' : service}
                goBack={goBack}
            />
            {/* <IntegrationPageHead goBack={goBack} provider={provider} service={service} backLinkName={backLinkName} redirect={redirect}/> */}
            <div className="pa4 pb5-l">
                <div className="ph3">
                    <div className="measure-wide">
                        {
                            params.provider === 'IBM' ?
                                <FormIBM data={params} integration={integration} />
                            : <FormSAP data={params} integration={integration} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, {match : { params } }) => ({
    integration: findIntegration(state.integrations.data, params.id),
})

export default connect(mapStateToProps, {redirect})(IntegrationPage);

IntegrationPage.propTypes = {
    integration: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    redirect: PropTypes.func.isRequired,
};
