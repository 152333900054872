import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { S3Image } from 'aws-amplify-react';

export default class S3ImageViewer extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    }

    render() {
        const { data } = this.props;
        return data && data.fileKey ?
            (
                <div className="pl2 bl mt3 b--black-05 bw3 measure">
                    <S3Image
                    style={{borderRadius: '.125rem'}}
                    imgKey={data.fileKey} />
                </div>
            ) : null
    }
}
