import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import {
    deleteConfirmation
} from '../../actions/integrations';

const log = new Amplify.Logger('Connection Item::');

class ConnectionItem extends Component {

    state = {
        showMenu: false
    }

    clickBody = () => {
        this.removeBodyListener();
        this.clickBodyTimeout = setTimeout(() => {
            this.setState({
                showMenu: false
            });
        }, 100);
    }

    toggleMenu() {
        this.setState({
            showMenu: !this.state.showMenu
        })
        document.body.addEventListener("click", this.clickBody);
    }

    removeBodyListener() {
        if (this.state.showMenu) {
            document.body.removeEventListener("click", this.clickBody);
        }
    }

    componentWillUnmount() {
        this.removeBodyListener();
        if (this.clickBodyTimeout) {
            clearTimeout(this.clickBodyTimeout);
            this.clickBodyTimeout = null;
        }
    }

    renderMenu() {
        const int = this.props.integration;

        return (
            <div className={`options-menu-dd br1 shadow-4`} style={{position: 'absolute', right: 0, top: 0}}>
                <ul className="list pa1 ma0" style={{cursor: "pointer"}}>
                    <li>
                        <span
                            className="db o-60 hover-o-100 pa2 br2"
                            onClick={() => this.props.deleteConfirmation(int)} >
                            Delete service
                        </span>
                    </li>
                    <li>
                        <Link to={`/connections/${int.provider}/${int.service}/${int.id}`} className="db o-60 hover-o-100 pa2 br2">
                            Edit service
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }

    render() {
        log.debug('render');
        const int = this.props.integration;
        const path = window.location.pathname;

        return (
            <tr key={int.id}>
                <td>
                    <Link to={`${path}/${int.provider}/${int.service}/${int.id}`} className="link">{int.name}</Link>
                </td>
                <td>{int.provider}</td>
                <td>{int.serviceName}</td>
                <td><span className={int.status ? 'green' : 'red'}>{int.status ? 'Active' : 'Disabled'}</span></td>
                {/* <td>
                    <div style={{position: 'relative'}}>
                        <img className="mr3 o-60 hover-o-100 pointer"
                            src="/assets/img/24icn-options.svg"
                            onClick={() => this.toggleMenu()}
                            alt="" />
                            {
                                this.state.showMenu 
                                ? this.renderMenu()  
                                : null
                            }
                    </div>
                </td> */}
            </tr>
        );
    }
}

ConnectionItem.propTypes = {
    integration: PropTypes.object.isRequired,
    deleteConfirmation: PropTypes.func.isRequired
};

export default connect(
    null, 
    {
        deleteConfirmation
    }
)(ConnectionItem);