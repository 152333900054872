import React from 'react';
import { Link } from 'react-router-dom';
import CatalogueBlock from './CatalogueBlock';
import { active, comingSoon } from './objectContainer';

const Catalogue = () => (
    <div style={{height: '99vh'}}>
        <div className="pa4 bg-navy-near-white br1 br--top">
            <div className="ph3">
                <div className="flex items-center">
					<img className="section-icon" src="/assets/img/24icn-integration-black.svg" alt="Hala App" />
					<h2 className="mb0">Catalogue</h2>
				</div>
                <p className="mt2 mb0 lh-copy">The catalog includes the information about the available integrations. Review and add the new connections.<br/>Learn more in <Link to="/documentation" className="link">Documentation</Link>.</p>
            </div>
        </div>
        <div className="pa4 pb5-l">
            <div className="ph2">
                <div className="ph2">
                    <div className="measure-wide">
                        <h3 className="mt0 mb2">Available integrations</h3>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    {
                        active.map( (int, k) => <CatalogueBlock key={k} int={int} />)
                    }
                </div>
            </div>
            <div className="mt4 ph2">
                <div className="ph2">
                    <div className="measure-wide">
                        <h3 className="mt0 mb2">More integrations coming soon...</h3>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    {
                        comingSoon.map( (int, k) => <CatalogueBlock key={k} int={int} />)
                    }
                </div>
            </div>
        </div>
    </div>
);

export default Catalogue;
