import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    setSkillSearchText,
    setSkillSearchArea,
    setSkillSearchDomainKnowledge,
    setSkillSearchProvider,
    fetchSkills
} from '../../actions/skills';
import { filterOptions } from './objectContainer';


class FilterSkills extends Component {

    state = {
        showFilters: false
    }
    
    toggleFilters = () => {
        this.setState({showFilters: !this.state.showFilters});
    }

    handleChange = e => {
        this.props[e.target.id](e.target.value);
    }

    render() {
        const { filters, children } = this.props;
        const { showFilters } = this.state;
        const toggleButtonName = this.state.showFilters ? 'Hide filters' : 'Show filters';
        const options = Object.keys(filterOptions);
        return (
            <div className="ph3 flex justify-between flex-wrap mb2">
                <div className="flex justify-between w-100">
                    <div className="flex items-center">
                        <form action="" className="mb2 mb2"> 
                            <input id="setSkillSearchText" type="text" value={filters.search} onChange={this.handleChange} placeholder="Search..." className="input-reset pv1 pl2 pr4 f5 w-100" />
                        </form>
                        <span className="ml3 link link-unstyled o-60 hover-o-100 pointer"
                            onClick={this.toggleFilters}>{toggleButtonName}
                        </span>
                    </div>
                    {children || null}
                </div>
                {
                    showFilters ? 
                    <div className="flex justify-between w-100 mb2">
                    {
                        options.map( (i, k) => {
                            const item = filterOptions[i];
                            return (
                                <div className={k === options.length - 1 ? "w-100" : "mr3 w-100"}>
                                    <select id={item.action} value={filters[i]} onChange={this.handleChange} className="input-reset pv1 pl2 pr4 f5 w-100">
                                        <option value="">{`All ${item.defOption}`}</option>
                                        { item.data.map( (option,i) => <option key={`op-${i}`} value={option.key}>{option.name}</option>) }
                                    </select>
                                </div>
                            )
                        })
                    }
                    </div>
                    : null
                }
            </div>
        );
    }

}

const mapStateToProps = state => ({
    filters: state.skills.filters
})

const mapDispatchToProps = {
    setSkillSearchText,
    setSkillSearchDomainKnowledge,
    setSkillSearchProvider,
    setSkillSearchArea,
    fetchSkills
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterSkills);

FilterSkills.propTypes = {
    filters: PropTypes.object.isRequired,
    setSkillSearchText: PropTypes.func.isRequired,
    setSkillSearchDomainKnowledge: PropTypes.func.isRequired,
    setSkillSearchProvider: PropTypes.func.isRequired,
    setSkillSearchArea: PropTypes.func.isRequired,
    fetchSkills: PropTypes.func.isRequired,
}
